var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.alerts)?_c('ui-panel',[(_vm.alerts.items.length > 0)?_c('ui-flex',[_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-table-column-selection',{attrs:{"columns":_vm.columns},model:{value:(_vm.hiddenColumns),callback:function ($$v) {_vm.hiddenColumns=$$v},expression:"hiddenColumns"}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('domain-ui-alert-table',{attrs:{"alerts":_vm.alertRows,"additional-columns":_vm.additionalColumns,"hidden-columns":_vm.hiddenColumns},scopedSlots:_vm._u([{key:"heatingSystemHeatingType",fn:function(ref){
var row = ref.row;
return [(row.heatingSystem === undefined || row.heatingSystem.heatingType === null)?[_vm._v("–")]:[_vm._v(_vm._s(row.heatingSystem.heatingType))]]}},{key:"heatingSystemBoilerDevice",fn:function(ref){
var row = ref.row;
return [(row.heatingSystem === undefined || row.heatingSystem.boilerDevice === null)?[_vm._v("–")]:[_vm._v(_vm._s(row.heatingSystem.boilerDevice))]]}},{key:"heatingSystemBoilerModel",fn:function(ref){
var row = ref.row;
return [(row.heatingSystem === undefined || row.heatingSystem.boilerModel === null)?[_vm._v("–")]:[_vm._v(_vm._s(row.heatingSystem.boilerModel))]]}},{key:"heatingSystemProductSerial",fn:function(ref){
var row = ref.row;
return [(row.heatingSystem === undefined || row.heatingSystem.productSerial === null)?[_vm._v("–")]:[_vm._v(_vm._s(row.heatingSystem.productSerial))]]}},{key:"heatingSystemManufacturer",fn:function(ref){
var row = ref.row;
return [(row.heatingSystem === undefined || row.heatingSystem.manufacturer === null)?[_vm._v("–")]:[_vm._v(_vm._s(row.heatingSystem.manufacturer))]]}},{key:"heatingSystemBoilerManufacturer",fn:function(ref){
var row = ref.row;
return [(row.heatingSystem === undefined || row.heatingSystem.boilerManufacturer === null)?[_vm._v("–")]:[_vm._v(_vm._s(row.heatingSystem.boilerManufacturer))]]}}],null,false,533401290)})],1),(_vm.totalPages > 1)?_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-pagination',{attrs:{"total-pages":_vm.totalPages},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()],1):_c('ui-form-message',{attrs:{"success":""}},[_vm._v("Zur Zeit liegen keine Alarme vor.")])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }