































































































































































































import { defineComponent } from '@vue/composition-api';
import { mapGetters } from 'vuex';
import {
  HeatingSystem,
  HeatingSystemComponent,
  HeatingSystemMetadata,
  WaterHeatingTypes,
} from '@/features/core/store/page-modules/heating-systems-metadata/heating-systems-metadata.module';

export default defineComponent({
  data() {
    return {
      options: ['Yes', 'No'],
      waterHeatingOptions: [
        {
          label: 'Frischwasserstation',
          value: WaterHeatingTypes.FRESH_WATER_STATION,
        },
        {
          label: 'Speicherladesystem',
          value: WaterHeatingTypes.STORAGE_TANK_CHARGING_SYSTEM,
        },
        {
          label: 'Heizwendelspeicher',
          value: WaterHeatingTypes.HEATING_COIL_STORAGE_TANK,
        },
      ],
      form: {
        // kessel
        condensing: null,
        wall_mounted: null,
        return_temp_uplift: null,
        safety_temp_guard: null,
        safety_temp_limiter: null,
        cascade: null,
        is_modulating: null,
        has_own_pump: null,
        has_regulating_valve: null,
        has_check_valve: null,
        system_separation: null,
        power_max_out: 0,
        power_min_out: 0,
        // warm heating
        type_of_pwh: null,
        potable_water_target_temp: null,
        potable_water_temperature_tolerance: null,
        circulation_target_temp: null,
        circulation_temperature_tolerance: null,
      } as HeatingSystemMetadata,
      lastChange: {
        user: null as string | null,
        date: null as Date | null,
      },
      toastMessage: '',
    };
  },
  computed: {
    ...mapGetters(['openModal']),
    heatingSystems(): HeatingSystem[] {
      return this.$store.getters.heatingSystems;
    },
    heatingSystemsComponents(): HeatingSystemComponent[] {
      return this.$store.getters.heatingSystemsComponents;
    },
    componentType(): string {
      return this.activeHeatingSystemComponent?.type || '';
    },
    activeHeatingSystem(): HeatingSystem | undefined {
      return this.$store.getters.activeHeatingSystem;
    },
    activeHeatingSystemComponent(): HeatingSystemComponent | undefined {
      return this.$store.getters.activeHeatingSystemComponent;
    },
    openApplySettings(): boolean {
      return this.$store.getters.openApplySettings;
    },
  },
  watch: {},
  mounted() {
    if (this.activeHeatingSystem?.metadata) {
      this.form = this.activeHeatingSystem.metadata;
    }
  },
  methods: {
    closeModal() {
      this.$store.commit('openModal', false);
    },
    saveForm() {
      this.$store.commit('openApplySettings', !this.openApplySettings);
      this.$store.commit('setFormData', this.form);
    },
  },
});
