




























































































import { defineComponent } from '@vue/composition-api';
import Panel from '@/features/ui/panel/Panel.global.vue';
import WizardButton from '@/components/clickables/WizardButton.global.vue';
import FormField from '@/features/ui/form/FormField.global.vue';
import Form from '@/features/ui/form/Form.global.vue';
import { mapActions, mapState } from 'vuex';
import { NewProjectData } from '@/features/core/store/page-modules/enterprise-project-create/enterpriseProjectCreateModule';

interface ProjectBuildingInformationMethods {
  [key: string]: (...args: any[]) => void;
  updateBuildingInformation(formData: any): void;
  getActiveBuilding(): void;
}
export default defineComponent<any, Record<string, unknown>, any, any, ProjectBuildingInformationMethods>({
  components: { Form, FormField, WizardButton },
  props: {},
  data() {
    return {
      addToAll: false,
      skip: false,
    };
  },
  computed: {
    ...mapState({
      activeStep1BuildingId: (state: any) => {
        return state.EnterpriseProjectCreateModule.activeStep1BuildingId;
      },
      activeBuilding: (state: any) => {
        if (
          state.EnterpriseProjectCreateModule.newProjectData.buildings &&
          state.EnterpriseProjectCreateModule.newProjectData.buildings?.length > 0
        ) {
          // Find active building by buildingId
          return state.EnterpriseProjectCreateModule.newProjectData.buildings.find(
            (building: any) => building.buildingId === state.EnterpriseProjectCreateModule.activeStep1BuildingId,
          );
        }
      },
      firstName: (state: any) =>
        state.EnterpriseProjectCreateModule.newProjectData.buildings.find(
          (building: any) => building.buildingId === state.EnterpriseProjectCreateModule.activeStep1BuildingId,
        )?.liegenshaftInformation.firstName,
      surname: (state: any) =>
        state.EnterpriseProjectCreateModule.newProjectData.buildings.find(
          (building: any) => building.buildingId === state.EnterpriseProjectCreateModule.activeStep1BuildingId,
        )?.liegenshaftInformation.surname,
      title: (state: any) =>
        state.EnterpriseProjectCreateModule.newProjectData.buildings.find(
          (building: any) => building.buildingId === state.EnterpriseProjectCreateModule.activeStep1BuildingId,
        )?.liegenshaftInformation.title,
      phoneNumber: (state: any) =>
        state.EnterpriseProjectCreateModule.newProjectData.buildings.find(
          (building: any) => building.buildingId === state.EnterpriseProjectCreateModule.activeStep1BuildingId,
        )?.liegenshaftInformation.phoneNumber,
      email: (state: any) =>
        state.EnterpriseProjectCreateModule.newProjectData.buildings.find(
          (building: any) => building.buildingId === state.EnterpriseProjectCreateModule.activeStep1BuildingId,
        )?.liegenshaftInformation.email,
    }),
  },
  methods: {
    async updateBuildingInformation(formData: any) {
      if (this.skip) {
        await this.$store.dispatch('saveBuildingInformation', {
          buildingInformation: {
            firstName: '',
            surname: '',
            title: '',
            phoneNumber: '',
            email: '',
          },
          liegenshaftInformationCompleted: false,
          apolloClient: this.$apollo,
        });
        this.skip = false;
        return;
      }
      if (this.addToAll) {
        await this.$store.dispatch('updateAllBuildingInformation', formData);
        await this.$store.dispatch('saveToDB', { apolloClient: this.$apollo, finalSave: false });
        this.addToAll = false;
      } else {
        await this.$store.dispatch('saveBuildingInformation', {
          buildingInformation: formData,
          liegenshaftInformationCompleted: true,
          apolloClient: this.$apollo,
        });
      }
      this.addToAll = false;
      return;
    },
    getActiveBuilding() {
      this.$store.dispatch('getNextActiveBuilding');
    },
    // Form
    validateEmail(email: string) {
      // Regular expression pattern for email validation
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // Test the email against the pattern
      return emailPattern.test(email);
    },
    validateForm(formData) {
      return (
        this.validateEmail(formData.email) &&
        formData.phoneNumber &&
        formData.firstName &&
        formData.surname &&
        formData.title
      );
    },
    setDisplayPropertyInformationCheckboxes() {
      this.$store.commit('setDisplayPropertyInformationCheckboxes', true);
    },
  },
});
