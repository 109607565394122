





















import { defineComponent } from '@vue/composition-api';
import GatewayDetailBreakdownLine from '@/features/app-manager/views/tree-node/gateway-new-details/components/GatewayDetailBreakdownLine.vue';
import LoadingIndicator from '@/features/core/components/loading-indicator/LoadingIndicator.vue';

interface DataConsumptionPollPanelProps {
  project: string;
}
export default defineComponent<DataConsumptionPollPanelProps, Record<string, unknown>, any, any>({
  components: { LoadingIndicator, GatewayDetailBreakdownLine },
  props: {
    project: {
      required: false,
      default: undefined,
    },
  },
  data() {
    return {};
  },
  computed: {
    projectCustomer() {
      return this.project?.customer?.name;
    },
    projectCustomerNumber() {
      return this.project?.customer?.externalId;
    },
    completedBuildingBlocks() {
      if (this.project) {
        return `${this.project.numberOfCompletedLProperties} / ${this.project.numberOfLProperties}`;
      }
      return '';
    },
    completedBuildings() {
      if (this.project) {
        return `${this.project.numberOfCompletedGProperties} / ${this.project.numberOfGProperties}`;
      }
      return '';
    },
    lastUpdate() {
      return `${this.transformToDateOnly(this.project.lastUpdate.lastUpdatedTimestamp)},
      ${this.transformToTimeOnly(this.project.lastUpdate.lastUpdatedTimestamp)} /
      ${this.project.lastUpdate.lastUpdatedByUser.firstName} ${this.project.lastUpdate.lastUpdatedByUser.lastName}`;
    },
  },
  methods: {
    transformToDateOnly(date: string): string {
      if (date) {
        return new Date(date).toLocaleDateString('de-DE');
      }

      return '';
    },
    transformToTimeOnly(date: string): string {
      if (date) {
        return new Date(date).toLocaleTimeString('de-DE');
      }

      return '';
    },
  },
});
