





















































































































































import { defineComponent, ref } from '@vue/composition-api';
import query from './project-details.gql';
import projectQuery from './project.gql';
import { Option } from '@/features/ui/inputs/model';
import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';

import { EnterpriseProjectBuildingStatus, EnterpriseProjectStatus, TreeNodeClassification } from '@/types/iot-portal';
import { EnterpriseProjectUpdateObjectStatusMutationVariables } from './__generated__/EnterpriseProjectUpdateObjectStatusMutation';
import { EnterpriseProjectUpdateProjectStatusMutationVariables } from './__generated__/EnterpriseProjectUpdateProjectStatusMutation';
import { AppEnterpriseProjectServiceProvidersQuery } from './__generated__/AppEnterpriseProjectServiceProvidersQuery';
import { GetProjectDetailsQuery } from './__generated__/GetProjectDetailsQuery';
import updateObjectStatusMutation from './update-object-status.gql';
import updateProjectStatusMutation from './update-project-status.gql';
import serviceProvidersQuery from './service-providers.gql';
import updateProjectBuildingsStatuses from './update-project-buildings-statuses.gql';
import buildingsStatuses from './get-project-building-statuses.gql';
import GroupsMixin from '@/features/core/components/mixins/groups';
import ProjectBuildingEditConfirmationModal from './ProjectBuildingEditConfirmationModal.vue';
import EnterpriseProjectCreateModal from '@/features/app-enterprise-project/views/enterprise-project-create/EnterpriseProjectCreateModal.vue';
import { generateStandortlist } from './imports/create-xlsx';
import { UpdateProjectBuildingsStatusesVariables } from '@/features/app-enterprise-project/views/enterprise-project-details/__generated__/UpdateProjectBuildingsStatuses';
import Pagination from '@/features/ui/pagination/Pagination.global.vue';
import ConfirmationModalButton from '@/features/core/components/confirmation-modal/ConfirmationModalButton.vue';
import { mapActions } from 'vuex';
import { EnterpriseProjectServiceType } from '@/features/core/store/page-modules/enterprise-project-create/enterpriseProjectCreateModule';
import ProjectObjectsTable from '@/features/app-enterprise-project/views/enterprise-project-details/components/ProjectObjectsTable.vue';
import Panel from '@/features/ui/panel/Panel.global.vue';
import Cell from '@/features/ui/layout/Cell.global.vue';
import Flex from '@/features/ui/layout/Flex.global.vue';

const viewName = 'Detailsicht Projekt ';
interface EnterpriseProjectDetailsViewProp {
  projectReference: string;
}

type ServiceProvider = AppEnterpriseProjectServiceProvidersQuery['serviceProviders']['items'][number];
type ProjectObjectListItem = GetProjectDetailsQuery['projectDetails']['items'][number];

export default defineComponent<EnterpriseProjectDetailsViewProp, Record<string, unknown>, any, any>({
  name: 'EnterpriseProjectDetailsView',
  components: {
    Pagination,
    EnterpriseProjectCreateModal,
    ProjectBuildingEditConfirmationModal,
    ConfirmationModalButton,
    ProjectObjectsTable,
    Panel,
    Cell,
    Flex,
  },
  mixins: [PaginationQueryStringMixin, GroupsMixin],
  props: { projectReference: { type: String, required: true } },
  apollo: {
    projectDetails: {
      query,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          // This should be an array according to graphql types,
          // but it won't work so making it single value
          statuses: this.statusFilter ? this.statusFilter : undefined,
          search: this.search.length < 3 ? undefined : this.search,
          buildingType: this.buildingTypeFilter,
          projectReference: this.projectReference,
          skip: this.skip,
          take: this.take,
        };
      },
      result({ data }: any) {
        if (!data) {
          return;
        }

        for (const item of data.projectDetails.items) {
          if (item.externalId) {
            item.pdtpCCRNumber = item.externalId;
          }
          if (item.ccrReferenceId) {
            item.pdtpCCRNumber = item.ccrReferenceId;
          }
        }

        this.projectDetails = data.projectDetails;
      },
    },
    project: {
      query: projectQuery,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          projectReference: this.projectReference,
        };
      },
    },
    // Get liegenshaft buildings statuses
    propertyListStatuses: {
      query: buildingsStatuses,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          projectReference: this.projectReference,
          buildingType: TreeNodeClassification.Liegenschaft,
        };
      },
      result({ data }: any) {
        if (!data || !data.propertyListStatuses || !data.propertyListStatuses.items) {
          return [];
        }

        this.propertyListStatuses = data.propertyListStatuses.items.map((item: any) => item.status);
      },
    },
  },
  setup() {
    // This is for the modal
    const projectCreateModal = ref(null);

    const openModal = (): void => {
      if (!projectCreateModal.value) {
        return;
      }
      (projectCreateModal.value as any).show();
    };

    return {
      projectCreateModal,
      openModal,
    };
  },
  data() {
    return {
      projectDetails: undefined,
      project: undefined,
      propertyListStatuses: [],
      statusFilter: undefined,
      buildingTypeFilter: undefined,
      serviceProviderSelection: undefined,
      serviceProviders: undefined,
      editConfirmation: false,
      viewName,
      search: '',
      tab: 'ProjectItems',
      columns: [
        { name: 'buildingReferenceId', label: 'Liegenschafts ID' },
        { name: 'pdtpCCRNumber', label: 'PDTP/CCR-Nummer' },
        { name: 'address', label: 'Anschrift' },
        { name: 'buildingType', label: 'Art' },
        { name: 'numberOfApartments', label: 'WIE' },
        { name: 'gatewayIdentifier', label: 'GWID' },
        { name: 'mainService', label: 'Main Service' },
        { name: 'productType', label: 'Produkt' },
        { name: 'useCase', label: 'Anwendungsfälle' },
        { name: 'status', label: 'Status' },
        { name: 'lastUpdate', label: 'Letzte Änderung ' },
      ],
      statusMappings: new Map([
        [
          EnterpriseProjectBuildingStatus.PROPERTY_CREATED,
          { value: EnterpriseProjectBuildingStatus.PROPERTY_CREATED, label: 'Liegenschaft erstellt' },
        ],
        [
          EnterpriseProjectBuildingStatus.PROPERTY_LIST_FINAL,
          { value: EnterpriseProjectBuildingStatus.PROPERTY_LIST_FINAL, label: 'Standortliste finalisiert' },
        ],
        [
          EnterpriseProjectBuildingStatus.ORDER_CONFIRMED,
          { value: EnterpriseProjectBuildingStatus.ORDER_CONFIRMED, label: 'Auftrag bestätigt' },
        ],
        [
          EnterpriseProjectBuildingStatus.INFORMATION_MISSING,
          { value: EnterpriseProjectBuildingStatus.INFORMATION_MISSING, label: 'fehlende Informationen' },
        ],
        [
          EnterpriseProjectBuildingStatus.IN_DEPLOYMENT,
          { value: EnterpriseProjectBuildingStatus.IN_DEPLOYMENT, label: 'im Rollout' },
        ],
        [
          EnterpriseProjectBuildingStatus.DEPLOYMENT_DONE,
          { value: EnterpriseProjectBuildingStatus.DEPLOYMENT_DONE, label: 'Rollout erledigt' },
        ],
        [EnterpriseProjectBuildingStatus.IN_QA, { value: EnterpriseProjectBuildingStatus.IN_QA, label: 'in QA' }],
        [
          EnterpriseProjectBuildingStatus.COMPLETED,
          { value: EnterpriseProjectBuildingStatus.COMPLETED, label: 'abgeschlossen' },
        ],
        [
          EnterpriseProjectBuildingStatus.CANCELLED,
          { value: EnterpriseProjectBuildingStatus.CANCELLED, label: 'storniert' },
        ],
      ]),
      projectStatusMappings: new Map([
        [EnterpriseProjectStatus.PLANNED, { value: EnterpriseProjectStatus.PLANNED, label: 'in Planung' }],
        [EnterpriseProjectStatus.CONFIRMED, { value: EnterpriseProjectStatus.CONFIRMED, label: 'Auftrag bestätigt' }],
        [EnterpriseProjectStatus.COMPLETE, { value: EnterpriseProjectStatus.COMPLETE, label: 'abgeschlossen' }],
        [EnterpriseProjectStatus.CANCELLED, { value: EnterpriseProjectStatus.CANCELLED, label: 'storniert' }],
      ]),
      buildingTypeFilterOptions: [
        { value: TreeNodeClassification.Liegenschaft, label: 'Liegenschaft' },
        { value: TreeNodeClassification.Gebaude, label: 'Gebaude' },
        { value: null, label: 'All' },
      ],
      saving: false,
    };
  },
  computed: {
    EnterpriseProjectBuildingStatus() {
      return EnterpriseProjectBuildingStatus;
    },
    projectObjectList(): ProjectObjectListItem[] {
      const list: ProjectObjectListItem[] = this.projectDetails?.items ?? [];
      // Only include buildings
      return list.filter(({ buildingType }) => !!buildingType);
    },
    loading(): boolean {
      return (
        (this.$apollo.queries.projectDetails?.loading || this.$apollo.queries.project?.loading || this.saving) ?? true
      );
    },
    take(): number {
      return 12;
    },
    totalPages(): number {
      const pageCount = Math.ceil((this.projectDetails?.count ?? 0) / this.take);

      return pageCount;
    },
    skip(): number {
      return (this.currentPage - 1) * this.take;
    },
    statusOptions(): Option[] {
      return Array.from(this.statusMappings.values());
    },
    buildingTypeOptions(): Option[] {
      return this.buildingTypeFilterOptions;
    },
    projectEditDescription(): string {
      if (this.project?.editOptions?.editable) {
        if (this.project.editOptions.projectDownloaded) {
          // Change Standorliste
          return 'Standortliste ändern';
        } else if (this.project.editOptions.buildingsCreated) {
          // Change Liegenschaften
          return 'Liegenschaften ändern';
        } else {
          // Resume Draft Project
          return 'Weiter mit dem Entwurf';
        }
      }

      // this will not be displayed
      return '';
    },
    shouldDisplayProjectEdit(): boolean {
      return this.project?.editOptions?.editable && this.project?.status !== EnterpriseProjectStatus.CANCELLED;
    },
    showDownloadButton(): boolean {
      // Return true if there is one status that is not PROPERTY_CREATED
      return this.propertyListStatuses.some(
        (status: string) => status !== EnterpriseProjectBuildingStatus.PROPERTY_CREATED,
      );
    },
    treeNodesCreated(): boolean {
      return this.project?.editOptions?.treeNodesCreated;
    },
  },
  watch: {
    async project() {
      if (!this.project) {
        return;
      }

      // const serviceProviders = await this.getServiceProviders(this.project.customer?.id );

      // if (!serviceProviders) {
      //   return;
      // }

      // this.serviceProviders = serviceProviders;
    },
    async currentPage() {
      await this.$apollo.queries.projectDetails.refetch();
    },
  },
  methods: {
    ...mapActions({
      addToastMessages: 'ADD_TOAST_MESSAGES',
    }),
    transformToDateOnly(date: string): string {
      if (date) {
        return new Date(date).toLocaleDateString('de-DE');
      }

      return '';
    },
    transformToTimeOnly(date: string): string {
      if (date) {
        return new Date(date).toLocaleTimeString('de-DE');
      }

      return '';
    },
    getStatusLabel(status: EnterpriseProjectBuildingStatus): string {
      if (this.statusMappings.has(status)) {
        return this.statusMappings.get(status)!.label;
      }

      return status;
    },
    onBuildingTypeFilterUpdate(value: string): void {
      if (value) {
        this.buildingTypeFilter = value as TreeNodeClassification;

        return;
      }

      this.buildingTypeFilter = null;
    },
    // Function when standortlisten erstellen button is clicked
    async updateStatusesAndDownloadExport() {
      // Update property statuses to PROPERTY_LIST_FINAL
      await this.updateAllPropertyStatuses({
        input: { projectReference: this.projectReference, status: EnterpriseProjectBuildingStatus.PROPERTY_LIST_FINAL },
      });
      // Download the export
      await this.downloadStandortliste();
    },
    async updateObjectStatus(input: EnterpriseProjectUpdateObjectStatusMutationVariables): Promise<string> {
      const { data } = await this.$apollo.mutate({
        mutation: updateObjectStatusMutation,
        variables: { input: input.input },
      });

      if (!data) {
        throw new Error('Status kann nicht aktualisiert werden!');
      }

      return data.customer;
    },
    async updateAllPropertyStatuses(input: UpdateProjectBuildingsStatusesVariables): Promise<void> {
      this.saving = true;
      const { data } = await this.$apollo.mutate({
        mutation: updateProjectBuildingsStatuses,
        variables: { input: input.input },
      });

      if (!data) {
        throw new Error('Der Status kann nicht aktualisiert werden!');
      }

      // Refresh projectDetails
      this.refetchQueries();
      this.saving = false;
    },
    async updateProjectStatus(input: EnterpriseProjectUpdateProjectStatusMutationVariables): Promise<string> {
      const { data } = await this.$apollo.mutate({
        mutation: updateProjectStatusMutation,
        variables: { input: input.input },
      });

      if (!data) {
        throw new Error('Status kann nicht aktualisiert werden!');
      }
      // Refresh projectDetails
      this.refetchQueries();

      return data.customer;
    },
    async getServiceProviders(customerId: string): Promise<Option[] | null> {
      try {
        const { data } = await this.$apollo.query({
          query: serviceProvidersQuery,
          variables: {
            customerId,
          },
        });

        const serviceProviders: ServiceProvider[] = data.serviceProviders?.items ?? [];

        return serviceProviders.map(({ id, name }) => ({ label: name, value: id }));
      } catch (error) {
        console.error('Beim Abrufen von Dienstanbietern ist ein Fehler aufgetreten:', error);
        return null;
      }
    },
    refetchQueries(): void {
      this.$apollo.queries.projectDetails.refetch();
      this.$apollo.queries.project.refetch();
      this.$apollo.queries.propertyListStatuses.refetch();
    },
    goToProjectList() {
      this.$router.push('/enterprise-project/project-list');
    },
    // Table Cells
    getGatewayIdentifier(gatewayIdentifier: any): string {
      return gatewayIdentifier?.identifier ? gatewayIdentifier.identifier : 'No Gateway Found';
    },
    goToGateway(gatewayIdentifier: any): void {
      if (gatewayIdentifier?.id) {
        this.$router.push({
          name: 'AppManager/TreeNode/Gateways/NewDetails',
          params: { treeNodeId: gatewayIdentifier.id, gatewayId: gatewayIdentifier.id },
        });
      }
    },
    getBuildingLetter(buildingType: string) {
      if (buildingType === TreeNodeClassification.Liegenschaft) {
        return 'L';
      }
      if (buildingType === TreeNodeClassification.Gebaude) {
        return 'G';
      }
    },
    shouldShowStatusDropdown(buildingType: TreeNodeClassification): boolean {
      return buildingType === TreeNodeClassification.Liegenschaft;
    },
    // Filtering
    onStatusFilterUpdate(value: string): void {
      this.statusFilter = value;
    },
    async onObjectStatusUpdate(value: string, id: string): Promise<void> {
      const status = value as EnterpriseProjectBuildingStatus;

      void (await this.updateObjectStatus({ input: { id, status } }));

      this.refetchQueries();
    },
    // Tab
    updateTab(tab: string) {
      this.tab = tab;
    },
    async editProject(): Promise<void> {
      this.editConfirmation = true;
    },
    onConfirmProjectEdit() {
      this.openModal();
    },
    onProjectEditCancelled() {
      this.editConfirmation = false;
    },

    async downloadStandortliste() {
      await generateStandortlist(this.project, this.$apollo.getClient());
    },
    copyToClipboard() {
      navigator.clipboard
        .writeText(this.projectReference)
        .then(() => {
          // Optional: Notify the user that the text has been copied
          this.addToastMessages({
            messages: [{ text: 'Projektreferenz kopiert', class: 'success' }],
          });
        })
        .catch((err) => {
          console.error('Failed to copy text: ', err);
        });
    },
    isLGateway(identifier: string): boolean {
      const parts = identifier.split('_');
      return parts.length > 1 && parts[1].startsWith('L');
    },
    // Cancel button
    async cancelProject() {
      await this.updateAllPropertyStatuses({
        input: { projectReference: this.projectReference, status: EnterpriseProjectBuildingStatus.CANCELLED },
      });
      await this.updateProjectStatus({
        input: { projectReference: this.projectReference, status: EnterpriseProjectStatus.CANCELLED },
      });
    },
    getMainServiceText(serviceType: EnterpriseProjectServiceType): string {
      // NB: GK_HUB_G should be excluded on FE
      if (serviceType === EnterpriseProjectServiceType.GK_HUB_L_GDSP_MOBILE_WIRELESS) {
        return 'GK_HUB_L_Kabel';
      }
      if (serviceType === EnterpriseProjectServiceType.GK_HUB_L_CABLE) {
        return 'GK_HUB_L_GDSP_MOBILFUNK';
      }
      return '';
    },
  },
});
