import {
  AlertCustomerDefaultRulesQuery,
  AlertCustomerDefaultRulesQuery_alertCustomerDefaultRules,
} from '@/hsc-api/queries/__generated__/AlertCustomerDefaultRulesQuery';
import { AlertHeatingSystemByCustomerIdQuery_alertHeatingSystemRuleByIdOrCustomerId_calloutTimeConfiguration } from '@/hsc-api/queries/__generated__/AlertHeatingSystemByCustomerIdQuery';
import {
  AlertMeasurementRulesQuery,
  AlertMeasurementRulesQuery_alertMeasurementRules_historyPoint,
} from '@/hsc-api/queries/__generated__/AlertMeasurementRulesQuery';
import { HeatingSystemsWithPaginationQuery } from '@/hsc-api/queries/__generated__/HeatingSystemsWithPaginationQuery';
import { CalloutTimeDay, CalloutTimeInput, UpdateAlertMeasurementRulesInput } from '@/types/hsc-types';
export type CalloutTimeMeta = Pick<CalloutTimeInput, 'day'> & { dayAsString?: string };
export type CalloutTimeOutput =
  AlertHeatingSystemByCustomerIdQuery_alertHeatingSystemRuleByIdOrCustomerId_calloutTimeConfiguration;
export const defaultCalloutTimeCOnfigurationsMeta: CalloutTimeMeta[] = [
  {
    day: CalloutTimeDay.MONDAY,
    dayAsString: 'Montag',
  },
  {
    day: CalloutTimeDay.TUESDAY,
    dayAsString: 'Dienstag',
  },
  {
    day: CalloutTimeDay.WEDNESDAY,
    dayAsString: 'Mittwoch',
  },
  {
    day: CalloutTimeDay.THURSDAY,
    dayAsString: 'Donnerstag',
  },
  {
    day: CalloutTimeDay.FRIDAY,
    dayAsString: 'Freitag',
  },
  {
    day: CalloutTimeDay.SATURDAY,
    dayAsString: 'Samstag',
  },
  {
    day: CalloutTimeDay.SUNDAY,
    dayAsString: 'Sonntag',
  },
  {
    day: CalloutTimeDay.HOLIDAY,
    dayAsString: 'Feiertag',
  },
];
export const defaultCalloutTimeConfigurations: CalloutTimeOutput[] = [
  { __typename: 'CalloutTimeOutput', isActive: false, day: CalloutTimeDay.MONDAY, from: null, to: null },
  { __typename: 'CalloutTimeOutput', isActive: false, day: CalloutTimeDay.TUESDAY, from: null, to: null },
  { __typename: 'CalloutTimeOutput', isActive: false, day: CalloutTimeDay.WEDNESDAY, from: null, to: null },
  { __typename: 'CalloutTimeOutput', isActive: false, day: CalloutTimeDay.THURSDAY, from: null, to: null },
  { __typename: 'CalloutTimeOutput', isActive: false, day: CalloutTimeDay.FRIDAY, from: null, to: null },
  { __typename: 'CalloutTimeOutput', isActive: false, day: CalloutTimeDay.SATURDAY, from: null, to: null },
  { __typename: 'CalloutTimeOutput', isActive: false, day: CalloutTimeDay.SUNDAY, from: null, to: null },
  {
    __typename: 'CalloutTimeOutput',
    isActive: false,
    day: CalloutTimeDay.HOLIDAY, //Feirtag
    from: null,
    to: null,
  },
];

export type AlertCustomerDefaultRules = AlertCustomerDefaultRulesQuery['alertCustomerDefaultRules'];
export type AlertCustomerDefaultRule = AlertCustomerDefaultRulesQuery_alertCustomerDefaultRules;

export enum AlertTypeLabel {
  KEIN_ALARM = 'Kein Alarm',
  STANDARD_ALARM = 'Standard',
  EMERGENCY_ALARM = 'Notdienst',
}

export type HeatingSystem = HeatingSystemsWithPaginationQuery['heatingSystemCollection']['items'][number];
export type HeatingSystemMeasurementGroup = HeatingSystem['heatingSystemMeasurementGroups'][number];
export type AlertMeasurementRules = AlertMeasurementRulesQuery['alertMeasurementRules'][number];

export type HistoryPoint = AlertMeasurementRulesQuery_alertMeasurementRules_historyPoint;
export type UpdateAlertMesurementRule = UpdateAlertMeasurementRulesInput & {
  measurementType: string;
  historyPoint?: HistoryPoint[] | undefined;
};
export interface SettingMeasurementComponent {
  systemComponentTypeName: string;
  measurementRules: UpdateAlertMesurementRule[];
}

export enum MeasurementType {
  VORLAUF = 'Vorlauf',
  RUCKLAUF = 'Rücklauf',
  WARMWASSER = 'Warmwasser',
  ZIRKULATION = 'Zirkulation',
  DELTA = 'Delta',
}
export const MeasurementTypeSorted = {
  [MeasurementType.VORLAUF]: 1,
  [MeasurementType.RUCKLAUF]: 0,
  [MeasurementType.WARMWASSER]: 1,
  [MeasurementType.ZIRKULATION]: 0,
  [MeasurementType.DELTA]: -1,
};
