


















import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import AlertDefaultSettingsPerComponent from './AlertDefaultSettingsPerComponent.vue';
import AlertGeneralSettings from './AlertGeneralSettings.vue';
import AlertServiceProviderContacts from './AlertServiceProviderContacts.vue';
import AlertSettingsHeader from './AlertSettingsHeader.vue';
import AlertIndividualConfigurationOfComponents from './AlertIndividualConfigurationOfComponents.vue';

@Component({
  components: {
    AlertGeneralSettings,
    AlertSettingsHeader,
    AlertDefaultSettingsPerComponent,
    AlertServiceProviderContacts,
    AlertIndividualConfigurationOfComponents,
  },
  data() {
    return {
      customerId: undefined,
    };
  },
})
export default class SettingsView extends Vue {
  @StringProp(true)
  private readonly treeNodeId!: string;
}
