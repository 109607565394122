









































































import { StringProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import editHeatingSystemMeasurementMutation from './edit-measurement.gql';
import editEneriqMeasurementMutation from './edit-eneriq-measurement.gql';
import disconnectEneriqMeasurementMutation from './disconnect-eneriq-measurement.gql';
import connectEneriqMeasurementMutation from '../add-heating-system-measurement-wizard-control/connect-eneriq-measurement.gql';
import {
  AppHeatingSystemEditHeatingSystemMeasurementMutation,
  AppHeatingSystemEditHeatingSystemMeasurementMutationVariables,
} from './__generated__/AppHeatingSystemEditHeatingSystemMeasurementMutation';
import {
  AppHeatingSystemConnectEnerIqMeasurementMutation,
  AppHeatingSystemConnectEnerIqMeasurementMutationVariables,
} from '../add-heating-system-measurement-wizard-control/__generated__/AppHeatingSystemConnectEnerIqMeasurementMutation';
import {
  AppHeatingSystemEditEnerIqMeasurementMutation,
  AppHeatingSystemEditEnerIqMeasurementMutationVariables,
} from './__generated__/AppHeatingSystemEditEnerIqMeasurementMutation';
import {
  AppHeatingSystemDisconnectEnerIqMeasurementMutation,
  AppHeatingSystemDisconnectEnerIqMeasurementMutationVariables,
} from './__generated__/AppHeatingSystemDisconnectEnerIqMeasurementMutation';
import {
  EnerIqMeasurementSystemComponentType,
  EnerIqMeasurementSourceType,
  EditHeatingSystemMeasurementInput,
  EditEnerIqMeasurementInput,
  ConnectEnerIqMeasurementInput,
} from '@/types/iot-portal';
import query from './view.gql';
import {
  AppHeatingSystemEditHeatingSystemMeasurementWizardControlQuery,
  AppHeatingSystemEditHeatingSystemMeasurementWizardControlQueryVariables,
  AppHeatingSystemEditHeatingSystemMeasurementWizardControlQuery_heatingSystems_first_measurements,
} from './__generated__/AppHeatingSystemEditHeatingSystemMeasurementWizardControlQuery';
import { isNonEmptyString } from '@/util/string';
import {
  EneriqTypesMixin,
  sourceType,
} from '@/features/app-heating-system/components/mixins/measurements/eneriq-types-mixin';
import FormField from '@/features/ui/form/FormField.global.vue';

interface EnerIQInput {
  link: boolean;
  systemComponentType?: EnerIqMeasurementSystemComponentType;
  sourceType?: EnerIqMeasurementSourceType;
  componentNumber?: string;
}

interface FormData {
  measurement: Omit<EditHeatingSystemMeasurementInput, 'id'>;
  enerIQ: EnerIQInput;
}

@Component({
  apollo: {
    heatingSystems: {
      query,
      variables(
        this: EditHeatingSystemMeasurementWizardControl,
      ): AppHeatingSystemEditHeatingSystemMeasurementWizardControlQueryVariables {
        return { heatingSystemId: this.heatingSystemId, measurementId: this.measurementId };
      },
    },
  },
  data() {
    return {
      heatingSystems: undefined,
      isNonEmptyString,
      sourceTypes: [],
    };
  },
})
export default class EditHeatingSystemMeasurementWizardControl extends Mixins(EneriqTypesMixin) {
  @StringProp(true)
  private readonly measurementId!: string;

  @StringProp(true)
  private readonly heatingSystemId!: string;

  private sourceTypes: sourceType[] = [];
  public readonly $refs!: { sourceType: FormField };

  private heatingSystems?: AppHeatingSystemEditHeatingSystemMeasurementWizardControlQuery['heatingSystems'];

  private get enerIQSettingsVisible(): boolean {
    return this.heatingSystems?.first?.enerIqConnectedEconomicUnit != null;
  }

  private get measurement():
    | AppHeatingSystemEditHeatingSystemMeasurementWizardControlQuery_heatingSystems_first_measurements
    | undefined {
    return this.heatingSystems?.first?.measurements[0];
  }

  private onWizardShow(): void {
    if (this.measurement?.enerIqMeasurement?.systemComponentType) {
      this.sourceTypes =
        this.components.find((el) => el.typeName == this.measurement?.enerIqMeasurement?.systemComponentType)
          ?.sourceTypes ?? [];
    }
  }

  private get initialData(): Partial<FormData> {
    return {
      measurement: {
        name: this.measurement?.name,
      },
      enerIQ: {
        ...this.measurement?.enerIqMeasurement,
        link: this.measurement?.enerIqMeasurement !== null,
        componentNumber: String(this.measurement?.enerIqMeasurement?.group ?? 1),
      },
    };
  }

  private isFormValid(input: Partial<EditHeatingSystemMeasurementInput>): boolean {
    return isNonEmptyString(input.name);
  }

  private isEnerIQValid(input: EnerIQInput): boolean {
    return (
      !input.link ||
      (isNonEmptyString(input.sourceType) &&
        isNonEmptyString(input.systemComponentType) &&
        Number(input.componentNumber) > 0)
    );
  }

  private systemComponentTypeSelected(typeName: string): void {
    this.sourceTypes = this.components.find((el) => el.typeName == typeName)?.sourceTypes ?? [];
    if (this.sourceTypes.length === 1) {
      this.$refs.sourceType.value = this.sourceTypes[0].key;
    }
  }

  private async disconnectEnerIq(id: string): Promise<void> {
    const { data } = await this.$apollo.mutate<
      AppHeatingSystemDisconnectEnerIqMeasurementMutation,
      AppHeatingSystemDisconnectEnerIqMeasurementMutationVariables
    >({
      mutation: disconnectEneriqMeasurementMutation,
      variables: {
        input: { id },
      },
    });

    if (!data) {
      throw new Error(
        'Die Messstelle konnte nicht von der EnerIq EconomicUnit getrennt werden. Versuchen Sie es erneut!',
      );
    }
  }

  private async connectEnerIq(input: ConnectEnerIqMeasurementInput): Promise<void> {
    const { data } = await this.$apollo.mutate<
      AppHeatingSystemConnectEnerIqMeasurementMutation,
      AppHeatingSystemConnectEnerIqMeasurementMutationVariables
    >({
      mutation: connectEneriqMeasurementMutation,
      variables: { input },
    });

    if (!data) {
      throw new Error(
        'Die Messstelle konnte nicht mit der EnerIq EconomicUnit verbunden werden. Versuchen Sie es erneut!',
      );
    }
  }

  private async editEnerIq(input: EditEnerIqMeasurementInput): Promise<void> {
    const { data } = await this.$apollo.mutate<
      AppHeatingSystemEditEnerIqMeasurementMutation,
      AppHeatingSystemEditEnerIqMeasurementMutationVariables
    >({
      mutation: editEneriqMeasurementMutation,
      variables: { input },
    });

    if (!data) {
      throw new Error(
        'Die EnerIq-Einstellungen der Messtelle konnten nicht aktualisiert werden. Versuchen Sie es erneut über die Editierfunktion!',
      );
    }
  }

  private async editHeatingSystemMeasurement(input: FormData): Promise<void> {
    if (!input.enerIQ.link && !!this.measurement?.enerIqMeasurement) {
      // disconnect
      await this.disconnectEnerIq(this.measurement.enerIqMeasurement.id);
    } else if (
      input.enerIQ.link &&
      !this.measurement?.enerIqMeasurement &&
      this.heatingSystems?.first?.enerIqConnectedEconomicUnit?.id &&
      input.enerIQ.componentNumber &&
      input.enerIQ.sourceType &&
      input.enerIQ.systemComponentType
    ) {
      //connect
      await this.connectEnerIq({
        enerIqEconomicUnitId: this.heatingSystems?.first?.enerIqConnectedEconomicUnit?.id,
        group: Number(input.enerIQ.componentNumber),
        heatingSystemMeasurementId: this.measurementId,
        sourceType: input.enerIQ.sourceType,
        systemComponentType: input.enerIQ.systemComponentType,
      });
    } else if (input.enerIQ.link && this.measurement?.enerIqMeasurement) {
      //edit
      await this.editEnerIq({
        enerIqMeasurementId: this.measurement.enerIqMeasurement.id,
        group: Number(input.enerIQ.componentNumber),
        sourceType: input.enerIQ.sourceType,
        systemComponentType: input.enerIQ.systemComponentType,
      });
    }

    const { data } = await this.$apollo.mutate<
      AppHeatingSystemEditHeatingSystemMeasurementMutation,
      AppHeatingSystemEditHeatingSystemMeasurementMutationVariables
    >({
      mutation: editHeatingSystemMeasurementMutation,
      variables: { input: { ...input.measurement, id: this.measurementId } },
    });

    if (!data) {
      throw new Error('Die Messstelle konnte nicht geändert werden!');
    }
  }
}
