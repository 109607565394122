










































































import { scaleLinear, ScaleLinear } from 'd3-scale';
import { Component, Vue } from 'vue-property-decorator';
import { ArrayProp, NumberProp, StringProp } from '@/util/prop-decorators';

const WIDTH = 80;
const HEIGHT = 160;
const BULB_RADIUS = 18;
const TUBE_WIDTH = 18.5;

@Component
export default class Thermometer extends Vue {
  @NumberProp(0)
  private readonly min!: number;

  @NumberProp(100)
  private readonly max!: number;

  @StringProp('°')
  private readonly unit!: string;

  @NumberProp(10)
  private readonly ticksCount!: number;

  @NumberProp(false)
  private readonly target?: number;

  @ArrayProp(() => [])
  private readonly ranges!: { from: number; to: number; color: string }[];

  @NumberProp()
  private readonly value?: number;

  @StringProp()
  private readonly text?: string;

  private get scale(): ScaleLinear<number, number> {
    const bottomY = HEIGHT - 5,
      topY = 5;

    const dim = {
      bottomY,
      topCy: topY + TUBE_WIDTH / 2,
      topY: topY + TUBE_WIDTH / 2,
      bulbCx: WIDTH / 2,
      bulbCy: bottomY - BULB_RADIUS,
    };

    const domain = [0, 1];

    const scale = scaleLinear()
      .range([dim.bulbCy - BULB_RADIUS + 2.5, dim.topCy])
      .domain(domain);

    return scale;
  }

  private get percentNumberToAbsoluteScale(): ScaleLinear<number, number> {
    return scaleLinear().range([this.min, this.max]).domain([0, 1]);
  }

  private get bulbFillColor(): string {
    return this.ranges[0]?.from === 0 ? this.ranges[0].color : 'lightgrey';
  }

  private get rangeRectProps(): { color: string; height: number; y: number }[] {
    return this.ranges.map((range) => ({
      color: range.color,
      height: this.scale(range.from) - this.scale(range.to),
      y: this.scale(range.to),
    }));
  }

  private get targetY(): number | undefined {
    if (this.target !== undefined) {
      return this.scale(this.target);
    }

    return undefined;
  }

  private get labelFontSize(): ScaleLinear<number, number> {
    return scaleLinear().domain([5, 15]).range([12, 7]);
  }

  private get currentY(): number {
    return this.scale(this.value ?? 0);
  }

  private get axisSteps(): { value: number; y: number }[] {
    const labelNumbersToPercent = scaleLinear().domain([this.min, this.max]).range([0, 1]);

    return scaleLinear()
      .domain([this.min, this.max])
      .ticks(this.ticksCount)
      .map((tick) => ({
        y: this.scale(labelNumbersToPercent(tick)),
        value: tick,
      }));
  }
}
