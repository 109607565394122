import { render, staticRenderFns } from "./EnterpriseProjectDetailOverview.vue?vue&type=template&id=63318c06&scoped=true&"
import script from "./EnterpriseProjectDetailOverview.vue?vue&type=script&lang=ts&"
export * from "./EnterpriseProjectDetailOverview.vue?vue&type=script&lang=ts&"
import style0 from "./EnterpriseProjectDetailOverview.vue?vue&type=style&index=0&id=63318c06&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63318c06",
  null
  
)

export default component.exports