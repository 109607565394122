var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-panel',[(_vm.treeNodeType !== 'Directory' && _vm.treeNodeType !== 'RootDirectory')?_c('div',[_c('ui-form-message',[_vm._v(" Bitte wählen Sie ein Wurzelverzeichnis oder ein Verzeichnis ")])],1):_c('div',[_c('ui-form-message',[_vm._v(" Von hier aus können Sie Gas- und Wärmezähler Heizungsanlagen zuordnen, die sich im gleichen Verzeichnis befinden ")]),_c('br'),_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-flex',{attrs:{"items":"center"}},[_c('ui-cell',{attrs:{"min":150,"max":280}},[_c('ui-input-select',{attrs:{"value":_vm.role,"data-element-name":'role-select',"options":_vm.roleOptions,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"placeholder":"Gerätetyp"},on:{"update":function($event){return _vm.changeDataValue('role', $event)}}})],1),_c('ui-cell',{attrs:{"min":150,"max":280}},[_c('ui-input-select',{attrs:{"data-element-name":'mapped-select',"value":_vm.mappedFilter,"options":_vm.mappedFilterOptions,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"placeholder":"Mapped Status"},on:{"update":function($event){return _vm.changeDataValue('mappedFilter', $event)}}})],1)],1)],1),_c('ui-table-skeleton',{attrs:{"ready":!_vm.dataLoading,"column-count":4,"line-count":2,"header-line-count":2}}),(!_vm.dataLoading)?_c('ui-cell',[_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('directory-spots-table',{attrs:{"heating-system-list":_vm.heatingSystemsList,"sibling-tree-nodes":_vm.treeNodeSiblings,"spots":_vm.filteredSpots,"heating-system-measurement-groups":_vm.heatingSystemMeasurementGroups,"callback-function":_vm.assignmentCallback},on:{"tree-node-click":function($event){return _vm.$emit('tree-node-click', $event)},"spot-click":function($event){return _vm.$emit('spot-click', $event)}}}),(_vm.totalPages > 1)?_c('ui-cell',{attrs:{"basis":1}},[_c('ui-pagination',{attrs:{"value":_vm.currentPage,"total-pages":_vm.totalPages},on:{"input":function($event){return _vm.$emit('update:currentPage', $event)}}})],1):_vm._e()],1)],1)],1):_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-message',[_vm._v("Keine Geräte entsprechen dem eingestelltem Filter")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }