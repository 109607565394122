




































import { NumberProp, ArrayProp, StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { scaleLinear, ScaleLinear } from 'd3-scale';
import { arc } from 'd3-shape';

const WIDTH = 330;
const HEIGHT = 190;
const RING_INSET = 25;
const RING_WIDTH = 18;
const MIN_ANGLE = -90;
const MAX_ANGLE = 90;
const LABEL_INSET = 18;

function deg2rad(deg: number): number {
  return (deg * Math.PI) / 180;
}

function oneDefinedOrEqual(a?: number, b?: number): boolean {
  if (a === undefined && b === undefined) {
    return false;
  }

  return a === undefined || b === undefined || a === b;
}

@Component
export default class extends Vue {
  @NumberProp()
  private readonly value?: number;

  @StringProp()
  private readonly text?: string;

  @StringProp('')
  private readonly unit!: string;

  @NumberProp(0)
  private readonly min!: number;

  @NumberProp(100)
  private readonly max!: number;

  @NumberProp(10)
  private readonly ticksCount!: number;

  @NumberProp()
  private readonly minTarget?: number;

  @NumberProp()
  private readonly maxTarget?: number;

  @ArrayProp(() => [])
  private readonly ranges!: { from: number; to: number; color: string }[];

  private readonly radius = WIDTH / 2;

  private readonly range = MAX_ANGLE - MIN_ANGLE;

  private readonly WIDTH = WIDTH;
  private readonly HEIGHT = HEIGHT;

  private get scale(): ScaleLinear<number, number> {
    return scaleLinear().range([0, 1]).domain([0, 1]);
  }

  private pointerAngle(value?: number): number {
    if (value === undefined) {
      return MIN_ANGLE - 10;
    }

    return MIN_ANGLE + value * this.range;
  }

  private get labelFontSize(): ScaleLinear<number, number> {
    return scaleLinear().domain([5, 30]).range([23, 8]);
  }

  private get rangePaths(): { color: string; arc: string | null }[] {
    const arcCalculation = arc<{ from: number; to: number }>()
      .innerRadius(this.radius - RING_WIDTH - RING_INSET)
      .outerRadius(this.radius - RING_INSET)
      .startAngle((d) => {
        return deg2rad(MIN_ANGLE + d.from * this.range);
      })
      .endAngle((d) => {
        return deg2rad(MIN_ANGLE + d.to * this.range);
      });

    return [{ from: 0, to: 1, color: 'lightgrey' }, ...this.ranges].map((range) => ({
      ...range,
      arc: arcCalculation(range),
    }));
  }

  private textOffset(label: number, steps: number, base: number, prefix: string, postfix: string): number {
    return base - (String(label).length + prefix.length + postfix.length) * steps;
  }

  private get absoluteNumberToPercentScale(): ScaleLinear<number, number> {
    return scaleLinear().domain([this.min, this.max]).range([0, 1]);
  }

  private get percentNumberToAbsoluteScale(): ScaleLinear<number, number> {
    return scaleLinear().range([this.min, this.max]).domain([0, 1]);
  }

  private get labels(): { transform: string; labelNumber: number }[] {
    const labelTransform = (d: number, isLast: boolean): string => {
      const ratio = this.absoluteNumberToPercentScale(d);
      const newAngle = MIN_ANGLE + ratio * this.range;
      return `rotate(${newAngle}) translate(${isLast ? -15 : -5}, ${LABEL_INSET - this.radius})`;
    };
    return scaleLinear()
      .domain([this.min, this.max])
      .ticks(this.ticksCount)
      .map((labelNumber, index, arr) => ({
        labelNumber,
        transform: labelTransform(labelNumber, arr.length === index + 1),
      }));
  }

  private get delta(): string | undefined {
    if (oneDefinedOrEqual(this.minTarget, this.maxTarget) && this.value !== undefined) {
      return this.percentNumberToAbsoluteScale(this.value - (this.minTarget ?? this.maxTarget ?? 0)).toLocaleString();
    }

    return undefined;
  }

  private get hasExactTarget(): boolean {
    return oneDefinedOrEqual(this.minTarget, this.maxTarget);
  }

  private get exactTarget(): number | undefined {
    return this.minTarget ?? this.maxTarget;
  }

  private readonly targetArchY = this.radius - (RING_WIDTH * 1.7 + RING_INSET);

  private get targetArch(): string | undefined {
    if (this.minTarget === undefined && this.maxTarget === undefined) {
      return undefined;
    }

    const targetArcCalculation = arc<{ from: number; to: number }>()
      .innerRadius(5)
      .outerRadius(this.targetArchY)
      .startAngle((d) => {
        return deg2rad(MIN_ANGLE + d.from * this.range);
      })
      .endAngle((d) => {
        return deg2rad(MIN_ANGLE + d.to * this.range);
      });
    const path = targetArcCalculation({
      from: this.minTarget ?? 0,
      to: this.maxTarget ?? 0,
    });

    return path ?? undefined;
  }
}
