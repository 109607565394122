export interface EnerIQRecommendationsTranslations {
  lang: string;
  value: string;
}

export interface EnerIQEconomicUnitStateRecommendationsRecommendations {
  indicator: EnerIQIndicator;
  statusCode: string;
  translations: EnerIQRecommendationsTranslations[];
}

export interface EnerIQEconomicUnitStateRecommendations {
  date: Date;
  context: {
    id: string;
    type: string;
    title: string;
    economicUnit: string;
    foreignIdentifier: string;
  };
  indicator: string;
  contextIri: string;
  recommendations: EnerIQEconomicUnitStateRecommendationsRecommendations[];
}

export interface EnerIQEconomicUnitState {
  color: string;
  rating: number;
  indicator: string;
  isHealthy: boolean;
  statusIri: string;
  updatedAt: Date;
  contextIri: string;
  recommendations: EnerIQEconomicUnitStateRecommendations[];
}

export interface EnerIQEconomicUnit {
  id: string;
  creationDate: Date;
  modificationDate: Date;
  state: EnerIQEconomicUnitState;
}

export enum EnerIQIndicator {
  GREEN = 'green',
  YELLOW = 'yellow',
  YELLOW_RED = 'yellow_red',
  RED = 'red',
}

export const alarmStateIndicators = [EnerIQIndicator.YELLOW, EnerIQIndicator.YELLOW_RED, EnerIQIndicator.RED];
