








import Gauge from '@/features/domain-ui/gauge/Gauge.global.vue';
import Thermometer from '@/features/domain-ui/thermometer/Thermometer.global.vue';
import { scaleLinear } from 'd3-scale';
import { Component as ComponentType } from 'vue';
import { Component } from 'vue-property-decorator';
import HeatingSystemMeasurement, { Measurement } from './Measurement.vue';
import {
  GaugeAttributes,
  TemperatureRange,
  TEMPERATURE_MEASUREMENT_METRIC_NAMES,
  ThermometerAttributes,
} from './model';

@Component
export default class HeatingSystemTemperatureMeasurement extends HeatingSystemMeasurement {
  private get gaugeRanges(): TemperatureRange[] {
    return [
      { from: 0.0, to: 0.2, color: '#52A300' },
      { from: 0.2, to: 0.4, color: '#A8B400' },
      { from: 0.4, to: 0.6, color: '#FECB00' },
      { from: 0.6, to: 0.8, color: '#F5A623' },
      { from: 0.8, to: 1, color: '#E60000' },
    ];
  }

  private get thermometerRanges(): TemperatureRange[] {
    return [
      { from: 0.0, to: 0.4, color: '#fe004f' },
      { from: 0.4, to: 0.5, color: '#f5a623' },
      { from: 0.5, to: 0.7, color: '#66cd05' },
      { from: 0.7, to: 0.9, color: '#f5a623' },
      { from: 0.9, to: 1, color: '#fe004f' },
    ];
  }

  private get gaugeAttributes(): GaugeAttributes {
    return {
      ranges: this.gaugeRanges,
      minTarget: 0.1,
      maxTarget: 0.4,
      min: 0,
      max: 100,
      ticksCount: 5,
      unit: '°C',
    };
  }

  private get thermometerAttributes(): ThermometerAttributes {
    return {
      ranges: this.thermometerRanges,
      target: 0.5,
      min: -10,
      max: 60,
      ticksCount: 15,
      unit: '°C',
    };
  }

  private component(measurement: Measurement): ComponentType {
    if (this.isThermometer(measurement.metricName)) {
      return Thermometer;
    }

    return Gauge;
  }

  private componentAttributes(measurement: Measurement): GaugeAttributes | ThermometerAttributes {
    if (this.isThermometer(measurement.metricName)) {
      return {
        ...this.thermometerAttributes,
        value: this.formatThermometerTemperatureIndicatorValue(measurement.latestMetricPoint?.value),
        text: measurement.latestMetricPoint?.value.toString() ?? 'N/A',
      };
    }

    return {
      ...this.gaugeAttributes,
      value: this.formatGaugeTemperatureIndicatorValue(measurement.latestMetricPoint?.value),
      text: measurement.latestMetricPoint?.value.toString() ?? 'N/A',
    };
  }

  private formatThermometerTemperatureIndicatorValue(value?: number): number {
    const { min, max } = this.thermometerAttributes;

    if (value === undefined || min === undefined || max === undefined) {
      return 0;
    }

    return scaleLinear().range([min, max]).domain([0, 1]).invert(value);
  }

  private formatGaugeTemperatureIndicatorValue(value?: number): number {
    const { min, max } = this.gaugeAttributes;
    if (value === undefined || min === undefined || max === undefined) {
      return 0;
    }

    return scaleLinear().range([min, max]).domain([0, 1]).invert(value);
  }

  private isThermometer(name: string): boolean {
    return TEMPERATURE_MEASUREMENT_METRIC_NAMES.THERMOMETER.findIndex((value) => value.includes(name)) !== -1;
  }
}
