var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-wizard',{attrs:{"title":"Messstelle bearbeiten"},on:{"show":_vm.onWizardShow},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var show = ref.show;
return [_c('ui-link',{on:{"click":show}},[_vm._v("Bearbeiten")])]}},{key:"content",fn:function(ref){
var hide = ref.hide;
return [_c('ui-form',{attrs:{"initial-data":_vm.initialData,"action":_vm.editHeatingSystemMeasurement},on:{"submitted":hide},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var dirty = ref.dirty;
var busy = ref.busy;
var submit = ref.submit;
var errorMessages = ref.errorMessages;
return [_c('core-toast',{attrs:{"messages":errorMessages,"error":""}}),_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-text","path":"measurement.name","label":"Name","error":dirty && !_vm.isNonEmptyString(formData.measurement.name)}})],1),(_vm.enerIQSettingsVisible)?_c('ui-cell',{attrs:{"basis":1}},[_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-checkbox","path":"enerIQ.link","option-label":"Messstelle mit EnerIQ Verknüpfen","label":' '}})],1),(formData.enerIQ && formData.enerIQ.link)?_c('ui-cell',{attrs:{"basis":1}},[_c('ui-flex',{attrs:{"direction":"row","basis":1}},[_c('ui-cell',[_c('ui-form-field',{attrs:{"input":"ui-input-select","path":"enerIQ.systemComponentType","label":"Komponente","option-label":"label","options":_vm.components,"reduce":function (ref) {
	var typeName = ref.typeName;

	return typeName;
},"error":dirty && !!formData.enerIQ.link && !_vm.isNonEmptyString(formData.enerIQ.systemComponentType)},on:{"input":_vm.systemComponentTypeSelected}})],1),_c('ui-cell',[_c('ui-form-field',{attrs:{"input":"ui-input-text","path":"enerIQ.componentNumber","initial-path":"enerIQ.componentNumber","type":"number","min":"1","label":"Komponentennummer","error":dirty && !!formData.enerIQ.link && !Number(formData.enerIQ.componentNumber) > 0}})],1)],1)],1):_vm._e(),(formData.enerIQ && formData.enerIQ.link)?_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{ref:"sourceType",attrs:{"input":"ui-input-select","path":"enerIQ.sourceType","label":"Messstellentyp","option-label":"label","options":_vm.sourceTypes,"reduce":function (ref) {
	var key = ref.key;

	return key;
},"error":dirty && !!formData.enerIQ.link && !_vm.isNonEmptyString(formData.enerIQ.sourceType)}})],1):_vm._e()],1)],1):_vm._e(),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard-button',{attrs:{"disabled":!dirty || !_vm.isEnerIQValid(formData.enerIQ)|| !_vm.isFormValid(formData.measurement),"busy":busy},on:{"click":submit}},[_vm._v("Speichern")])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }