





























import { defineComponent } from '@vue/composition-api';
import { mapState, mapActions, mapGetters } from 'vuex';
import InputText from '@/features/ui/inputs/InputText.global.vue';

export default defineComponent<any, Record<string, unknown>, any, any, any>({
  components: {
    InputText,
  },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    ...mapGetters(['currentTab']),
    ...mapState({
      buildings: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.buildings,
      activeStep2BuildingId: (state: any) => state.EnterpriseProjectCreateModule.activeStep2BuildingId,
      buildingsCreated: (state: any) => state.EnterpriseProjectCreateModule.buildingsCreated,
      editableBuildings: (state: any) => state.EnterpriseProjectCreateModule.editableBuildings,
    }),
    liegenshaftBuildings() {
      return this.buildings.filter((building: any) => building.liegenshaft);
    },
    filteredBuildings() {
      return this.liegenshaftBuildings.filter((building: any) =>
        building.name.toLowerCase().includes(this.search.toLowerCase()),
      );
    },
  },
  methods: {
    ...mapActions(['setActiveStep2BuildingId']),
    setActiveBuilding(id: number) {
      this.setActiveStep2BuildingId(id);
    },
    getListItemSubText(building: any): string {
      if (building.buildingId === this.activeStep2BuildingId) {
        return 'in Bearbeitung';
      }

      if (this.buildingsCreated && !this.editableBuildings.has(`${building.buildingId}`)) {
        // uneditable building in a project where buildings are already created
        return 'nicht editierbar';
      }

      return 'ändern';
    },
  },
});
