var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('Table',{staticClass:"project-list-container",attrs:{"ready":!_vm.loading,"columns":_vm.columns,"rows":_vm.projectList,"data-element-id":'app-enterprise-project-project-list-table'},scopedSlots:_vm._u([{key:"projectItems.label",fn:function(ref){
var column = ref.column;
return [_c('ProjectPropertyPopup',{attrs:{"tooltip-content":_vm.getTooltipContent()}},[_vm._v(" "+_vm._s(column.label)+" ")])]}},{key:"customerId",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getCustomerNumber(row))+" ")]}},{key:"customerName",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getCustomerName(row))+" ")]}},{key:"reference",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.projectReference)+" ")]}},{key:"salesContactUserName",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.salesContactUserName)+" ")]}},{key:"numberOfLProperties",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.numberOfLProperties)+" ")]}},{key:"numberOfGProperties",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.numberOfGProperties)+" ")]}},{key:"numberOfApartments",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.numberOfApartments)+" ")]}},{key:"projectItems",fn:function(ref){
var row = ref.row;
return [_c('ProjectLiegenshaftStatus',{attrs:{"project-items":row.projectItems}})]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"status-cell",style:(_vm.getStyle(row.status))},[_vm._v(" "+_vm._s(_vm.getStatusLabel(row.status))+" ")])]}},{key:"ProjectCreationAndCompletionDates",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.transformToDateOnly(row.createdAt))+" / "+_vm._s(_vm.transformToDateOnly(row.closedAt))+" ")]}},{key:"lastUpdate",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.transformToDateOnly(row.lastUpdate.lastUpdatedTimestamp))+", "+_vm._s(_vm.transformToTimeOnly(row.lastUpdate.lastUpdatedTimestamp))+" / "+_vm._s(row.lastUpdate.lastUpdatedByUser.firstName)+" "+_vm._s(row.lastUpdate.lastUpdatedByUser.lastName)+" ")]}},{key:"serviceProviderName",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.serviceProvider ? row.serviceProvider.name : _vm.defaultServiceProvider)+" ")]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('ui-link',{staticClass:"space",on:{"click":function($event){return _vm.$router.push({
          name: 'AppEnterpriseProject/ProjectDetails',
          params: {
            projectReference: row.projectReference,
            customerName: row.customer ? row.customer.name : null,
          },
        })}}},[_vm._v(" Detailansicht ")])]}}],null,false,1027546075)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }