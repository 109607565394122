
















































import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { Component, Mixins } from 'vue-property-decorator';

import heatingSystemsSpotsQuery from './heating-systems-spots.gql';
import {
  AppHeatingSystemAlertsViewHeatingSystemsSpotsQuery,
  AppHeatingSystemAlertsViewHeatingSystemsSpotsQueryVariables,
} from './__generated__/AppHeatingSystemAlertsViewHeatingSystemsSpotsQuery';
import {
  AppHeatingSystemAlertsViewAlertsQuery,
  AppHeatingSystemAlertsViewAlertsQueryVariables,
} from './__generated__/AppHeatingSystemAlertsViewAlertsQuery';
import { StringProp } from '@/util/prop-decorators';
import { ApolloQueryResult } from 'apollo-client';
import { uniq } from 'lodash';
import alertsQuery from './alerts.gql';
import { Column } from '@/features/ui/table/model';
import { BASE_COLUMNS } from '@/features/domain-ui/alert-table/model';

type AlertRow = AppHeatingSystemAlertsViewAlertsQuery['alerts']['items'][number] & {
  heatingSystem?: AppHeatingSystemAlertsViewHeatingSystemsSpotsQuery['treeNodes']['first']['heatingSystems']['items'][number];
};

@Component({
  apollo: {
    treeNodes: {
      query: heatingSystemsSpotsQuery,
      variables(this: AlertsView): AppHeatingSystemAlertsViewHeatingSystemsSpotsQueryVariables {
        return { treeNodeId: this.treeNodeId };
      },
      result(this: AlertsView, { data }: ApolloQueryResult<AppHeatingSystemAlertsViewHeatingSystemsSpotsQuery>): void {
        this.spotIds = uniq(
          data.treeNodes.first.heatingSystems.items
            .map(({ measurements }) => measurements)
            .flat()
            .map(({ spot }) => spot.id),
        );
      },
    },
    alerts: {
      query: alertsQuery,
      skip(this: AlertsView): boolean {
        return this.spotIds === undefined;
      },
      variables(this: AlertsView): AppHeatingSystemAlertsViewAlertsQueryVariables {
        return { spotIds: this.spotIds, skip: this.skip, take: this.take };
      },
    },
  },
  data() {
    return { treeNodes: undefined, alerts: undefined, spotIds: undefined, hiddenColumns: [] };
  },
})
export default class AlertsView extends Mixins(PaginationQueryStringMixin) {
  @StringProp(true)
  private readonly treeNodeId!: string;

  private readonly treeNodes?: AppHeatingSystemAlertsViewHeatingSystemsSpotsQuery['treeNodes'];

  private readonly alerts?: AppHeatingSystemAlertsViewAlertsQuery['alerts'];

  private spotIds?: string[];

  private hiddenColumns!: string[];

  protected get more(): boolean {
    return this.alerts?.more ?? false;
  }

  private get columns(): Column[] {
    return BASE_COLUMNS.concat(this.additionalColumns);
  }

  private get additionalColumns(): Column[] {
    return [
      { name: 'heatingSystemHeatingType', label: 'Heizart', hideable: true },
      { name: 'heatingSystemBoilerDevice', label: 'Kesselgerät', hideable: true },
      { name: 'heatingSystemBoilerModel', label: 'Kesselmodell', hideable: true },
      { name: 'heatingSystemProductSerial', label: 'Produkt-ID-Nummer', hideable: true },
      { name: 'heatingSystemManufacturer', label: 'Hersteller-Nummer', hideable: true },
      { name: 'heatingSystemBoilerManufacturer', label: 'Kesselhersteller', hideable: true },
    ];
  }

  private get alertRows(): AlertRow[] {
    return (
      this.alerts?.items.map((alert) => ({
        ...alert,
        heatingSystem: this.treeNodes?.first.heatingSystems.items.find(({ measurements }) =>
          measurements.find(({ spot }) => spot.id === alert.spot?.id),
        ),
      })) ?? []
    );
  }
}
