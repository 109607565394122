



















































import { defineComponent } from '@vue/composition-api';
import { mapState, mapActions } from 'vuex';
import Form from '@/features/ui/form/Form.global.vue';
import FormField from '@/features/ui/form/FormField.global.vue';
import WizardButton from '@/components/clickables/WizardButton.global.vue';
import { AddNewEnterpriseProject, AddNewEnterpriseProjectVariables } from './__generated__/AddNewEnterpriseProject';
import createProjectMutation from './add-project.gql';
import customerQuery from '../../../enterprise-project/customer-by-external-id.gql';
import {
  AppEnterpriseProjectCustomerByExternalIdQuery,
  AppEnterpriseProjectCustomerByExternalIdQueryVariables,
} from '../../../enterprise-project/__generated__/AppEnterpriseProjectCustomerByExternalIdQuery';
import { ApolloQueryResult } from 'apollo-client';

export default defineComponent<any, Record<string, unknown>, any, any>({
  components: { Form, FormField, WizardButton },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapState({
      generalInformationCompleted: (state: any) => state.EnterpriseProjectCreateModule.generalInformationCompleted,
      generalInformationFormDisabled: (state: any) =>
        state.EnterpriseProjectCreateModule.generalInformationCompleted &&
        state.EnterpriseProjectCreateModule.numberOfNonEditableBuildings > 0,
      customerName: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.general.customerName,
      customerNumber: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.general.customerNumber,
      partnerName: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.general.partnerName,
    }),
  },
  methods: {
    async updateProjectData(formData: any) {
      const result = await (
        this.$apollo.query({
          query: customerQuery,
          variables: {
            externalId: formData.customerNumber,
          } as AppEnterpriseProjectCustomerByExternalIdQueryVariables,
        }) as Promise<ApolloQueryResult<AppEnterpriseProjectCustomerByExternalIdQuery>>
      ).catch((error) => {
        // TODO: Add Proper error handling with a toast and sending to a bug tracker service
        // eslint-disable-next-line no-console
        console.error('An error occured while fetching customer info from the file import:', error);
      });

      const customerData = result?.data?.customers?.first;

      // Call mutation to persist project to database
      const { data } = await this.$apollo.mutate({
        mutation: createProjectMutation,
        variables: {
          input: {
            customerId: customerData?.id,
            objectList: [],
            salesContactUserName: formData.partnerName ?? '',
            serviceProviderId: null,
          },
        } as AddNewEnterpriseProjectVariables,
      });
      const { createProject } = data as AddNewEnterpriseProject;
      formData.id = createProject.project.id;
      formData.projectReference = createProject.project.projectReference;
      formData.general = {
        customerName: formData.customerName,
        customerNumber: formData.customerNumber,
        partnerName: formData.partnerName,
      };
      await this.$store.dispatch('saveNewProjectData', formData);
      await this.$store.dispatch('setGeneralInformationCompleted', true);
      await this.$store.dispatch('saveToDB', { apolloClient: this.$apollo, finalSave: false });
    },
    // Function to validate the form
    validateForm(formData: any) {
      return formData.customerName && formData.customerNumber && this.validateEmail(formData.partnerName);
    },
    validateEmail(email: string) {
      // Regular expression pattern for email validation
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // Test the email against the pattern
      return emailPattern.test(email);
    },
  },
});
