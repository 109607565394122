




import TreeNodeLocationGeneratorMixin from '@/features/core/components/mixins/tree-node-location-generator';
import { RootGetter, RootState } from '@/features/core/store';
import { StringProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import DirectorySpotsPanel from '@/features/app-heating-system/views/tree-node/directory-spots/components/DirectorySpotsPanel.vue';

@Component({
  components: { DirectorySpotsPanel },
  data() {
    return {};
  },
})
export default class HeatingSystemSpotsView extends Mixins(TreeNodeLocationGeneratorMixin) {
  @StringProp(true)
  private readonly treeNodeId!: string;

  @RootGetter
  private readonly userId?: string;

  @RootGetter
  private readonly activeTreeNodeId?: string;

  @RootState
  private readonly appCustomerLastCustomerId?: string;
}
