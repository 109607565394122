













import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { StringProp } from '@/util/prop-decorators';
import { ApolloQueryResult } from 'apollo-client';
import { Component, Mixins } from 'vue-property-decorator';
import query from './view.gql';
import {
  AppHeatingSystemHeatingSystemGatewaysViewGatewaysQuery,
  AppHeatingSystemHeatingSystemGatewaysViewGatewaysQueryVariables,
} from './__generated__/AppHeatingSystemHeatingSystemGatewaysViewGatewaysQuery';
import {
  AppHeatingSystemHeatingSystemGatewaysViewQuery,
  AppHeatingSystemHeatingSystemGatewaysViewQueryVariables,
} from './__generated__/AppHeatingSystemHeatingSystemGatewaysViewQuery';
import gatewaysQuery from './gateways.gql';
import healthsQuery from './gateway-healths.gql';
import {
  AppHeatingSystemHeatingSystemGatewaysViewGatewayHealthsQuery,
  AppHeatingSystemHeatingSystemGatewaysViewGatewayHealthsQueryVariables,
} from './__generated__/AppHeatingSystemHeatingSystemGatewaysViewGatewayHealthsQuery';
import { GatewayHealthQueryStringMixin } from '@/features/core/components/mixins/gateway-health-query-string';
import { uniq } from 'lodash';

@Component({
  apollo: {
    heatingSystems: {
      query,
      variables(this: GatewaysView): AppHeatingSystemHeatingSystemGatewaysViewQueryVariables {
        return { heatingSystemId: this.heatingSystemId };
      },
      result(this: GatewaysView, { data }: ApolloQueryResult<AppHeatingSystemHeatingSystemGatewaysViewQuery>): void {
        this.treeNodeIds = uniq(
          data.heatingSystems.first.consumers
            .filter(({ node }) => node)
            .map(({ node }) => (node as NonNullable<typeof node>).id)
            .concat(data.heatingSystems.first.site.id),
        );
      },
    },
    gateways: {
      query: gatewaysQuery,
      skip(this: GatewaysView): boolean {
        return this.treeNodeIds === undefined;
      },
      variables(this: GatewaysView): AppHeatingSystemHeatingSystemGatewaysViewGatewaysQueryVariables {
        return {
          treeNodeIds: this.treeNodeIds,
          take: this.take,
          skip: this.skip,
          healths: this.health === undefined ? undefined : [this.health],
        };
      },
      pollInterval: 60000,
    },
    healths: {
      query: healthsQuery,
      pollInterval: 60000,
      skip(this: GatewaysView): boolean {
        return this.treeNodeIds === undefined;
      },
      variables(this: GatewaysView): AppHeatingSystemHeatingSystemGatewaysViewGatewayHealthsQueryVariables {
        return {
          treeNodeIds: this.treeNodeIds,
        };
      },
    },
  },
  data() {
    return { treeNodeIds: undefined, healths: undefined, gateways: undefined };
  },
})
export default class GatewaysView extends Mixins(GatewayHealthQueryStringMixin, PaginationQueryStringMixin) {
  @StringProp(true)
  private readonly heatingSystemId!: string;

  private treeNodeIds?: string[];

  private readonly gateways?: AppHeatingSystemHeatingSystemGatewaysViewGatewaysQuery['gateways'];
  private readonly healths?: AppHeatingSystemHeatingSystemGatewaysViewGatewayHealthsQuery['healths'];

  protected get count(): number {
    return this.gateways?.count ?? 0;
  }
}
