var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.heatingSystem.measurements.length > 0)?_c('ui-table',{attrs:{"columns":_vm.columns,"rows":_vm.heatingSystem.measurements},scopedSlots:_vm._u([{key:"consumerPath",fn:function(ref){
var row = ref.row;
return [(row.consumer.node)?_c('domain-ui-tree-node-path',{attrs:{"tree-nodes":row.consumer.node.path.items,"context-tree-node":row.consumer.node},on:{"tree-node-click":_vm.onConsumerPathClick}}):_c('span',[_vm._v("–")])]}},{key:"consumer",fn:function(ref){
var row = ref.row;
return [(row.consumer.node)?_c('ui-clickable',{on:{"click":function($event){return _vm.onConsumerClick(row)}}},[_vm._v(_vm._s(row.consumer.name))]):_c('span',[_vm._v(_vm._s(row.consumer.name))])]}},{key:"spot.label",fn:function(){return [_vm._v("Gerätename"),_c('br'),_vm._v("Gerätenummer")]},proxy:true},{key:"spot",fn:function(ref){
var row = ref.row;
return [_c('domain-ui-spot-info',{attrs:{"spot":row.spot},on:{"click":function($event){return _vm.onSpotClick(row)}}})]}},{key:"name.label",fn:function(){return [_vm._v("Name"),_c('br'),_vm._v("Metrik")]},proxy:true},{key:"name",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.name)),_c('br'),_c('span',{staticClass:"text-light"},[_vm._v(_vm._s(row.metricName))])]}},{key:"latestMetricPoint.label",fn:function(){return [_vm._v("Wert"),_c('br'),_vm._v("Datum")]},proxy:true},{key:"latestMetricPoint",fn:function(ref){
var row = ref.row;
return [(row.latestMetricPoint)?[_vm._v(_vm._s(row.latestMetricPoint.value)),_c('br'),_c('span',{staticClass:"text-light"},[_c('ui-relative-time',{attrs:{"date":row.latestMetricPoint.time,"min":"-PT30M","format":"L LT"}})],1)]:[_vm._v("–")]]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('edit-heating-system-measurement-wizard-control',{attrs:{"heating-system-id":_vm.heatingSystem.id,"measurement-id":row.id}}),_c('ui-link',{on:{"click":function($event){return _vm.$emit('remove', row)}}},[_vm._v("Entfernen")])]}}],null,false,2128686717)}):_c('ui-form-message',[_vm._v("Keine Messstellen konfiguriert.")])}
var staticRenderFns = []

export { render, staticRenderFns }