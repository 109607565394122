<template>
  <div v-tooltip="tooltipContent">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    tooltipContent: {
      type: String,
      required: true,
    },
  },
};
</script>
