






import { Component, Vue } from 'vue-property-decorator';
import { EnumProp, NumberProp, StringProp } from '@/util/prop-decorators';

@Component
export default class MiniSensor extends Vue {
  @EnumProp('NONE', 'RED', 'GREEN')
  public border!: 'NONE' | 'RED' | 'GREEN';

  @StringProp()
  public icon!: string;

  @NumberProp()
  public counter!: number;

  private get cssClass(): string {
    let cssClass = this.border;

    if (this.counter > 0) {
      cssClass += ' badge';
    }

    return cssClass;
  }
}
