









import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import query from './view.gql';
import {
  AppHeatingSystemHeatingSystemConsumersViewQuery,
  AppHeatingSystemHeatingSystemConsumersViewQueryVariables,
} from './__generated__/AppHeatingSystemHeatingSystemConsumersViewQuery';
import HeatingSystemConsumersTable from './HeatingSystemConsumersTable.vue';
import AddHeatingSystemConsumerWizardControl from './add-heating-system-consumer-wizard-control/AddHeatingSystemConsumerWizardControl.vue';
import removeHeatingSystemConsumer from './remove-heating-system-consumer.gql';
import {
  AppHeatingSystemHeatingSystemConsumersRemoveHeatingSystemConsumerMutation,
  AppHeatingSystemHeatingSystemConsumersRemoveHeatingSystemConsumerMutationVariables,
} from './__generated__/AppHeatingSystemHeatingSystemConsumersRemoveHeatingSystemConsumerMutation';

type Consumer = NonNullable<
  AppHeatingSystemHeatingSystemConsumersViewQuery['heatingSystems']['first']['consumers'][number]['node']
>;

@Component({
  apollo: {
    heatingSystems: {
      query,
      variables(this: ConsumersView): AppHeatingSystemHeatingSystemConsumersViewQueryVariables {
        return { heatingSystemId: this.heatingSystemId };
      },
    },
  },
  components: { HeatingSystemConsumersTable, AddHeatingSystemConsumerWizardControl },
})
export default class ConsumersView extends Vue {
  @StringProp(true)
  private readonly heatingSystemId!: string;

  private readonly heatingSystems?: AppHeatingSystemHeatingSystemConsumersViewQuery['heatingSystems'];

  private get customerId(): string | undefined {
    return this.heatingSystems?.first.customer.id;
  }

  private async removeConsumer(consumer: Consumer): Promise<void> {
    if (
      !window.confirm(
        `Sind Sie sich sicher, dass Sie den Versorgungsbereich "${consumer.name}" und alle dazugehörigen Messstellen entfernen möchten?`,
      )
    ) {
      return;
    }

    const { data } = await this.$apollo.mutate<
      AppHeatingSystemHeatingSystemConsumersRemoveHeatingSystemConsumerMutation,
      AppHeatingSystemHeatingSystemConsumersRemoveHeatingSystemConsumerMutationVariables
    >({
      mutation: removeHeatingSystemConsumer,
      variables: { input: { heatingSystemId: this.heatingSystemId, consumerId: consumer.id } },
    });

    if (!data) {
      throw new Error('Der Versorgungsbereich konnte nicht entfernt werden!');
    }
  }
}
