




























import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import query from './view.gql';
import {
  AppHeatingSystemHeatingSystemViewQuery,
  AppHeatingSystemHeatingSystemViewQueryVariables,
} from './__generated__/AppHeatingSystemHeatingSystemViewQuery';

@Component({
  apollo: {
    heatingSystems: {
      query,
      variables(this: HeatingSystemView): AppHeatingSystemHeatingSystemViewQueryVariables {
        return { heatingSystemId: this.heatingSystemId };
      },
    },
  },
})
export default class HeatingSystemView extends Vue {
  @StringProp(true)
  private readonly heatingSystemId!: string;

  private readonly heatingSystems?: AppHeatingSystemHeatingSystemViewQuery['heatingSystems'];
}
