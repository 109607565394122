























import { defineComponent } from '@vue/composition-api';
import { mapGetters } from 'vuex';
import {
  HeatingSystem,
  HeatingSystemComponent,
  HeatingSystemMetadata,
} from '@/features/core/store/page-modules/heating-systems-metadata/heating-systems-metadata.module';
import { getHeatingSystemsComponents } from '@/util/heating-systems';

export default defineComponent({
  data() {
    return {
      searchObject: '',
      heatingSystems: [] as HeatingSystem[],
      toastMessage: '',
    };
  },
  computed: {
    ...mapGetters(['openModal']),
    heatingSystemObjects(): HeatingSystem[] {
      return this.$store.getters.heatingSystems;
    },
    formData(): HeatingSystemMetadata {
      return this.$store.getters.formData;
    },
    filteredHeatingSystems(): HeatingSystem[] {
      // Filter heating systems using following criteria:
      // 1. Exclude selected/active heating system.
      // 2. Heating System Component does not have metadata.
      // 3. Heating System Component is the same type as selected/active component (i.e Kessel).
      return this.heatingSystemObjects
        .filter(
          (item) =>
            item.components.id !== this.activeHeatingSystem?.components.id &&
            this.hasSameComponentAsActive(item) &&
            (!item.metadata || item.metadata === null),
        )
        .map((item) => {
          return { ...item };
        });
    },
    heatingSystemsComponents(): HeatingSystemComponent[] {
      return this.$store.getters.heatingSystemsComponents;
    },
    activeHeatingSystem(): HeatingSystem | undefined {
      return this.$store.getters.activeHeatingSystem;
    },
    activeHeatingSystemComponent(): HeatingSystemComponent | undefined {
      return this.$store.getters.activeHeatingSystemComponent;
    },
  },
  watch: {
    searchObject(newValue) {
      if (newValue) {
        this.heatingSystems = this.heatingSystems.filter((item) => {
          return item.street?.includes(newValue);
        });
      } else {
        this.heatingSystems = this.filteredHeatingSystems;
      }
    },
  },
  mounted() {
    this.heatingSystems = this.filteredHeatingSystems;
  },
  methods: {
    handleSaveSettings() {
      // get selected heating systems
      const heatingSystems = this.filteredHeatingSystems.filter((item) => item.active === true);
      // apply metadata to each
      heatingSystems.forEach((heatingSystem) => {
        this.$store.commit('setHeatingSystemMetadata', {
          id: heatingSystem.components.id,
          metadata: this.formData,
        });
      });
      // save data
      try {
        this.$store.dispatch('addHeatingSystemMetadata', {
          heatingSystemId: this.activeHeatingSystem?.components.id,
          componentType: this.activeHeatingSystemComponent?.type,
          metadata: this.formData,
        });
        this.toastMessage = 'Metadata saved successfully';
      } catch (error) {
        this.toastMessage = 'There was a problem saving metadata';
      }
    },
    hasSameComponentAsActive(heatingSystem: HeatingSystem): boolean {
      const activeComponent = this.heatingSystemsComponents.find((item) => item.active === true);
      if (!activeComponent?.label) {
        return false;
      }
      const components = Array.from(getHeatingSystemsComponents(heatingSystem));
      return components.some((item) => item.includes(activeComponent.label));
    },
    handleSelectAll() {
      this.heatingSystems.forEach((item) => {
        item.active = true;
      });
    },
    handleClearSelection() {
      this.heatingSystems.forEach((item) => {
        item.active = false;
      });
    },
  },
});
