




import { Component, Vue } from 'vue-property-decorator';
import { BooleanProp } from '@/util/prop-decorators';

@Component
export default class Spinner extends Vue {
  @BooleanProp()
  private readonly dark?: boolean;
}
