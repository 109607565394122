

























import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'ConfirmationModal',
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    mode: {
      default: 'confirmation',
      type: String,
      required: false,
    },
    cancelButtonText: {
      default: 'Abbrechen',
      type: String,
      required: false,
    },
    confirmButtonText: {
      default: 'Bestätigen Sie',
      type: String,
      required: false,
    },
    understandButtonText: {
      default: 'Ich verstehe',
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    const visible = ref(false);
    const modal = ref(null);

    const show = (): void => {
      visible.value = true;
      emit('show');
    };

    const hide = (): void => {
      visible.value = false;
      emit('hide');
    };

    const confirm = (): void => {
      visible.value = false;
      emit('confirm');
    };

    return {
      visible,
      modal,
      show,
      hide,
      confirm,
    };
  },
});
