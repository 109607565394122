import { Column } from '@/features/ui/table/model';

export const BASE_COLUMNS: readonly Column[] = Object.freeze([
  { name: 'status', label: 'Status' },
  { name: 'statusText', label: 'Statustext' },
  { name: 'health', label: 'Erreichbarkeit' },
  { name: 'alerts', label: 'Alarme', hideable: true },
  { name: 'name', label: 'Name' },
  { name: 'site.name', label: 'Standort' },
  { name: 'heatingType', label: 'Heizart' },
  { name: 'boilerDevice', label: 'Kesselgerät' },
  { name: 'boilerModel', label: 'Kesselmodell' },
  { name: 'productSerial', label: 'Produkt-ID-Nummer' },

  { name: 'manufacturer', label: 'Hersteller-Nummer', hideable: true },
  { name: 'boilerManufacturer', label: 'Kesselhersteller', hideable: true },
  { name: 'energyOutputRange', label: 'Wärmebelastungsgrad', hideable: true },
  { name: 'waterStorageType', label: 'Wasserspeicher-Typ', hideable: true },
  { name: 'waterStorageModel', label: 'Wasserspeicher-Modell', hideable: true },
  { name: 'waterStorageManufacturer', label: 'Wasserspeicher-Hersteller', hideable: true },
  { name: 'waterStorageCapacity', label: 'Wasserspeicher-Kapazität', hideable: true },
  { name: 'maintenanceCompany', label: 'Wartungsfirma', hideable: true },
  { name: 'numApartmentUnits', label: 'Anzahl versorgte Wohnungen', hideable: true },
  { name: 'numCommercialUnits', label: 'Anzahl vorsorgte Gewerbeeinheiten', hideable: true },
  { name: 'suppliedQuantity', label: 'Versorgte Fläche', hideable: true },
  { name: 'lastHydraulicBalancing', label: 'letzter hydraulischer Abgleich', hideable: true },
  { name: 'consumptions', label: 'Energieverbräuche', hideable: true },
  { name: 'action', label: '', align: 'right' },
  { name: 'expand', label: '' },
]);
