



















import { defineComponent } from '@vue/composition-api';
import { mapState, mapActions } from 'vuex';
import InputCheckbox from '@/features/ui/inputs/InputCheckbox.global.vue';
import { ProjectBuilding } from '@/features/core/store/page-modules/enterprise-project-create/enterpriseProjectCreateModule';
import FormMessage from '@/features/ui/form/FormMessage.global.vue';

interface ProjectBuildingImporterData {
  importedBuildings: ProjectBuilding[];
}

export default defineComponent<any, Record<string, unknown>, ProjectBuildingImporterData, any, any>({
  components: { InputCheckbox, FormMessage },
  props: {},
  data() {
    return {
      importedBuildings: [] as ProjectBuilding[],
      showCheckList: true,
    };
  },
  computed: {
    ...mapState({
      buildingsImportCompleted: (state: any) => state.EnterpriseProjectCreateModule.buildingsImportCompleted,
      buildings: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.buildings,
      selectedBuildingsForContactInformation: (state: any) =>
        state.EnterpriseProjectCreateModule.selectedBuildingsForContactInformation,
    }),
  },
  watch: {
    buildings: {
      handler() {
        this.toggleCheckList();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(['ADD_TOAST_MESSAGES']),
    onBuildingClick(event: MouseEvent, buildingId: number) {
      // Update the selectedBuildingsForContactInformation in the state
      this.$store.commit('setBuildingSelectionForContactInformation', {
        buildingId,
        selected: (event.target as HTMLInputElement).checked,
      });
    },
    // We only want to show buildings that are properties and have not yet been completed
    filteredBuildings(): ProjectBuilding[] {
      return this.buildings.filter((building: ProjectBuilding) => {
        return building.liegenshaft;
      });
    },
    toggleCheckList() {
      this.showCheckList = false;
      this.showCheckList = true;
    },
  },
});
