import {
  ColumnMapping,
  requiredFields,
  TemplateHeadingRow,
} from '@/features/app-enterprise-project/views/enterprise-project-create/components/step1/constants';
import {
  EnterpriseProjectObjectProduct,
  EnterpriseProjectObjectUseCase,
  IWCloudType,
  ProjectBuilding,
} from '@/features/core/store/page-modules/enterprise-project-create/enterpriseProjectCreateModule';
import { sortProjectDraftBuildings } from '@/features/core/store/page-modules/enterprise-project-create/create-module-helpers';

export interface ProjectBuildingsUpload {
  file: File;
  label: string;
}

type ImportRowErrors = {
  row: number;
  error: string;
};

export type CovertSheetToDataResult = {
  data: { [key: string]: any }[];
  errors: ImportRowErrors[];
};

interface ImportedRow {
  buildingId: number;
  objectId?: string;
  street?: string;
  houseNumber?: number;
  addressSupplement?: string;
  postalCode?: string;
  city?: string;
  area?: string;
  objectType?: string;
  supplyType?: string;
  frequency?: string;
  orderStartDate?: string;
  vpkn?: string;
  invIdentifier?: string;
  contractStatus?: string;
  revenueYear?: string;
  weEnv?: string;
  tlaName?: string;
  geoLatitude?: string;
  geoLongitude?: string;
  id?: string;
  numberOfApartments?: string;
}

function convertToNumber(coordinate: string): number {
  // Replace comma with dot and convert to number
  return parseFloat(coordinate.replace(',', '.'));
}

function checkRowRequiredInformationForErrors(row: number, entry: { [key: string]: any }): ImportRowErrors[] {
  const errors: ImportRowErrors[] = [];
  // Check if row has all the required fields
  for (const field of requiredFields) {
    if (!entry[field]) {
      // First get the corresponding key from the ColumnMapping
      const key = Object.keys(ColumnMapping).find((k) => ColumnMapping[k] === field);
      if (key) {
        errors.push({
          row,
          error: `Der Wert für die Spalte ${key} ist nicht gültig`,
        });
      }
    }
  }
  return errors;
}

function addProductsAndUseCases(building: ProjectBuilding): void {
  building.products = [
    {
      monthlyPriceInEuros: null,
      oneTimePriceInEuros: null,
      productType: EnterpriseProjectObjectProduct.INFRASTRUCTURE_VARIANT,
      option: null,
    },
    {
      monthlyPriceInEuros: null,
      oneTimePriceInEuros: null,
      productType: EnterpriseProjectObjectProduct.IW_CLOUD,
      option: IWCloudType.STANDARD,
    },
  ];
  building.useCases = [
    {
      label: 'Heizung',
      monthlyPriceInEuros: null,
      oneTimePriceInEuros: null,
      useCaseType: null,
    },
    {
      label: 'Aufzug',
      monthlyPriceInEuros: null,
      oneTimePriceInEuros: null,
      useCaseType: null,
    },
    {
      label: 'Digitalisierung TGA',
      checked: false,
      oneTimePriceInEuros: null,
      useCaseType: EnterpriseProjectObjectUseCase.TGA_DIGITIZATION,
    },
    {
      label: 'TGA Monitoring',
      checked: false,
      monthlyPriceInEuros: null,
      oneTimePriceInEuros: null,
      useCaseType: EnterpriseProjectObjectUseCase.TGA_MONITORING,
    },
    {
      label: 'TGA Integration',
      checked: false,
      monthlyPriceInEuros: null,
      oneTimePriceInEuros: null,
      useCaseType: EnterpriseProjectObjectUseCase.TGA_INTEGRATION,
    },
    {
      label: 'Zählerfernauslesung (ZFA)',
      checked: false,
      monthlyPriceInEuros: null,
      oneTimePriceInEuros: null,
      useCaseType: EnterpriseProjectObjectUseCase.REMOTE_METER_READING,
    },
    {
      label: 'Wettbewerbliche Messstellenbetrieb (SMG)',
      checked: false,
      monthlyPriceInEuros: null,
      oneTimePriceInEuros: null,
      useCaseType: EnterpriseProjectObjectUseCase.SMG,
    },
    {
      label: 'Dienstleistungsmanagement',
      checked: false,
      monthlyPriceInEuros: null,
      oneTimePriceInEuros: null,
      useCaseType: EnterpriseProjectObjectUseCase.SERVICE_MANAGEMENT,
    },
    {
      label: 'Verbrauchsdatenerfassung (VDE)',
      checked: false,
      monthlyPriceInEuros: null,
      oneTimePriceInEuros: null,
      useCaseType: EnterpriseProjectObjectUseCase.CONSUMPTION_DATA_ACQUISITION,
    },
    {
      label: 'Unterjährige Verbrauchsinformation (UVI)',
      checked: false,
      monthlyPriceInEuros: null,
      oneTimePriceInEuros: null,
      useCaseType: EnterpriseProjectObjectUseCase.CONSUMPTION_INFORMATION_DURING_THE_YEAR,
    },
    {
      label: 'Rauchwarnmeldermonitoring',
      checked: false,
      monthlyPriceInEuros: null,
      oneTimePriceInEuros: null,
      useCaseType: EnterpriseProjectObjectUseCase.SMOKE_ALARM_MONITORING,
    },
  ];
}

export async function covertSheetToData(data: any[]): Promise<CovertSheetToDataResult> {
  let errors: ImportRowErrors[] = [];

  if (!data.length) {
    return {
      data: [],
      errors: [{ row: 0, error: 'Keine Daten gefunden' }],
    };
  }

  const importedData = [];
  // Map the data to the desired format
  for (let i = 0; i < data.length; i++) {
    const rowNumber = i + 4;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (data[i].length > 0) {
      const mappedRow: ImportedRow = { buildingId: i + 1 };
      // The ColumnMapping contains the columns that we extract
      for (const key in ColumnMapping) {
        // Get the index from the key from the templateHeadingRow array
        const columnIndex = TemplateHeadingRow.indexOf(key);
        // If there is an unknown column then stop the process
        if (columnIndex === -1 && requiredFields.includes(ColumnMapping[key])) {
          errors.push({
            row: rowNumber,
            error: `Der Wert für die Spalte ${key} ist nicht gültig`,
          });
          continue;
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (data[i]?.[columnIndex] !== undefined) {
          /* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          mappedRow[ColumnMapping[key]] = data[i][columnIndex];
          /* eslint-enable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
        }
      }
      // Check if the row has the required information
      const rowErrors = checkRowRequiredInformationForErrors(rowNumber, mappedRow);
      errors = errors.concat(rowErrors);
      if (rowErrors.length === 0) {
        importedData.push(mappedRow);
      }
    }
  }

  let buildings: ProjectBuilding[] = importedData.map((building: ImportedRow) => {
    const convertedBuilding: ProjectBuilding = {
      id: Number(building.objectId),
      name: `${building.street ? building.street : ''} ${building.houseNumber ? building.houseNumber : ''} ${
        building.addressSupplement ? building.addressSupplement : ''
      }`,
      cityAddress: building.city ? building.city : '',
      houseAddress: Number(building.houseNumber),
      houseAddressSuffix: building.addressSupplement ? building.addressSupplement.toString() : '',
      streetAddress: building.street ? building.street : '',
      postCodeAddress: building.postalCode ? building.postalCode : '',
      locationLongitude: convertToNumber(building.geoLongitude ? building.geoLongitude : '0'),
      locationLatitude: convertToNumber(building.geoLatitude ? building.geoLatitude : '0'),
      numberOfApartments: Number(building.numberOfApartments),
      liegenshaft: false,
      liegenshaftInformation: {
        firstName: '',
        surname: '',
        title: '',
        phoneNumber: '',
        email: '',
      },
      mainService: null,
      buildingId: Number(building.buildingId),
    };
    addProductsAndUseCases(convertedBuilding);
    return convertedBuilding;
  });
  // Sort buildings by street, house number, and addressSupplement
  buildings = sortProjectDraftBuildings(buildings);
  return { data: buildings, errors };
}
