









































































import { defineComponent, ref } from '@vue/composition-api';
import { EnterpriseProjectBuildingStatus, EnterpriseProjectStatus, TreeNodeClassification } from '@/types/iot-portal';
import { EnterpriseProjectServiceType } from '@/features/core/store/page-modules/enterprise-project-create/enterpriseProjectCreateModule';
import { EnterpriseProjectUpdateObjectStatusMutationVariables } from '@/features/app-enterprise-project/views/enterprise-project-details/__generated__/EnterpriseProjectUpdateObjectStatusMutation';
import updateObjectStatusMutation from '@/features/app-enterprise-project/views/enterprise-project-details/update-object-status.gql';
import { Option } from '@/features/ui/inputs/model';
import { LiegenshaftStatusMappings } from '@/features/app-enterprise-project/views/enterprise-project-details/project-details-constants';

export default defineComponent<any, Record<string, unknown>, any, any>({
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    projectObjectList: {
      type: Array,
      required: true,
    },
    projectDetails: {
      type: Object,
      required: true,
    },
  },
  setup() {
    // This is for the modal
    const propertyEditModal = ref(null);

    const openPropertyEditModal = (): void => {
      if (!propertyEditModal.value) {
        return;
      }
      (propertyEditModal.value as any).show();
    };

    return {
      propertyEditModal,
      openPropertyEditModal,
    };
  },
  data() {
    return {
      columns: [
        { name: 'buildingReferenceId', label: 'Liegenschafts ID' },
        { name: 'pdtpCCRNumber', label: 'PDTP/CCR-Nummer' },
        { name: 'address', label: 'Anschrift' },
        { name: 'buildingType', label: 'Art' },
        { name: 'numberOfApartments', label: 'WIE' },
        { name: 'gatewayIdentifier', label: 'GWID' },
        { name: 'mainService', label: 'Main Service' },
        { name: 'productType', label: 'Produkt' },
        { name: 'useCase', label: 'Anwendungsfälle' },
        { name: 'status', label: 'Status' },
        { name: 'lastUpdate', label: 'Letzte Änderung ' },
      ],
      statusMappings: LiegenshaftStatusMappings,
    };
  },
  computed: {
    EnterpriseProjectBuildingStatus() {
      return EnterpriseProjectBuildingStatus;
    },
    statusOptions(): Option[] {
      return Array.from(this.statusMappings.values());
    },
  },
  methods: {
    transformToDateOnly(date: string): string {
      if (date) {
        return new Date(date).toLocaleDateString('de-DE');
      }

      return '';
    },
    transformToTimeOnly(date: string): string {
      if (date) {
        return new Date(date).toLocaleTimeString('de-DE');
      }

      return '';
    },
    shouldShowStatusDropdown(buildingType: TreeNodeClassification): boolean {
      return buildingType === TreeNodeClassification.Liegenschaft;
    },
    isLGateway(identifier: string): boolean {
      const parts = identifier.split('_');
      return parts.length > 1 && parts[1].startsWith('L');
    },
    getGatewayIdentifier(gatewayIdentifier: any): string {
      return gatewayIdentifier?.identifier ? gatewayIdentifier.identifier : 'No Gateway Found';
    },
    goToGateway(gatewayIdentifier: any): void {
      if (gatewayIdentifier?.id) {
        this.$router.push({
          name: 'AppManager/TreeNode/Gateways/NewDetails',
          params: { treeNodeId: gatewayIdentifier.id, gatewayId: gatewayIdentifier.id },
        });
      }
    },
    getBuildingLetter(buildingType: string) {
      if (buildingType === TreeNodeClassification.Liegenschaft) {
        return 'L';
      }
      if (buildingType === TreeNodeClassification.Gebaude) {
        return 'G';
      }
    },
    getMainServiceText(serviceType: EnterpriseProjectServiceType): string {
      // NB: GK_HUB_G should be excluded on FE
      if (serviceType === EnterpriseProjectServiceType.GK_HUB_L_GDSP_MOBILE_WIRELESS) {
        return 'GK_HUB_L_GDSP_MOBILFUNK';
      }
      if (serviceType === EnterpriseProjectServiceType.GK_HUB_L_CABLE) {
        return 'GK_HUB_L_Kabel';
      }
      return '';
    },
    async onObjectStatusUpdate(value: string, id: string): Promise<void> {
      const status = value as EnterpriseProjectBuildingStatus;

      void (await this.updateObjectStatus({ input: { id, status } }));

      this.reloadData();
    },
    async updateObjectStatus(input: EnterpriseProjectUpdateObjectStatusMutationVariables): Promise<string> {
      const { data } = await this.$apollo.mutate({
        mutation: updateObjectStatusMutation,
        variables: { input: input.input },
      });

      if (!data) {
        throw new Error('Status kann nicht aktualisiert werden!');
      }

      return data.customer;
    },
    shouldDisplayProjectEdit(): boolean {
      return this.project?.editOptions?.editable && this.project?.status !== EnterpriseProjectStatus.CANCELLED;
    },
    reloadData() {
      // Emit refresh function
      this.$emit('refresh');
    },
  },
});
