










































































import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { Column } from '@/features/ui/table/model';
import { StringProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import heatingSystemsQuery from '@/hsc-api/queries/HeatingSystemsWithPaginationQuery.gql';
import {
  HeatingSystemsWithPaginationQuery,
  HeatingSystemsWithPaginationQueryVariables,
} from '@/hsc-api/queries/__generated__/HeatingSystemsWithPaginationQuery';
import { APOLLO_CLIENT } from '@/features/core/container/model';
import Search from '@/features/core/components/tree-node-bar-control/Search.vue';
import serviceProvidersQuery from './service-providers.gql';
import technicalContactsQuery from './techincal-contacts.gql';
import { TechnicalContacts, TechnicalContactsVariables } from './__generated__/TechnicalContacts';
import { ServiceProvidersQuery, ServiceProvidersQueryVariables } from './__generated__/ServiceProvidersQuery';
import alertServiceProviderRulesQuery from '@/hsc-api/queries/AlertServiceProviderRulesQuery.gql';
import {
  AlertServiceProviderRulesQuery,
  AlertServiceProviderRulesQuery_alertServiceProviderRulesByHeatingSystemIds,
  AlertServiceProviderRulesQueryVariables,
} from '@/hsc-api/queries/__generated__/AlertServiceProviderRulesQuery';
import {
  UpdateAlertServiceProviderRulesMutation,
  UpdateAlertServiceProviderRulesMutationVariables,
} from '@/hsc-api/mutations/__generated__/UpdateAlertServiceProviderRulesMutation';
import updateAlertServiceProviderRulesMutation from '@/hsc-api/mutations/UpdateAlertServiceProviderRulesMutation.gql';
import { UpdateAlertServiceProviderRulesInput } from '@/types/hsc-types';
import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import userQuery from './userQuery.gql';
import { HeatingSystemSettingsUserPermissionQuery } from './__generated__/HeatingSystemSettingsUserPermissionQuery';

type ServiceProvider = ServiceProvidersQuery['serviceProviders']['items'][number];
type HeatingSystem = HeatingSystemsWithPaginationQuery['heatingSystemCollection']['items'][number];
type AlertServiceProviderRule =
  AlertServiceProviderRulesQuery_alertServiceProviderRulesByHeatingSystemIds['items'][number];
type RuleValueProperty = keyof Pick<
  AlertServiceProviderRule,
  'technicianEmail' | 'contactPerson' | 'emergencyProviderEmail' | 'messageShouldBeSent'
>;
type TechnicalContact = TechnicalContacts['technicalContacts']['items'][number];

@Component({
  components: { Search },
  apollo: {
    alertServiceProviderRulesByHeatingSystemIds: {
      query: alertServiceProviderRulesQuery,
      fetchPolicy: 'no-cache',
      client: APOLLO_CLIENT.HEATING_SYSTEM_COLLECTOR_CLIENT,
      variables(this: AlertServiceProviderContacts): AlertServiceProviderRulesQueryVariables {
        return {
          heatingSystemIds: this.heatingSystemCollection?.items.map((item) => item.id) || [],
        };
      },
      skip(this: AlertServiceProviderContacts): boolean {
        return !this.heatingSystemCollection || this.heatingSystemCollection.items.length === 0;
      },
    },
    serviceProviders: {
      query: serviceProvidersQuery,
      fetchPolicy: 'no-cache',
      variables(this: AlertServiceProviderContacts): ServiceProvidersQueryVariables {
        return {
          customerId: this.customerId,
        };
      },
    },
    heatingSystemCollection: {
      client: APOLLO_CLIENT.HEATING_SYSTEM_COLLECTOR_CLIENT,
      query: heatingSystemsQuery,
      fetchPolicy: 'no-cache',
      variables(this: AlertServiceProviderContacts): HeatingSystemsWithPaginationQueryVariables {
        return { take: this.take, skip: this.skip, searchQuery: this.searchQuery, customerId: this.treeNodeId };
      },
    },
    technicalContacts: {
      query: technicalContactsQuery,
      fetchPolicy: 'no-cache',
      variables(this: AlertServiceProviderContacts): TechnicalContactsVariables {
        return { resourceId: this.customerId };
      },
    },
    me: {
      query: userQuery,
      fetchPolicy: 'no-cache',
    },
  },
  data() {
    return {
      heatingSystemCollection: undefined,
      serviceProviders: undefined,
      alertServiceProviderRulesByHeatingSystemIds: undefined,
      technicalContacts: undefined,
    };
  },
})
export default class AlertServiceProviderContacts extends Mixins(PaginationQueryStringMixin) {
  @StringProp(true)
  private readonly treeNodeId!: string;

  @StringProp(true)
  private readonly customerId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly heatingSystemCollection?: HeatingSystemsWithPaginationQuery['heatingSystemCollection'];
  private readonly serviceProviders?: ServiceProvidersQuery['serviceProviders'];
  private readonly alertServiceProviderRulesByHeatingSystemIds?: AlertServiceProviderRulesQuery['alertServiceProviderRulesByHeatingSystemIds'];
  private readonly technicalContacts?: TechnicalContacts['technicalContacts'];
  private readonly me?: HeatingSystemSettingsUserPermissionQuery['me'];

  private searchQuery = '';

  private readonly columns: Column[] = [
    {
      name: 'economicUnit',
      accessor: (heatingSystem: HeatingSystem) => `${heatingSystem.economicUnit}`,
      label: 'Anlage',
    },
    { name: 'cleaning', label: 'Wartungsfirma', width: 200 },
    { name: 'emergency', label: 'Notdienst', width: 200 },
    { name: 'technicianName', label: 'Technischer Ansprechpartner (intern)' },
    { name: 'action', label: '' },
  ];

  private serviceProviderId(heatingSystemId: HeatingSystem['id']): string | null {
    const alreadyExistingEntityId =
      this.alertServiceProviderRulesByHeatingSystemIds?.items.find((rule) => rule.heatingSystem.id === heatingSystemId)
        ?.id ?? null;
    return alreadyExistingEntityId;
  }

  private getValue(
    heatingSystemId: HeatingSystem['id'],
    property: RuleValueProperty,
  ): ServiceProvider | boolean | undefined {
    const rule = this.alertServiceProviderRulesByHeatingSystemIds?.items.find(
      (rule) => rule.heatingSystem.id === heatingSystemId,
    );

    if (property === 'messageShouldBeSent') return rule?.messageShouldBeSent || false;

    const serviceProvider = this.serviceProviders?.items.find(
      (serviceProvider) => serviceProvider.email === rule?.[property] && rule?.[property],
    );
    return serviceProvider;
  }

  private getTechnicalContactValue(
    heatingSystemId: HeatingSystem['id'],
    property: RuleValueProperty,
  ): TechnicalContact | boolean | undefined {
    const rule = this.alertServiceProviderRulesByHeatingSystemIds?.items.find(
      (rule) => rule.heatingSystem.id === heatingSystemId,
    );

    if (property === 'messageShouldBeSent') return rule?.messageShouldBeSent || false;

    const technicalContact = this.technicalContacts?.items.find(
      (technicalContact) => technicalContact.email === rule?.[property] && rule?.[property],
    );
    return technicalContact;
  }

  protected get take(): number {
    return 10;
  }

  protected get count(): number {
    return this.heatingSystemCollection?.count ?? 0;
  }

  private onSearch(query: string): void {
    if (this.loading) {
      return;
    }

    this.searchQuery = query;
  }

  private get loading(): boolean {
    return this.$apollo.queries.heatingSystemCollection.loading;
  }

  private get loadingServiceProviders(): boolean {
    return this.$apollo.queries.serviceProviders.loading;
  }

  private get loadingTechnicalContacts(): boolean {
    return this.$apollo.queries.serviceProviders.loading;
  }

  private get serviceProvidersOptions(): ServiceProvider[] {
    return this.serviceProviders?.items.filter((item) => item.email) ?? [];
  }

  private get technicalContactsOptions(): TechnicalContact[] {
    return this.technicalContacts?.items ?? [];
  }

  private get technicalContactsDisabled(): boolean {
    return this.me ? !this.me.customerAdmin : true;
  }

  private get technicalContactPlaceholder(): string {
    return this.technicalContactsDisabled ? '' : 'bitte auswählen';
  }

  private async clearProviders(heatingSystemId: HeatingSystem['id']): Promise<void> {
    const alreadyExistingEntityId = this.serviceProviderId(heatingSystemId);
    if (alreadyExistingEntityId) {
      const payload: UpdateAlertServiceProviderRulesInput = {
        id: alreadyExistingEntityId,
        heatingSystemId: heatingSystemId,
        emergencyProviderName: null,
        emergencyProviderEmail: null,
        companyName: null,
        contactPerson: null,
        technicianName: null,
        technicianEmail: null,
      };
      this.onUpdate(payload);
    }
  }

  private async onUpdate(payload: UpdateAlertServiceProviderRulesInput): Promise<void> {
    const { data } = await this.$apollo.mutate<
      UpdateAlertServiceProviderRulesMutation,
      UpdateAlertServiceProviderRulesMutationVariables
    >({
      mutation: updateAlertServiceProviderRulesMutation,
      client: APOLLO_CLIENT.HEATING_SYSTEM_COLLECTOR_CLIENT,
      variables: {
        input: payload,
      },
    });
    if (data) {
      this.ADD_TOAST_MESSAGES({
        messages: [{ text: 'Einstellungen für Alarme gespeichert!', class: 'success' }],
        duration: 3000,
      });
      await this.$apollo.queries.heatingSystemCollection.refetch();
      await this.$apollo.queries.alertServiceProviderRulesByHeatingSystemIds.refetch();
    }
  }

  private onUpdateShouldMessageBeSend(value: boolean, heatingSystemId: HeatingSystem['id']): void {
    const alreadyExistingEntityId = this.serviceProviderId(heatingSystemId);
    const payload: UpdateAlertServiceProviderRulesInput = {
      heatingSystemId: heatingSystemId,
      ...(alreadyExistingEntityId && { id: alreadyExistingEntityId }),
      messageShouldBeSent: value,
    };
    this.onUpdate(payload);
  }

  private onUpdateCleaning(serviceProvider: ServiceProvider, heatingSystemId: HeatingSystem['id']): void {
    const alreadyExistingEntityId = this.serviceProviderId(heatingSystemId);
    const payload: UpdateAlertServiceProviderRulesInput = {
      heatingSystemId: heatingSystemId,
      ...(alreadyExistingEntityId && { id: alreadyExistingEntityId }),
      companyName: `${serviceProvider.firstName ?? ''} ${serviceProvider.lastName ?? ''}`,
      contactPerson: serviceProvider.email,
    };
    this.onUpdate(payload);
  }

  private onUpdateEmergency(serviceProvider: ServiceProvider, heatingSystemId: HeatingSystem['id']): void {
    const alreadyExistingEntityId = this.serviceProviderId(heatingSystemId);
    const payload: UpdateAlertServiceProviderRulesInput = {
      heatingSystemId: heatingSystemId,
      ...(alreadyExistingEntityId && { id: alreadyExistingEntityId }),
      emergencyProviderName: `${serviceProvider.firstName ?? ''} ${serviceProvider.lastName ?? ''}`,
      emergencyProviderEmail: serviceProvider.email,
    };
    this.onUpdate(payload);
  }

  private onUpdateTechnician(serviceProvider: ServiceProvider, heatingSystemId: HeatingSystem['id']): void {
    const payload: UpdateAlertServiceProviderRulesInput = {
      heatingSystemId: heatingSystemId,
      technicianName: `${serviceProvider.firstName ?? ''} ${serviceProvider.lastName ?? ''}`,
      technicianEmail: serviceProvider.email,
    };
    this.onUpdate(payload);
  }
}
