var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-wizard',{attrs:{"title":"Messstelle hinzufügen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var show = ref.show;
return [_c('ui-wizard-button',{on:{"click":show}},[_vm._v("Messstelle hinzufügen")])]}},(_vm.consumers)?{key:"content",fn:function(ref){
var hide = ref.hide;
return [(_vm.consumers.length > 0)?_c('ui-form',{attrs:{"initial-data":_vm.initialData,"action":_vm.addHeatingSystemMeasurement},on:{"submitted":hide},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var dirty = ref.dirty;
var busy = ref.busy;
var submit = ref.submit;
var errorMessages = ref.errorMessages;
return [_c('core-toast',{attrs:{"messages":errorMessages,"error":""}}),_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-text","path":"addMeasurement.name","label":"Name","error":dirty && !_vm.isNonEmptyString(formData.addMeasurement.name)}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-select","path":"addMeasurement.consumerId","label":"Versorgungsbereich","option-label":"name","options":_vm.consumers,"reduce":function (ref) {
	var id = ref.id;

	return id;
}}}),_c('ui-form-field',{attrs:{"input":"ui-input-array-item","array-input":"core-input-tree-node-select-control","path":"addMeasurement.spotId","label":"Gerät","root-ids":_vm.rootIds,"types":_vm.types,"selectable-types":_vm.selectableTypes,"transformer":_vm.pluckId}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-text","path":"addMeasurement.metricName","label":"Metrik","error":dirty && !_vm.isNonEmptyString(formData.addMeasurement.metricName)}})],1),(_vm.enerIQSettingsVisible)?_c('ui-cell',{attrs:{"basis":1}},[_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-checkbox","path":"enerIQ.link","option-label":"Messstelle mit EnerIQ Verknüpfen","label":' '}})],1),(formData.enerIQ && formData.enerIQ.link)?_c('ui-cell',{attrs:{"basis":1}},[_c('ui-flex',{attrs:{"direction":"row","basis":1}},[_c('ui-cell',[_c('ui-form-field',{attrs:{"input":"ui-input-select","path":"enerIQ.systemComponentType","label":"Komponente","option-label":"label","options":_vm.components,"reduce":function (ref) {
	var typeName = ref.typeName;

	return typeName;
},"error":dirty && !!formData.enerIQ.link && !_vm.isNonEmptyString(formData.enerIQ.systemComponentType)},on:{"input":_vm.systemComponentTypeSelected}})],1),_c('ui-cell',[_c('ui-form-field',{attrs:{"input":"ui-input-text","path":"enerIQ.componentNumber","initial-path":"enerIQ.componentNumber","type":"number","min":"1","label":"Komponentennummer","error":dirty && !!formData.enerIQ.link && !Number(formData.enerIQ.componentNumber) > 0}})],1)],1)],1):_vm._e(),(formData.enerIQ && formData.enerIQ.link)?_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{ref:"sourceType",attrs:{"input":"ui-input-select","path":"enerIQ.sourceType","label":"Messstellentyp","option-label":"label","options":_vm.sourceTypes,"reduce":function (ref) {
	var key = ref.key;

	return key;
},"error":dirty && !!formData.enerIQ.link && !_vm.isNonEmptyString(formData.enerIQ.sourceType)}})],1):_vm._e()],1)],1):_vm._e(),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard-button',{attrs:{"disabled":!dirty || !_vm.isEnerIQValid(formData.enerIQ)|| !_vm.isFormValid(formData.addMeasurement),"busy":busy},on:{"click":submit}},[_vm._v("Anlegen")])],1)],1)]}}],null,true)}):_c('ui-form-message',{attrs:{"warning":""}},[_vm._v("Um Messstellen anzulegen müssen Sie zuerst mindestens einen"),_c('ui-link',{on:{"click":function($event){return _vm.onAddConsumerClick(hide)}}},[_vm._v("Versorgungsbereich hinzufügen")])],1)]}}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }