import ExcelJS from 'exceljs';
import { GetProjectQuery_project } from '../__generated__/GetProjectQuery';
import ApolloClient, { ApolloQueryResult } from 'apollo-client';
import {
  GetProjectDetailsExportQuery,
  GetProjectDetailsExportQueryVariables,
  GetProjectDetailsExportQuery_projectDetails_items,
  GetProjectDetailsExportQuery_projectDetails_items_customerContacts,
  GetProjectDetailsExportQuery_projectDetails_items_useCaseList,
} from '../__generated__/GetProjectDetailsExportQuery';
import projectDetailExportQuery from '../project-details-export.gql';
import { setBicabColumns, setGk40Columns, styleBicarbSheet, styleGk4NullSheet } from './style-xlsx';
import {
  BICAB_HEADER_ROW,
  BICAB_HEADER_ROW_2,
  ENTERPRISE_PRODUCT_OPTION_MAP,
  HEADER_ROW,
  HEADER_ROW_2,
  LIEGENSCHAFT_GW_REXEP,
} from './standortlists-constants';
import {
  EnterpriseProjectObjectProduct,
  EnterpriseProjectObjectProductOption,
  EnterpriseProjectObjectUseCase,
  TreeNodeClassification,
} from '@/types/iot-portal';
import getProjectData from '@/features/app-enterprise-project/views/enterprise-project-create/get-project-draft.gql';
import {
  GetProjectDataUsingIDQuery,
  GetProjectDataUsingIDQuery_projectDraft,
} from '@/features/app-enterprise-project/views/enterprise-project-create/__generated__/GetProjectDataUsingIDQuery';
import { DatabaseProjectDraft } from '@/features/core/store/page-modules/enterprise-project-create/create-module-types';

type UseCase = GetProjectDetailsExportQuery_projectDetails_items_useCaseList;
type CustomerContact = GetProjectDetailsExportQuery_projectDetails_items_customerContacts;
type ProjectObjectDetails = GetProjectDetailsExportQuery_projectDetails_items;

function getExcelProductType(
  object: ProjectObjectDetails,
  option?: EnterpriseProjectObjectProductOption | null,
): string {
  if (option && object.buildingType === TreeNodeClassification.Liegenschaft) {
    return ENTERPRISE_PRODUCT_OPTION_MAP[option];
  }
  return '';
}

function getExcelBuildingType(buildingType: string): string {
  if (buildingType === TreeNodeClassification.Liegenschaft) {
    return 'Liegenschaft (Hauptgebäude)';
  }
  if (buildingType === TreeNodeClassification.Gebaude) {
    return 'Gebäude';
  }
  return '';
}

function formatCustomerContact(customerContact: CustomerContact | undefined): string {
  let name = '';
  if (customerContact?.title) {
    name += `${customerContact.title} `;
  }
  name = name.trimStart();

  if (customerContact?.firstName) {
    name += `${customerContact.firstName} `;
  }
  name = name.trimStart();

  if (customerContact?.surname) {
    name += `${customerContact.surname}`;
  }
  name = name.trim();

  return name;
}

function getUseCaseObject(useCaseList: UseCase[], useCaseType: string): UseCase | undefined {
  return useCaseList?.find((useCase) => useCase.useCaseType === useCaseType);
}

type UseCaseColumns = [string, string | number, string | number];

function getUseCaseColumns(property: ProjectObjectDetails, useCase: UseCase | undefined): UseCaseColumns {
  if (property.buildingType !== TreeNodeClassification.Liegenschaft) {
    return ['', '', ''];
  }

  // If there is a use case and at least one price is not null
  if (useCase && (useCase?.oneTimePriceInEuros !== null || useCase?.monthlyPriceInEuros !== null)) {
    return ['ja', useCase.oneTimePriceInEuros ?? '-', useCase.monthlyPriceInEuros ?? '-'];
  }

  return ['nien', '', ''];
}

function getLocation(property: ProjectObjectDetails): string {
  let location = `${property.streetAddress} ${property.houseAddress}`.trim();
  if (location) {
    location += ', ';
  }
  location += `${property.postCodeAddress} ${property.cityAddress}`.trim();
  return location;
}

function addStandortlisteRow(
  worksheet: ExcelJS.Worksheet,
  property: ProjectObjectDetails,
  gatewayIdentifier: string,
): void {
  const generateUseCaseColumns = (useCase?: UseCase): UseCaseColumns => getUseCaseColumns(property, useCase);

  const infrastructureVariant = property.products.find(
    ({ productType }) => productType === EnterpriseProjectObjectProduct.INFRASTRUCTURE_VARIANT,
  );
  const iwCloud = property.products.find(({ productType }) => productType === EnterpriseProjectObjectProduct.IW_CLOUD);
  const customerContact = property.customerContacts[0];
  const useCaseList = property.useCaseList ?? [];

  const digitizationUseCase = getUseCaseObject(useCaseList, EnterpriseProjectObjectUseCase.TGA_DIGITIZATION);
  const heatingMonitoringUseCase = getUseCaseObject(useCaseList, EnterpriseProjectObjectUseCase.HEATING_MONITORING);
  const heatingProfessionalUseCase = getUseCaseObject(useCaseList, EnterpriseProjectObjectUseCase.HEATING_PROFESSIONAL);
  const zfaUseCase = getUseCaseObject(useCaseList, EnterpriseProjectObjectUseCase.REMOTE_METER_READING);
  const tgaMonitoringUseCase = getUseCaseObject(useCaseList, EnterpriseProjectObjectUseCase.TGA_MONITORING);
  const tgaIntegrationUseCase = getUseCaseObject(useCaseList, EnterpriseProjectObjectUseCase.TGA_INTEGRATION);
  const smgUseCase = getUseCaseObject(useCaseList, EnterpriseProjectObjectUseCase.SMG);
  const serviceManagementUseCase = getUseCaseObject(useCaseList, EnterpriseProjectObjectUseCase.SERVICE_MANAGEMENT);
  const vdeUseCase = getUseCaseObject(useCaseList, EnterpriseProjectObjectUseCase.CONSUMPTION_DATA_ACQUISITION);
  const uviUseCase = getUseCaseObject(
    useCaseList,
    EnterpriseProjectObjectUseCase.CONSUMPTION_INFORMATION_DURING_THE_YEAR,
  );
  const smokeDetectorMonitoringUseCase = getUseCaseObject(
    useCaseList,
    EnterpriseProjectObjectUseCase.SMOKE_ALARM_MONITORING,
  );
  const elevatorMonitoringUseCase = getUseCaseObject(useCaseList, EnterpriseProjectObjectUseCase.ELEVATOR_MONITORING);
  const smartElevatorMaintenanceUseCase = getUseCaseObject(
    useCaseList,
    EnterpriseProjectObjectUseCase.SMART_ELEVATOR_MAINTENANCE,
  );

  worksheet.addRow([
    getExcelBuildingType(property.buildingType ?? ''),
    property.buildingReferenceId,
    property.itemName,
    property.mainService,
    gatewayIdentifier,
    infrastructureVariant?.oneTimePriceInEuros,
    infrastructureVariant?.monthlyPriceInEuros,
    property.buildingType === TreeNodeClassification.Liegenschaft ? getLocation(property) : '',
    property.buildingType === TreeNodeClassification.Gebaude ? getLocation(property) : '',
    getExcelProductType(property, infrastructureVariant?.option),
    '',
    '',
    formatCustomerContact(customerContact),
    customerContact?.phoneNumber,
    customerContact?.email,
    '',
    '',
    getExcelProductType(property, iwCloud?.option),
    iwCloud?.oneTimePriceInEuros,
    iwCloud?.monthlyPriceInEuros,
    'Classic Gk4.0',
    '',
    '',
    digitizationUseCase ? 'ja' : 'nein',
    digitizationUseCase?.oneTimePriceInEuros ?? '',
    ...generateUseCaseColumns(heatingMonitoringUseCase),
    ...generateUseCaseColumns(heatingProfessionalUseCase),
    ...generateUseCaseColumns(tgaMonitoringUseCase),
    ...generateUseCaseColumns(tgaIntegrationUseCase),
    ...generateUseCaseColumns(zfaUseCase),
    ...generateUseCaseColumns(smgUseCase),
    ...generateUseCaseColumns(serviceManagementUseCase),
    ...generateUseCaseColumns(vdeUseCase),
    ...generateUseCaseColumns(uviUseCase),
    ...generateUseCaseColumns(smokeDetectorMonitoringUseCase),
    ...generateUseCaseColumns(elevatorMonitoringUseCase),
    ...generateUseCaseColumns(smartElevatorMaintenanceUseCase),
  ]);
}

function addPropertyServicesInfo(worksheet: ExcelJS.Worksheet, details: ProjectObjectDetails[]): void {
  worksheet.addRow(HEADER_ROW);
  worksheet.addRow(HEADER_ROW_2);

  for (const property of details.filter((item) => item.buildingType === TreeNodeClassification.Liegenschaft)) {
    const allGatewayDetails =
      property.gatewayDetails?.filter((item) => LIEGENSCHAFT_GW_REXEP.exec(item.identifier)) || [];
    let gatewayIdentifier = '';

    if (allGatewayDetails.length === 0) {
      if (property.gatewayDetails?.length) {
        gatewayIdentifier = property.gatewayDetails.map((item) => item.identifier).join(' / ');
      }
    } else {
      gatewayIdentifier = allGatewayDetails.map((item) => item.identifier).join(' / ');
    }

    addStandortlisteRow(worksheet, property, gatewayIdentifier);

    for (const subProperty of details.filter(
      (item) =>
        item.buildingType === TreeNodeClassification.Gebaude &&
        item.buildingReferenceId === property.buildingReferenceId,
    )) {
      // There should only be one gateway for each Gebäude
      let subGatewayIdentifier = '';

      if (subProperty.gatewayDetails?.length) {
        subGatewayIdentifier = subProperty.gatewayDetails.map((item) => item.identifier).join(' / ');
      }

      addStandortlisteRow(worksheet, subProperty, subGatewayIdentifier);
    }
  }
}

function addBicab2Rows(worksheet: ExcelJS.Worksheet, details: ProjectObjectDetails[]): void {
  worksheet.addRow(BICAB_HEADER_ROW);
  worksheet.addRow(BICAB_HEADER_ROW_2);

  details
    .filter((item) => item.buildingType === TreeNodeClassification.Liegenschaft)
    .forEach((property) => {
      worksheet.addRow([
        getExcelBuildingType(property.buildingType ?? ''),
        property.buildingReferenceId,
        property.itemName,
        getLocation(property),
        'BICAB 2.0',
        '300/50',
        0,
        0,
        'VF-Station	',
        'Standard Advanced',
        'Absprache Techniker-Kunde	',
        'CPE im Schrank',
      ]);
    });
}

async function queryAllProjectDetailsData(
  client: ApolloClient<unknown>,
  variables: Omit<GetProjectDetailsExportQueryVariables, 'skip' | 'take'>,
): Promise<ProjectObjectDetails[]> {
  let allDetails: ProjectObjectDetails[] = [];
  let details: ApolloQueryResult<GetProjectDetailsExportQuery>;
  let skip = 0;

  do {
    details = await client.query<GetProjectDetailsExportQuery>({
      query: projectDetailExportQuery,
      variables: { ...variables, skip, take: 500 },
    });

    allDetails = allDetails.concat(details.data.projectDetails.items);
    skip += 500;
  } while (details.data.projectDetails.count > allDetails.length);

  return allDetails;
}

async function queryProjectDraftCustomerInfo(client: ApolloClient<unknown>, projectReference: string): Promise<string> {
  const { data } = await client.query<GetProjectDataUsingIDQuery>({
    query: getProjectData,
    variables: { projectReference },
  });

  if (!data?.projectDraft?.pageContent) {
    return 'Customer not found';
  }

  const projectDraft = data.projectDraft?.pageContent as unknown as DatabaseProjectDraft;
  // Get the customer info from the project draft
  return projectDraft.general.customerNumber ?? 'Customer not found';
}

export async function generateStandortlist(
  project: GetProjectQuery_project,
  client: ApolloClient<unknown>,
): Promise<void> {
  const details = await queryAllProjectDetailsData(client, { projectReference: project.projectReference });
  // Get the customer info from the project draft
  const customerNumber = await queryProjectDraftCustomerInfo(client, project.projectReference);
  const preamble = [
    ['Standortliste Gk4.0'],
    ['Neubestellung / Änderung'],
    ['Kundennamee:', '', project.customer?.name ?? 'NO CUSTOMER'],
    ['Kundennummer:', '', customerNumber],
    ['Rechnungskontonummer'],
    ['Vertragslaufzeit'],
    ['ASP Vertrieb', '', project.salesContactUserName],
    [],
  ];
  const bicabPreamble = preamble.map((row) => [...row]);
  bicabPreamble[0].push('Kabel-Access für GK4.0');
  bicabPreamble[1][0] = 'Neubestellung';

  const workbook = new ExcelJS.Workbook();
  const gk40Worksheet = workbook.addWorksheet('GK4.0', {
    views: [{ state: 'frozen', xSplit: 3, ySplit: 9, showGridLines: false }],
  });

  setGk40Columns(gk40Worksheet);
  gk40Worksheet.addRows(preamble);
  addPropertyServicesInfo(gk40Worksheet, details);
  styleGk4NullSheet(gk40Worksheet);
  await gk40Worksheet.protect(atob('Z2s0bnVsbCEyMDI0JA=='), {});

  const bicabWorksheet = workbook.addWorksheet('BICAB 2.0 für GK4.0', {
    views: [{ state: 'frozen', xSplit: 3, ySplit: 9, showGridLines: false, zoomScale: 145 }],
  });

  setBicabColumns(bicabWorksheet);
  bicabWorksheet.addRows(bicabPreamble);
  addBicab2Rows(bicabWorksheet, details);
  styleBicarbSheet(bicabWorksheet);
  await bicabWorksheet.protect(btoa('Z2s0bnVsbCEyMDI0JA=='), {});

  // Code adapted from https://stackoverflow.com/a/74728655 and https://github.com/exceljs/exceljs/issues/402#issuecomment-347927095
  const workbookBuffer = await workbook.xlsx.writeBuffer({ base64: true } as unknown as ExcelJS.XlsxWriteOptions);

  const downloadBlob = new Blob([workbookBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  const link = document.createElement('a');
  document.body.appendChild(link);
  link.href = URL.createObjectURL(downloadBlob);
  link.download = 'GK4.0_Standortliste.xlsx';
  link.click();
  setTimeout(() => {
    URL.revokeObjectURL(link.href);
    document.body.removeChild(link);
  }, 0);
}
