











import { computed, defineComponent } from '@vue/composition-api';

interface ProjectLBuildingProps {
  projectItems: Array<{
    buildingType: string;
    status: string;
  }>;
}
export default defineComponent<ProjectLBuildingProps, Record<string, unknown>, any, any>({
  components: {},
  props: {
    projectItems: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const statusCounts = computed(() => {
      const counts = {
        blue: 0,
        red: 0,
        orange: 0,
        green: 0,
      };

      props.projectItems.forEach((item) => {
        if (item.buildingType === 'Liegenschaft') {
          switch (item.status) {
            case 'PROPERTY_CREATED':
            case 'PROPERTY_LIST_FINAL':
            case 'ORDER_CONFIRMED':
              counts.blue += 1;
              break;
            case 'INFORMATION_MISSING':
              counts.red += 1;
              break;
            case 'IN_DEPLOYMENT':
            case 'DEPLOYMENT_DONE':
            case 'QUALITY_MANAGEMENT':
              counts.orange += 1;
              break;
            case 'COMPLETED':
            case 'CANCELLED':
              counts.green += 1;
              break;
          }
        }
      });

      return counts;
    });

    return {
      statusCounts,
    };
  },
});
