















import { Vue } from 'vue-property-decorator';
export default class SettingsComponentHeader extends Vue {}
