var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-header-test"},[_c('h3',{staticClass:"column-header"},[_vm._v("Projektdaten")]),_c('p',[_vm._v("Bitte tragen Sie hier die Stammdaten des Projektes ein.")]),_c('Form',{attrs:{"initial-data":{
      customerName: _vm.customerName,
      customerNumber: _vm.customerNumber,
      partnerName: _vm.partnerName,
    },"action":function (data) { return _vm.updateProjectData(data); }},on:{"submitted":function($event){return _vm.$emit('project-data-updated')},"error":function($event){return _vm.$emit('error', $event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var dirty = ref.dirty;
    var busy = ref.busy;
    var submit = ref.submit;
    var formData = ref.formData;
return [_c('FormField',{attrs:{"input":"ui-input-text","data-element-id":'app-enterprise-project-create-customer-name',"path":"customerName","label":"Kundenname","error":dirty && !formData.customerName,"description":dirty && !formData.customerName ? 'Bitte geben Sie einen Kundenname ein.' : '',"disabled":_vm.generalInformationFormDisabled}}),_c('FormField',{attrs:{"input":"ui-input-text","data-element-id":'app-enterprise-project-create-customer-number',"path":"customerNumber","label":"Kundennummer","error":dirty && !formData.customerNumber,"description":dirty && !formData.customerNumber ? 'Bitte geben Sie eine Kundennummer ein.' : '',"disabled":_vm.generalInformationFormDisabled}}),_c('FormField',{attrs:{"input":"ui-input-text","data-element-id":'app-enterprise-project-create-partner-name',"path":"partnerName","label":"E-Mail Adresse des Ansprechpartners","error":dirty && !_vm.validateEmail(formData.partnerName),"description":dirty && !_vm.validateEmail(formData.partnerName) ? 'Bitte geben Sie eine gültige E-Mail Adresse ein.' : '',"disabled":_vm.generalInformationFormDisabled}}),(!_vm.generalInformationCompleted)?_c('WizardButton',{attrs:{"disabled":!_vm.validateForm(formData),"busy":busy},on:{"click":submit}},[_vm._v("WEITER ZU LIEGENSCHAFTSDATEN")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }