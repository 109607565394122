import { render, staticRenderFns } from "./AppView.vue?vue&type=template&id=21a49b86&lang=pug&"
import script from "./AppView.vue?vue&type=script&lang=ts&"
export * from "./AppView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports