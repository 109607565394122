









import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import query from './view.gql';
import {
  AppHeatingSystemHeatingSystemMeasurementsViewQuery,
  AppHeatingSystemHeatingSystemMeasurementsViewQueryVariables,
} from './__generated__/AppHeatingSystemHeatingSystemMeasurementsViewQuery';
import HeatingSystemMeasurementsTable from './HeatingSystemMeasurementsTable.vue';
import AddHeatingSystemMeasurementWizardControl from './add-heating-system-measurement-wizard-control/AddHeatingSystemMeasurementWizardControl.vue';
import removeHeatingSystemMeasurement from './remove-heating-system-measurement.gql';
import {
  AppHeatingSystemHeatingSystemMeasurementsRemoveHeatingSystemMeasurementMutation,
  AppHeatingSystemHeatingSystemMeasurementsRemoveHeatingSystemMeasurementMutationVariables,
} from './__generated__/AppHeatingSystemHeatingSystemMeasurementsRemoveHeatingSystemMeasurementMutation';

type Measurement =
  AppHeatingSystemHeatingSystemMeasurementsViewQuery['heatingSystems']['first']['measurements'][number];

@Component({
  apollo: {
    heatingSystems: {
      query,
      variables(this: MeasurementsView): AppHeatingSystemHeatingSystemMeasurementsViewQueryVariables {
        return { heatingSystemId: this.heatingSystemId };
      },
    },
  },
  components: { HeatingSystemMeasurementsTable, AddHeatingSystemMeasurementWizardControl },
})
export default class MeasurementsView extends Vue {
  @StringProp(true)
  private readonly heatingSystemId!: string;

  private readonly heatingSystems?: AppHeatingSystemHeatingSystemMeasurementsViewQuery['heatingSystems'];

  private get customerId(): string | undefined {
    return this.heatingSystems?.first.customer.id;
  }

  private async removeMeasurement(measurement: Measurement): Promise<void> {
    if (!window.confirm(`Sind Sie sich sicher, dass Sie die Messstelle "${measurement.name}" entfernen möchten?`)) {
      return;
    }

    const { data } = await this.$apollo.mutate<
      AppHeatingSystemHeatingSystemMeasurementsRemoveHeatingSystemMeasurementMutation,
      AppHeatingSystemHeatingSystemMeasurementsRemoveHeatingSystemMeasurementMutationVariables
    >({
      mutation: removeHeatingSystemMeasurement,
      variables: { input: { id: measurement.id } },
    });

    if (!data) {
      throw new Error('Die Messstelle konnte nicht entfernt werden!');
    }
  }
}
