








































































































import { StringProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import addHeatingSystemMeasurementMutation from './add-heating-system-measurement.gql';
import connectEneriqMeasurementMutation from './connect-eneriq-measurement.gql';
import {
  AppHeatingSystemAddHeatingSystemMeasurementMutation,
  AppHeatingSystemAddHeatingSystemMeasurementMutationVariables,
} from './__generated__/AppHeatingSystemAddHeatingSystemMeasurementMutation';
import {
  AppHeatingSystemConnectEnerIqMeasurementMutation,
  AppHeatingSystemConnectEnerIqMeasurementMutationVariables,
} from './__generated__/AppHeatingSystemConnectEnerIqMeasurementMutation';
import {
  AddHeatingSystemMeasurementInput,
  TreeNodeType,
  EnerIqMeasurementSystemComponentType,
  EnerIqMeasurementSourceType,
} from '@/types/iot-portal';
import FormField from '@/features/ui/form/FormField.global.vue';
import query from './view.gql';
import {
  AppHeatingSystemAddHeatingSystemMeasurementWizardControlQuery,
  AppHeatingSystemAddHeatingSystemMeasurementWizardControlQueryVariables,
} from './__generated__/AppHeatingSystemAddHeatingSystemMeasurementWizardControlQuery';
import { isDef } from '@/util/lang';
import { isNonEmptyString } from '@/util/string';
import {
  EneriqTypesMixin,
  sourceType,
} from '@/features/app-heating-system/components/mixins/measurements/eneriq-types-mixin';

type Consumer =
  AppHeatingSystemAddHeatingSystemMeasurementWizardControlQuery['heatingSystems']['first']['consumers'][number]['node'];

interface EnerIQInput {
  link: boolean;
  systemComponentType: EnerIqMeasurementSystemComponentType;
  sourceType: EnerIqMeasurementSourceType;
  componentNumber: string;
}

interface FormData {
  addMeasurement: Omit<AddHeatingSystemMeasurementInput, 'heatingSystemId'>;
  enerIQ: EnerIQInput;
}

@Component({
  apollo: {
    heatingSystems: {
      query,
      variables(
        this: AddHeatingSystemMeasurementWizardControl,
      ): AppHeatingSystemAddHeatingSystemMeasurementWizardControlQueryVariables {
        return { heatingSystemId: this.heatingSystemId };
      },
    },
  },
  data() {
    return {
      initialData: { addMeasurement: {}, enerIQ: { link: false, componentNumber: 1 } },
      heatingSystems: undefined,
      isNonEmptyString,
      selectedComponent: undefined,
      sourceTypes: [],
    };
  },
})
export default class AddHeatingSystemMeasurementWizardControl extends Mixins(EneriqTypesMixin) {
  @StringProp(true)
  private readonly heatingSystemId!: string;

  @StringProp(true)
  private readonly customerId!: string;

  private sourceTypes: sourceType[] = [];
  public readonly $refs!: { sourceType: FormField };

  private heatingSystems?: AppHeatingSystemAddHeatingSystemMeasurementWizardControlQuery['heatingSystems'];

  private get consumers(): Consumer[] | undefined {
    return this.heatingSystems?.first.consumers.filter(isDef).map(({ node }) => node);
  }

  private get enerIQSettingsVisible(): boolean {
    return this.heatingSystems?.first?.enerIqConnectedEconomicUnit != null;
  }

  private pluckId<T>(object: { id: T } | null): T | null {
    return object?.id ?? null;
  }

  private get types(): TreeNodeType[] {
    return [
      TreeNodeType.RootDirectory,
      TreeNodeType.Directory,
      TreeNodeType.Property,
      TreeNodeType.PropertyGroup,
      TreeNodeType.PropertySubdivision,
      TreeNodeType.Spot,
    ];
  }

  private get selectableTypes(): TreeNodeType[] {
    return [TreeNodeType.Spot];
  }

  private get rootIds(): string[] {
    return [this.customerId];
  }

  private isFormValid(input: Partial<AddHeatingSystemMeasurementInput>): boolean {
    return (
      isNonEmptyString(input.name) &&
      isNonEmptyString(input.metricName) &&
      isNonEmptyString(input.consumerId) &&
      isNonEmptyString(input.spotId)
    );
  }

  private isEnerIQValid(input: EnerIQInput): boolean {
    return (
      !input ||
      !input.link ||
      (isNonEmptyString(input.sourceType) &&
        isNonEmptyString(input.systemComponentType) &&
        Number(input.componentNumber) > 0)
    );
  }

  private systemComponentTypeSelected(typeName: string): void {
    this.sourceTypes = this.components.find((el) => el.typeName == typeName)?.sourceTypes ?? [];
    if (this.sourceTypes.length === 1) {
      this.$refs.sourceType.value = this.sourceTypes[0].key;
    }
  }

  private async addHeatingSystemMeasurement(input: FormData): Promise<void> {
    const { data } = await this.$apollo.mutate<
      AppHeatingSystemAddHeatingSystemMeasurementMutation,
      AppHeatingSystemAddHeatingSystemMeasurementMutationVariables
    >({
      mutation: addHeatingSystemMeasurementMutation,
      variables: { input: { ...input.addMeasurement, heatingSystemId: this.heatingSystemId } },
    });

    if (!data) {
      throw new Error('Die Messstelle konnte nicht hinzugefügt werden!');
    }

    const newMeasurement = data.addHeatingSystemMeasurement.heatingSystem.measurements.find((measurement) => {
      return (
        measurement.name == input.addMeasurement.name &&
        measurement.metricName == input.addMeasurement.metricName &&
        measurement.consumer?.node?.id == input.addMeasurement.consumerId &&
        measurement.spot.id == input.addMeasurement.spotId
      );
    });

    if (!newMeasurement) {
      throw new Error('Die Messstelle konnte nicht hinzugefügt werden!');
    }

    if (input.enerIQ && input.enerIQ.link && this.heatingSystems?.first?.enerIqConnectedEconomicUnit?.id) {
      const { data } = await this.$apollo.mutate<
        AppHeatingSystemConnectEnerIqMeasurementMutation,
        AppHeatingSystemConnectEnerIqMeasurementMutationVariables
      >({
        mutation: connectEneriqMeasurementMutation,
        variables: {
          input: {
            enerIqEconomicUnitId: this.heatingSystems?.first?.enerIqConnectedEconomicUnit?.id,
            group: Number(input.enerIQ.componentNumber),
            heatingSystemMeasurementId: newMeasurement.id,
            sourceType: input.enerIQ.sourceType,
            systemComponentType: input.enerIQ.systemComponentType,
          },
        },
      });

      if (!data) {
        throw new Error(
          'Die Messstelle konnte nicht mit der EnerIq EconomicUnit verbunden werden. Versuchen Sie es erneut über die Editierfunktion!',
        );
      }

      this.$apollo.queries.heatingSystems.refetch();
    }
  }

  private async onAddConsumerClick(hide: () => Promise<void>): Promise<void> {
    await hide();

    this.$router.push({
      name: 'AppHeatingSystem/HeatingSystem/Consumers',
      params: this.$route.params,
    });
  }
}
