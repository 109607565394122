














































import { AlertType, ComparisonValue } from '@/types/hsc-types';
import { ObjectProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { AlertCustomerDefaultRule, AlertTypeLabel, MeasurementType, MeasurementTypeSorted } from '../model';

@Component
export default class SettingsComponent extends Vue {
  @ObjectProp()
  private readonly settingComponent!: AlertCustomerDefaultRule;

  private loading = false;

  private clearAlerts(measurementRuleId: string): void {
    this.$emit('clear-alert-type', AlertType.NOOP, measurementRuleId);
  }

  private updateAlertTypeOption(alertType: AlertType, measurementRuleId: string): void {
    this.$emit('update-alert-type', alertType, measurementRuleId);
  }

  private get sortedAlertMeasurementRules(): AlertCustomerDefaultRule['alertDefaultMeasurementRules'] {
    return this.settingComponent
      ? this.settingComponent.alertDefaultMeasurementRules.sort(
          (a, b) =>
            MeasurementTypeSorted[b.measurementType as MeasurementType] -
            MeasurementTypeSorted[a.measurementType as MeasurementType],
        )
      : [];
  }

  private updateComparisonValue(event: FocusEvent, measurementRuleId: string): void {
    const value = (event.target as HTMLInputElement).value;
    this.$emit('update-comparison-value', value, measurementRuleId);
  }

  private updateComparisonTypeValue(comparisonValue: ComparisonValue | null, measurementRuleId: string): void {
    this.$emit('update-comparison-value-type', comparisonValue, measurementRuleId);
  }

  private getComparisonValueLabel(comparisonValue: ComparisonValue): '<' | '=' | '>' {
    return comparisonValue === ComparisonValue.EQUAL ? '=' : comparisonValue === ComparisonValue.GREATER ? '>' : '<';
  }

  private getAlertTypeLabel(alertType: AlertType): AlertTypeLabel {
    return alertType === AlertType.EMERGENCY
      ? AlertTypeLabel.EMERGENCY_ALARM
      : alertType === AlertType.NOOP
      ? AlertTypeLabel.KEIN_ALARM
      : AlertTypeLabel.STANDARD_ALARM;
  }

  private getAlertTypeValue(alertType: AlertType): AlertType {
    return alertType ?? AlertType.NOOP;
  }

  private get alertTypeOptions(): AlertType[] {
    return [AlertType.NOOP, AlertType.EMERGENCY, AlertType.STANDARD];
  }

  private get comparisonValueOptions(): ComparisonValue[] {
    return [ComparisonValue.LOWER, ComparisonValue.EQUAL, ComparisonValue.GREATER];
  }
}
