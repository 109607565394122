





















































































import {
  COMMON_MEASUREMENT_METRIC_NAMES,
  HeatingSystemMeasurementType,
  SETTING_MEASUREMENT_METRIC_NAMES,
  TEMPERATURE_MEASUREMENT_METRIC_NAMES,
} from '@/features/app-heating-system/components/heating-system-measurement/model';
import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { BASE_COLUMNS } from '@/features/domain-ui/heating-system-table/model';
import { AddHeatingSystemInput, TreeNodeType } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { isString } from 'lodash';
import { Component as ComponentType } from 'vue';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import HeatingSystemCommonMeasurement from '../../../components/heating-system-measurement/HeatingSystemCommonMeasurement.global.vue';
import HeatingSystemSettingMeasurement from '../../../components/heating-system-measurement/HeatingSystemSettingMeasurement.global.vue';
import HeatingSystemTemperatureMeasurement from '../../../components/heating-system-measurement/HeatingSystemTemperatureMeasurement.global.vue';
import HeatingSystemDhwTemperatureMeasurements from '../../../components/heating-system-measurement/HeatingSystemDhwTemperatureMeasurements.vue';

import addHeatingSystemMutation from './add-heating-system.gql';
import query from './view.gql';
import {
  AppHeatingSystemAddHeatingSystemMutation,
  AppHeatingSystemAddHeatingSystemMutationVariables,
} from './__generated__/AppHeatingSystemAddHeatingSystemMutation';
import {
  AppHeatingSystemHeatingSystemsViewQuery,
  AppHeatingSystemHeatingSystemsViewQueryVariables,
} from './__generated__/AppHeatingSystemHeatingSystemsViewQuery';

type HeatingSystem = AppHeatingSystemHeatingSystemsViewQuery['treeNodes']['first']['heatingSystems']['items'][number];

type Measurement = HeatingSystem['measurements'][number];

type Tab = {
  key: string;
  label: string;
};

@Component({
  apollo: {
    treeNodes: {
      query,
      fetchPolicy: 'cache-and-network',
      pollInterval: 60000,
      variables(this: HeatingSystemsView): AppHeatingSystemHeatingSystemsViewQueryVariables {
        return {
          treeNodeId: this.treeNodeId,
          skip: this.skip,
          take: this.take,
          searchQuery: this.searchQuery === '' ? null : this.searchQuery,
        };
      },
      result(this: HeatingSystemsView): void {
        this.searchQuery = this.nextSearchQuery;
      },
    },
  },
  components: {
    HeatingSystemDhwTemperatureMeasurements,
  },
  data(this: HeatingSystemsView) {
    return {
      hiddenColumns: [
        'manufacturerId',
        'boilerManufacturer',
        'energyOutputRange',
        'waterStorageType',
        'waterStorageModel',
        'waterStorageManufacturer',
        'waterStorageCapacity',
        'maintenanceCompany',
        'numApartmentUnits',
        'numCommercialUnits',
        'suppliedQuantity',
        'lastHydraulicBalancing',
        'consumptions',
        'alerts',
        'health',
      ],
      treeNodes: undefined,
      searchQuery: isString(this.$route.query.query) ? this.$route.query.query : '',
      selectedIdForOverlay: undefined,
      measurementTab: HeatingSystemMeasurementType.COMMON,
    };
  },
})
export default class HeatingSystemsView extends Mixins(PaginationQueryStringMixin) {
  @StringProp(true)
  private readonly treeNodeId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly treeNodes?: AppHeatingSystemHeatingSystemsViewQuery['treeNodes'];

  private measurementTab!: HeatingSystemMeasurementType;

  private hiddenColumns!: string[];

  private searchQuery!: string;

  private currentTab: Tab | null = null;

  private readonly BASE_COLUMNS = BASE_COLUMNS;

  private expandedRows: number[] = [];

  private selectedIdForOverlay?: string;

  private get heatingSystems(): HeatingSystem[] {
    return this.treeNodes?.first.heatingSystems.items ?? [];
  }

  protected get count(): number {
    return this.treeNodes?.first.heatingSystems.count ?? 0;
  }

  private get nextSearchQuery(): string {
    return isString(this.$route.query.query) ? this.$route.query.query : '';
  }

  private set nextSearchQuery(value: string) {
    this.$router.replace({ query: { ...this.$route.query, query: value === '' ? undefined : value } });

    if (!this.$apollo.queries.treeNodes.loading) {
      this.searchQuery = value;
    }
  }

  private get heatingSystemMeasurements(): Measurement[] {
    if (this.selectedIdForOverlay === undefined) {
      return [];
    }
    return this.heatingSystems?.find(({ id }) => id === this.selectedIdForOverlay)?.measurements ?? [];
  }

  private get temperatureMeasurementNames(): string[] {
    return [
      ...TEMPERATURE_MEASUREMENT_METRIC_NAMES.GAUGE,
      ...TEMPERATURE_MEASUREMENT_METRIC_NAMES.THERMOMETER,
      ...TEMPERATURE_MEASUREMENT_METRIC_NAMES.DHW_MEASUREMENTS,
    ];
  }

  private get temperatureMeasurements(): Measurement[] {
    return this.heatingSystemMeasurements.filter(
      ({ metricName }) => this.temperatureMeasurementNames.findIndex((value) => value.includes(metricName)) !== -1,
    );
  }

  private get commonMeasurementNames(): string[] {
    return [...COMMON_MEASUREMENT_METRIC_NAMES.OTHER, ...COMMON_MEASUREMENT_METRIC_NAMES.STATE];
  }

  private get commonMeasurements(): Measurement[] {
    return this.heatingSystemMeasurements.filter(
      ({ metricName }) => this.commonMeasurementNames.findIndex((value) => value.includes(metricName)) !== -1,
    );
  }

  private get settingMeasurementNames(): string[] {
    return [...SETTING_MEASUREMENT_METRIC_NAMES.SWITCH, ...SETTING_MEASUREMENT_METRIC_NAMES.SCHEDULE];
  }

  private get settingMeasurements(): Measurement[] {
    return this.heatingSystemMeasurements.filter(
      ({ metricName }) => this.settingMeasurementNames.findIndex((value) => value.includes(metricName)) !== -1,
    );
  }

  private get measurements(): Measurement[] {
    switch (this.measurementTab) {
      case HeatingSystemMeasurementType.COMMON:
        return this.commonMeasurements;
      case HeatingSystemMeasurementType.TEMPERATURE:
        return this.temperatureMeasurements;
      case HeatingSystemMeasurementType.SETTING:
        return this.settingMeasurements;
      default:
        return [];
    }
  }

  private get measurementComponent(): ComponentType | undefined {
    switch (this.measurementTab) {
      case HeatingSystemMeasurementType.COMMON:
        return HeatingSystemCommonMeasurement;
      case HeatingSystemMeasurementType.TEMPERATURE:
        return HeatingSystemTemperatureMeasurement;
      case HeatingSystemMeasurementType.SETTING:
        return HeatingSystemSettingMeasurement;
      default:
        return undefined;
    }
  }

  private get canAddHeatingSystem(): boolean {
    if (!this.treeNodes) {
      return false;
    }
    return [TreeNodeType.Property, TreeNodeType.PropertyGroup, TreeNodeType.PropertySubdivision].includes(
      this.treeNodes.first.__typename as TreeNodeType,
    );
  }

  private get loading(): boolean {
    return this.$apollo.queries.treeNodes.loading;
  }

  private async addHeatingSystem(input: Omit<AddHeatingSystemInput, 'siteId'>): Promise<string> {
    const { data } = await this.$apollo.mutate<
      AppHeatingSystemAddHeatingSystemMutation,
      AppHeatingSystemAddHeatingSystemMutationVariables
    >({
      mutation: addHeatingSystemMutation,
      variables: { input: { ...input, siteId: this.treeNodeId } },
    });

    if (!data) {
      throw new Error('Das Heizsystem konnte nicht hinzugefügt werden!');
    }

    return data.addHeatingSystem.heatingSystem.id;
  }

  private async onSubmitted(heatingSystemId: string, hide: () => Promise<void>): Promise<void> {
    await hide();

    this.$router.push({ name: 'AppHeatingSystem/HeatingSystem', params: { heatingSystemId } });
  }

  private toggleRowExpansion(rowIndex: number): void {
    if (this.isRowExpanded(rowIndex)) {
      this.expandedRows = this.expandedRows.filter((currentRowIndex) => currentRowIndex !== rowIndex);
    } else {
      this.expandedRows.push(rowIndex);
    }
  }

  private isRowExpanded(rowIndex: number): boolean {
    return this.expandedRows.includes(rowIndex);
  }

  private switchMeasurementTab(value: HeatingSystemMeasurementType): void {
    this.measurementTab = value;
  }

  @Watch('currentPage')
  private resetExpandedRows(): void {
    this.expandedRows = [];
  }

  private get hasDhwMeasurements(): boolean {
    return this.measurements.some(
      ({ metricName }) =>
        TEMPERATURE_MEASUREMENT_METRIC_NAMES.DHW_MEASUREMENTS.findIndex((value) => value.includes(metricName)) !== -1,
    );
  }

  private get filteredMeasurements(): Measurement[] {
    return this.measurements.filter(
      ({ metricName }) =>
        TEMPERATURE_MEASUREMENT_METRIC_NAMES.DHW_MEASUREMENTS.findIndex((value) => value.includes(metricName)) === -1,
    );
  }

  private get dhwMeasurements(): Measurement[] {
    return this.measurements.filter(
      ({ metricName }) =>
        TEMPERATURE_MEASUREMENT_METRIC_NAMES.DHW_MEASUREMENTS.findIndex((value) => value.includes(metricName)) !== -1,
    );
  }

  private get temperatureMeasurementTabSelected(): boolean {
    return this.measurementTab === HeatingSystemMeasurementType.TEMPERATURE;
  }
}
