
































import { NotificationsMixin } from '@/features/core/components/mixins/notifications/notifications';
import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import TreeNodeLocationGeneratorMixin from '@/features/core/components/mixins/tree-node-location-generator';
import { RootAction, Action } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { NotificationType } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';

@Component
export default class NotificationsView extends Mixins(
  NotificationsMixin,
  PaginationQueryStringMixin,
  TreeNodeLocationGeneratorMixin,
) {
  @StringProp(true)
  private readonly treeNodeId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  protected get count(): number {
    return this.notifications?.count ?? 0;
  }

  protected get contextIds(): string[] {
    return [this.treeNodeId];
  }

  protected get types(): NotificationType[] {
    return [NotificationType.HeatingSystemNotification];
  }

  private async onReloadClick(): Promise<void> {
    this.currentPage = 1;

    await this.refetchNotifications();
  }

  private goToNotificationSettings(): void {
    this.$router.push({ name: 'AppUserAccount/NotificationSettings' });
  }
}
