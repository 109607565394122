




































import { ObjectProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { AppHeatingSystemHeatingSystemGeneralViewQuery } from '../__generated__/AppHeatingSystemHeatingSystemGeneralViewQuery';
import removeHeatingSystemMutation from './remove-heating-system.gql';
import {
  AppHeatingSystemHeatingSystemGeneralViewRemoveHeatingSystemMutation,
  AppHeatingSystemHeatingSystemGeneralViewRemoveHeatingSystemMutationVariables,
} from './__generated__/AppHeatingSystemHeatingSystemGeneralViewRemoveHeatingSystemMutation';

type HeatingSystem = AppHeatingSystemHeatingSystemGeneralViewQuery['heatingSystems']['first'];

@Component
export default class RemoveHeatingSystemWizardControl extends Vue {
  @ObjectProp(true)
  private readonly heatingSystem!: HeatingSystem;

  private async removeHeatingSystem(): Promise<void> {
    const { data } = await this.$apollo.mutate<
      AppHeatingSystemHeatingSystemGeneralViewRemoveHeatingSystemMutation,
      AppHeatingSystemHeatingSystemGeneralViewRemoveHeatingSystemMutationVariables
    >({
      mutation: removeHeatingSystemMutation,
      variables: { input: { id: this.heatingSystem.id } },
    });
    if (!data) {
      throw new Error('Heizungssystem konnte nicht entfernt werden.');
    }
  }

  private async onHeatingSystemRemoved(hide: () => Promise<void>): Promise<void> {
    await hide();
    this.$emit('removed');
  }

  private equalsName(name: string): boolean {
    return name === this.heatingSystem.name;
  }
}
