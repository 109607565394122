























































import { AlertType, ComparisonValue } from '@/types/hsc-types';
import { ObjectProp, StringProp } from '@/util/prop-decorators';
import { isEqual } from 'lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  SettingMeasurementComponent,
  AlertTypeLabel,
  HeatingSystemMeasurementGroup,
  UpdateAlertMesurementRule,
  MeasurementType,
  MeasurementTypeSorted,
} from '../model';

@Component
export default class SettingsMeasurementComponent extends Vue {
  @ObjectProp()
  private readonly component!: SettingMeasurementComponent;

  @ObjectProp()
  private readonly initialComponent!: SettingMeasurementComponent;

  @StringProp()
  private readonly measurementGroupId!: HeatingSystemMeasurementGroup['id'];

  private hasBeenChanged = false;

  @Watch('component', { deep: true })
  private onWatchComponent(newValue: SettingMeasurementComponent): void {
    if (!this.hasBeenChanged && !isEqual(newValue, this.initialComponent)) {
      this.hasBeenChanged = true;
    }
  }

  private updateAlertTypeOption(alertType: AlertType, heatingSystemMeasurementId: string): void {
    this.$emit('update-alert-type', alertType ?? AlertType.NOOP, heatingSystemMeasurementId);
  }

  private updateComparisonValue(event: FocusEvent, heatingSystemMeasurementId: string): void {
    const value = (event.target as HTMLInputElement).value;
    this.$emit('update-comparison-value', value, heatingSystemMeasurementId);
  }

  private updateComparisonTypeValue(comparisonValue: ComparisonValue | null, heatingSystemMeasurementId: string): void {
    this.$emit('update-comparison-value-type', comparisonValue, heatingSystemMeasurementId);
  }

  private get sortedMeasurementRules(): UpdateAlertMesurementRule[] {
    return (
      this.component.measurementRules.sort(
        (a, b) =>
          MeasurementTypeSorted[b.measurementType as MeasurementType] -
          MeasurementTypeSorted[a.measurementType as MeasurementType],
      ) || []
    );
  }

  private getComparisonValueLabel(comparisonValue: ComparisonValue): '<' | '=' | '>' {
    return comparisonValue === ComparisonValue.EQUAL ? '=' : comparisonValue === ComparisonValue.GREATER ? '>' : '<';
  }

  private get saveEnabled(): boolean {
    return this.hasBeenChanged;
  }

  private get historyPointEnabled(): boolean {
    return this.component.measurementRules.some((rule) => rule.historyPoint && rule.historyPoint.length > 0);
  }

  private getAlertTypeLabel(alertType: AlertType): AlertTypeLabel {
    return alertType === AlertType.EMERGENCY
      ? AlertTypeLabel.EMERGENCY_ALARM
      : alertType === AlertType.NOOP
      ? AlertTypeLabel.KEIN_ALARM
      : AlertTypeLabel.STANDARD_ALARM;
  }

  private getAlertTypeValue(alertType: AlertType): AlertType {
    return alertType ?? AlertType.NOOP;
  }

  private get alertTypeOptions(): AlertType[] {
    return [AlertType.NOOP, AlertType.EMERGENCY, AlertType.STANDARD];
  }

  private get comparisonValueOptions(): ComparisonValue[] {
    return [ComparisonValue.LOWER, ComparisonValue.EQUAL, ComparisonValue.GREATER];
  }

  private callUpdate(measurementGroupId: HeatingSystemMeasurementGroup['id']): void {
    this.$emit('onSave', measurementGroupId);
  }
  private callDefaults(measurementGroupId: HeatingSystemMeasurementGroup['id']): void {
    this.$emit('onDefaults', measurementGroupId);
  }
  private callHistoryPoints(measurementGroupId: HeatingSystemMeasurementGroup['id']): void {
    this.$emit('onHistoryPoints', measurementGroupId);
  }
}
