import { GatewayHealth } from '@/types/iot-portal';
import { omit } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';

@Component
export class GatewayHealthQueryStringMixin extends Vue {
  protected get health(): GatewayHealth | undefined {
    return GatewayHealth[this.$route.query.health as GatewayHealth] ?? undefined;
  }

  protected set health(health: GatewayHealth | undefined) {
    if (health === this.health) {
      return;
    }

    void this.$router.replace({ query: { ...omit(this.$route.query, 'page'), health } });
  }
}
