



















































import { TreeNodePathTreeNode } from '@/features/domain-ui/tree-node-path/model';
import { Column } from '@/features/ui/table/model';
import { RequiredProp } from '@/util/prop-decorators';
import { Vue, Component } from 'vue-property-decorator';
import { AppHeatingSystemHeatingSystemMeasurementsViewQuery } from './__generated__/AppHeatingSystemHeatingSystemMeasurementsViewQuery';
import EditHeatingSystemMeasurementWizardControl from './edit-heating-system-measurement-wizard-control/EditHeatingSystemMeasurementWizardControl.vue';

type Row = AppHeatingSystemHeatingSystemMeasurementsViewQuery['heatingSystems']['first']['measurements'][number];

@Component({ components: { EditHeatingSystemMeasurementWizardControl } })
export default class HeatingSystemMeasurementsTable extends Vue {
  @RequiredProp()
  private readonly heatingSystem!: AppHeatingSystemHeatingSystemMeasurementsViewQuery['heatingSystems']['first'];

  private readonly columns: Column[] = [
    {
      name: 'consumerPath',
      label: 'Versorgungsbereich Ort',
    },
    {
      name: 'consumer',
      label: 'Versorgungsbereich',
    },
    {
      name: 'name',
    },
    {
      name: 'spot',
    },
    {
      name: 'latestMetricPoint',
    },
    { name: 'action', label: '', align: 'right' },
  ];

  private onSpotClick(row: Row): void {
    this.$router.push({
      name: 'AppManager/TreeNode/Spots/Spot',
      params: { treeNodeId: row.spot.path.items.slice(-1)[0].id, spotId: row.spot.id },
    });
  }

  private onConsumerClick(row: Row): void {
    if (!row.consumer.node) {
      return;
    }
    this.$router.push({
      name: 'AppManager/TreeNode',
      params: { treeNodeId: row.consumer.node.id },
    });
  }

  private onConsumerPathClick(treeNode: TreeNodePathTreeNode | undefined): void {
    if (!treeNode) {
      return;
    }
    this.$router.push({
      name: 'AppManager/TreeNode',
      params: { treeNodeId: treeNode.id },
    });
  }
}
