import { Component, Vue } from 'vue-property-decorator';
import { EnerIqMeasurementSystemComponentType, EnerIqMeasurementSourceType } from '@/types/iot-portal';

export interface sourceType {
  label: string;
  key: EnerIqMeasurementSourceType;
}

export interface systemComponentType {
  label: string;
  typeName: EnerIqMeasurementSystemComponentType;
  sourceTypes: sourceType[];
}

@Component
export class EneriqTypesMixin extends Vue {
  protected get components(): systemComponentType[] {
    return [
      {
        label: 'Kessel',
        typeName: EnerIqMeasurementSystemComponentType.BOILER,
        sourceTypes: [
          {
            label: 'Eingangstemperatur',
            key: EnerIqMeasurementSourceType.TEMPERATURE_IN,
          },
          {
            label: 'Ausgangstemperatur',
            key: EnerIqMeasurementSourceType.TEMPERATURE_OUT,
          },
          {
            label: 'Heizleistung',
            key: EnerIqMeasurementSourceType.HEAT_PRODUCTION_QUANTITY,
          },
          {
            label: 'Brennstoffverbrauch',
            key: EnerIqMeasurementSourceType.FUEL_INPUT_QUANTITY,
          },
        ],
      },
      {
        label: 'Heizkreis',
        typeName: EnerIqMeasurementSystemComponentType.HEATING_CIRCUIT,
        sourceTypes: [
          {
            label: 'Vorlauftemperatur',
            key: EnerIqMeasurementSourceType.CIRCULATION_TEMPERATURE_IN,
          },
          {
            label: 'Rücklauftemperatur',
            key: EnerIqMeasurementSourceType.CIRCULATION_TEMPERATURE_OUT,
          },
        ],
      },
      {
        label: 'Warmwasserbereitung',
        typeName: EnerIqMeasurementSystemComponentType.WATER_HEATING,
        sourceTypes: [
          {
            label: 'Temperatur Warmwasserbereitung',
            key: EnerIqMeasurementSourceType.WARM_WATER_TEMPERATURE,
          },
          {
            label: 'Temperatur Zirkulationsleitung',
            key: EnerIqMeasurementSourceType.CIRCULATION_TEMPERATURE,
          },
          {
            label: 'Wärmemenge',
            key: EnerIqMeasurementSourceType.HEAT_QUANTITY,
          },
          {
            label: 'Wärmeleistung',
            key: EnerIqMeasurementSourceType.HEAT_METER_POWER,
          },
        ],
      },
      {
        label: 'Pufferspeicher',
        typeName: EnerIqMeasurementSystemComponentType.BUFFER,
        sourceTypes: [
          {
            label: 'Primärrücklauf',
            key: EnerIqMeasurementSourceType.PRIMARY_TEMPERATURE_IN,
          },
          {
            label: 'Primärvorlauf',
            key: EnerIqMeasurementSourceType.PRIMARY_TEMPERATURE_OUT,
          },
          {
            label: 'Sekundärrücklauf',
            key: EnerIqMeasurementSourceType.SECONDARY_TEMPERATURE_IN,
          },
          {
            label: 'Sekundärvorlauf',
            key: EnerIqMeasurementSourceType.SECONDARY_TEMPERATURE_OUT,
          },
          {
            label: 'Tertierrücklauf',
            key: EnerIqMeasurementSourceType.TERTIARY_TEMPERATURE_IN,
          },
          {
            label: 'Tertiervorlauf',
            key: EnerIqMeasurementSourceType.TERTIARY_TEMPERATURE_OUT,
          },
          {
            label: 'Temperatur oben',
            key: EnerIqMeasurementSourceType.TOP_TEMPERATURE,
          },
          {
            label: 'Temperatur mitte',
            key: EnerIqMeasurementSourceType.MIDDLE_TEMPERATURE,
          },
          {
            label: 'Temperatur unten',
            key: EnerIqMeasurementSourceType.BOTTOM_TEMPERATURE,
          },
        ],
      },
      {
        label: 'Fernwärme',
        typeName: EnerIqMeasurementSystemComponentType.TELE_HEATING,
        sourceTypes: [
          {
            label: 'Eingangstemperatur',
            key: EnerIqMeasurementSourceType.TEMPERATURE_IN,
          },
          {
            label: 'Ausgangstemperatur',
            key: EnerIqMeasurementSourceType.TEMPERATURE_OUT,
          },
          {
            label: 'Wärmemenge',
            key: EnerIqMeasurementSourceType.HEAT_QUANTITY,
          },
          {
            label: 'Wärmeleistung',
            key: EnerIqMeasurementSourceType.HEAT_METER_POWER,
          },
        ],
      },
      {
        label: 'Gaszähler',
        typeName: EnerIqMeasurementSystemComponentType.GAS_METER,
        sourceTypes: [
          {
            label: 'Menge',
            key: EnerIqMeasurementSourceType.QUANTITY,
          },
        ],
      },
      {
        label: 'Stromzähler',
        typeName: EnerIqMeasurementSystemComponentType.ELECTRICITY_METER,
        sourceTypes: [
          {
            label: 'Menge',
            key: EnerIqMeasurementSourceType.QUANTITY,
          },
        ],
      },
      {
        label: 'Warmwasserzähler',
        typeName: EnerIqMeasurementSystemComponentType.COLD_WATER_METER,
        sourceTypes: [
          {
            label: 'Menge',
            key: EnerIqMeasurementSourceType.QUANTITY,
          },
        ],
      },
      {
        label: 'Kaltwasserzähler',
        typeName: EnerIqMeasurementSystemComponentType.WARM_WATER_METER,
        sourceTypes: [
          {
            label: 'Menge',
            key: EnerIqMeasurementSourceType.QUANTITY,
          },
        ],
      },
    ];
  }
}
