
















import { defineComponent } from '@vue/composition-api';
import { mapState } from 'vuex';
import { Order } from '@/types/iot-portal';
import Table from '@/features/ui/table/Table.global.vue';

export default defineComponent<any, Record<string, unknown>, any, any, any>({
  components: { Table },
  data() {
    return {
      search: '',
      columns: [
        { name: 'name', label: 'name', sortable: true, width: 150 },
        { name: 'buildingType', width: 100 },
        { name: 'liegenshaftInformationCompleted', width: 100 },
        { name: 'liegenshaft', label: 'Has Gebaude', width: 200 },
        { name: 'mainService', width: 200 },
        { name: 'products', width: 200 },
        { name: 'numberOfApartments', label: 'numberOfApartments', width: 200 },
      ],
      sortKeys: [
        {
          key: 'name',
          direction: Order.ASC,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      buildings: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.buildings,
    }),
  },
  methods: {},
});
