var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-wizard',{attrs:{"title":"Bitte aufmerksam lesen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var show = ref.show;
return [_c('ui-wizard-button',{attrs:{"secondary":""},on:{"click":show}},[_vm._v("Heizungssystem löschen")])]}},{key:"content",fn:function(ref){
var hide = ref.hide;
return [_c('ui-form',{attrs:{"action":_vm.removeHeatingSystem,"no-reset":""},on:{"submitted":function($event){return _vm.onHeatingSystemRemoved(hide)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var dirty = ref.dirty;
var busy = ref.busy;
var submit = ref.submit;
var errorMessages = ref.errorMessages;
return [_c('ui-flex',{attrs:{"max":600}},[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-message',{attrs:{"warning":""}},[_vm._v("Wenn Sie dieses Heizungssystem entfernen werden "),_c('strong',[_vm._v("alle dazugehörigen gespeicherten Werte unwiderruflich gelöscht")]),_vm._v("."),_c('br'),_c('br'),_vm._v("Bitte geben Sie den Namen des Heizungssystem ein um die Löschung zu bestätigen.")])],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-text","path":"name","placeholder":"Heizungssystemname","error":!_vm.equalsName(formData.name)}})],1),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard-button',{attrs:{"disabled":!dirty || !_vm.equalsName(formData.name),"busy":busy},on:{"click":submit}},[_vm._v("Entfernen")])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }