import { Column } from '@/features/ui/table/model';
import { HeatingSystemCollectionWithPaginationQuery } from '@/hsc-api/queries/__generated__/HeatingSystemCollectionWithPaginationQuery';

type HeatingSystemConsumption =
  HeatingSystemCollectionWithPaginationQuery['heatingSystemsList']['items'][number]['heatingSystemConsumptions'][number];

type HeatingSystem = HeatingSystemCollectionWithPaginationQuery['heatingSystemsList']['items'][number];

export const BASE_COLUMNS: readonly Column[] = Object.freeze([
  { name: 'street', label: 'Standort' },
  { name: 'status', label: 'Alarmstatus' },
  { name: 'statuses', label: 'Alarmmeldung' },
  { name: 'action', label: '', align: 'right' },
  { name: 'expand', label: '' },
]);

export interface HeatingSystemSummary {
  name: string;
  health: string | undefined;
  statuses: string[] | null;
  statusText: string | null;
  street: string | null;
  heatingType: string | null;
  boilerDevice: string | null;
  boilerModel: string | null;
  productSerial: string | null;
  manufacturer: string | null;
  boilerManufacturer: string | null;
  energyOutputRange: string | null;
  waterStorageType: string | null;
  waterStorageModel: string | null;
  waterStorageManufacturer: string | null;
  waterStorageCapacity: string | null;
  maintenanceCompany: string | null;
  numApartmentUnits: number | null;
  numCommercialUnits: number | null;
  suppliedQuantity: number | null;
  lastHydraulicBalancing: number | null;
  consumptions: HeatingSystemConsumption[] | null;
  components: HeatingSystem;
  siteId: string;
}
