export enum HeatingSystemMeasurementType {
  COMMON = 'common',
  TEMPERATURE = 'temperature',
  SETTING = 'setting',
}

export interface TemperatureRange {
  from: number;
  to: number;
  color: string;
}

interface Schedule {
  start: string;
  end: string;
  mode: 'normal';
  position: number;
}

export interface ScheduleMetricPoint {
  mon: Schedule[];
  tue: Schedule[];
  wed: Schedule[];
  thu: Schedule[];
  fri: Schedule[];
  sat: Schedule[];
  sun: Schedule[];
}

interface GaugeProps {
  ranges: TemperatureRange[];
  minTarget?: number;
  maxTarget?: number;
  min: number;
  max: number;
  ticksCount: number;
  unit: string;
  value: number;
  text?: string;
}

interface ThermometerProps {
  ranges: TemperatureRange[];
  target?: number;
  min: number;
  max: number;
  ticksCount: number;
  unit: string;
  value?: number;
  text?: string;
}

interface SwitchProps {
  value: boolean;
  error: boolean;
  leftValue: boolean;
  rightValue: boolean;
  leftColor?: string;
  rightColor?: string;
  alignRight: boolean;
  disabled?: boolean;
}

interface HtmlProps {
  value: string;
}

export const COMMON_MEASUREMENT_METRIC_NAMES = Object.freeze({
  OTHER: ['activeErrors', 'burnerStarts0'],
  STATE: ['burnerHours0'],
});

export const TEMPERATURE_MEASUREMENT_METRIC_NAMES = Object.freeze({
  DHW_MEASUREMENTS: ['heatingDhwTemperatureMain', 'dhwHotWaterStorageTemperature'],
  GAUGE: [
    'boilerTemperature',
    'circuitSupplyTemperature0',
    'circuitSupplyTemperature1',
    'temperature1',
    'temperature2',
    'temperature3',
    'temperature4',
  ],
  THERMOMETER: ['outsideTemperature'],
});

export const SETTING_MEASUREMENT_METRIC_NAMES = Object.freeze({
  SWITCH: [
    'dhwPumpsPrimary',
    'burnerState0',
    'circuitFrostProtection0',
    'circuitFrostProtection1',
    'circuitActivePrograms0',
    'circuitActivePrograms1',
    'circuitActiveOperatingModes0',
    'circuitActiveOperatingModes1',
  ],
  SCHEDULE: ['circuitHeatingSchedule0', 'circuitHeatingSchedule1'],
});

export type GaugeAttributes = Partial<GaugeProps>;

export type ThermometerAttributes = Partial<ThermometerProps>;

export type SwitchAttributes = Partial<SwitchProps>;

export type HtmlAttributes = Partial<HtmlProps>;
