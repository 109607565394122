<template>
  <div>
    <ui-panel v-if="isLoading || hasError" class="loading-panel">
      <div class="loading-container">
        <div v-if="isLoading" class="loader"></div>
        <ui-form-message v-else-if="hasError" error>{{ errorMessage }}</ui-form-message>
      </div>
    </ui-panel>
  </div>
</template>

<script>
export default {
  name: 'LoadingIndicator',
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: 'An error occurred while loading.',
    },
  },
};
</script>

<style scoped>
.loading-panel {
  margin-top: 10px;
  margin-bottom: 10px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh; /* Full viewport height */
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #e60000; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite; /* Spin animation */
}

.error-message {
  color: red;
  font-size: 16px;
  text-align: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
