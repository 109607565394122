




















import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'ProjectBuildingEditConfirmationModal',
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const visible = ref(true);
    const modal = ref(null);

    const show = (): void => {
      visible.value = true;
      emit('show');
    };

    const hide = (): void => {
      visible.value = false;
      emit('hide');
    };

    const confirm = (): void => {
      emit('confirm');
      hide();
    };

    return {
      visible,
      modal,
      show,
      hide,
      confirm,
    };
  },
});
