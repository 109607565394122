























































import { defineComponent } from '@vue/composition-api';
import { mapGetters } from 'vuex';
import { HeatingSystemSummary } from '@/features/domain-ui/heating-system-list-table/model';
import { getHeatingSystemsComponentsWithTypes } from '@/util/heating-systems';
import {
  HeatingSystem,
  HeatingSystemComponent,
} from '@/features/core/store/page-modules/heating-systems-metadata/heating-systems-metadata.module';
import HeatingSystemsMetadataForm from './HeatingSystemsMetadataForm.vue';
import HeatingSystemsMetadataApplySettings from './HeatingSystemsMetadataApplySettings.vue';

export default defineComponent({
  components: {
    HeatingSystemsMetadataForm,
    HeatingSystemsMetadataApplySettings,
  },
  props: {
    heatingSystemsData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      searchInput: '',
    };
  },
  computed: {
    ...mapGetters(['openModal', 'openApplySettings']),
    heatingSystems(): HeatingSystem[] {
      return this.$store.getters.heatingSystems;
    },
    heatingSystemsComponents(): HeatingSystemComponent[] {
      return this.$store.getters.heatingSystemsComponents;
    },
    activeHeatingSystem(): HeatingSystem | undefined {
      return this.$store.getters.activeHeatingSystem;
    },
    activeHeatingSystemComponent(): HeatingSystemComponent | undefined {
      return this.$store.getters.activeHeatingSystemComponent;
    },
    filteredHeatingSystems(): HeatingSystem[] {
      if (this.searchInput) {
        return this.heatingSystems.filter((heatingSystem: HeatingSystem) =>
          heatingSystem.name.toLocaleLowerCase().includes(this.searchInput.toLocaleLowerCase()),
        );
      } else {
        return this.heatingSystems;
      }
    },
    filteretedComponents(): HeatingSystemComponent[] {
      return this.heatingSystemsComponents;
    },
  },
  watch: {
    heatingSystemsData(value) {
      const heatingSystems = value.map((item: HeatingSystemSummary) => {
        return {
          ...item,
          active: false,
        };
      });

      this.$store.commit('setHeatingSystems', heatingSystems);
    },
  },
  methods: {
    closeModal() {
      this.$store.commit('openModal', false);
    },
    onHeatingSystemChange(id: string) {
      const activeHeatingSystem = this.heatingSystems.find((item) => item.components.id === id);

      // set active heating system
      this.heatingSystems.forEach((heatingSystem) => {
        heatingSystem.active = heatingSystem.components.id === id ? true : false;
      });

      // get active heating system components
      if (activeHeatingSystem) {
        const components = getHeatingSystemsComponentsWithTypes(activeHeatingSystem);
        const componentOptions = Array.from(components, ([key, value]) => ({
          value: key,
          label: value.component,
          active: false,
          type: value.type,
        }));
        this.$store.commit('setHeatingSystemsComponents', componentOptions);
      }
    },
    onHeatingSystemComponentChange(id: string) {
      this.heatingSystemsComponents.forEach((component) => {
        component.active = component.value === id ? true : false;
      });
    },
  },
});
