













import { defineComponent } from '@vue/composition-api';

interface GatewayDetailBreakdownLineProps {
  title: string;
  value: string;
}
export default defineComponent<
  GatewayDetailBreakdownLineProps,
  Record<string, unknown>,
  Record<string, never>, // Nothing in data
  Record<string, never> // Nothing in computed
>({
  components: {},
  props: {
    title: {
      type: String,
      required: true,
      default: undefined,
    },
    value: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
});
