
import { ObjectProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { AppHeatingSystemHeatingSystemMeasurementHeatingSystemFragment } from './__generated__/AppHeatingSystemHeatingSystemMeasurementHeatingSystemFragment';

export type Measurement = AppHeatingSystemHeatingSystemMeasurementHeatingSystemFragment['measurements'][number];

@Component
export default class HeatingSystemMeasurement extends Vue {
  @ObjectProp()
  protected readonly measurement!: Measurement;
}
