









































































































































































import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import query from './view.gql';
import {
  AppHeatingSystemHeatingSystemGeneralViewQuery,
  AppHeatingSystemHeatingSystemGeneralViewQuery_heatingSystems_first_consumptions,
  AppHeatingSystemHeatingSystemGeneralViewQueryVariables,
} from './__generated__/AppHeatingSystemHeatingSystemGeneralViewQuery';
import editHeatingSystemMutation from './edit-heating-system.gql';
import { EditHeatingSystemInput, EnergySource } from '@/types/iot-portal';
import {
  AppHeatingSystemHeatingSystemGeneralViewEditHeatingSystemMutation,
  AppHeatingSystemHeatingSystemGeneralViewEditHeatingSystemMutationVariables,
} from './__generated__/AppHeatingSystemHeatingSystemGeneralViewEditHeatingSystemMutation';
import { isNonEmptyString } from '@/util/string';
import RemoveHeatingSystemWizardControl from './remove-heating-system-wizard-control/RemoveHeatingSystemWizardControl.vue';
import { SelectOption } from '@/components/form/model';
import { ApolloQueryResult } from 'apollo-client';

type HeatingSystem = AppHeatingSystemHeatingSystemGeneralViewQuery['heatingSystems']['first'];

@Component({
  apollo: {
    heatingSystems: {
      query,
      variables(this: GeneralView): AppHeatingSystemHeatingSystemGeneralViewQueryVariables {
        return { heatingSystemId: this.heatingSystemId };
      },
      result(this: GeneralView, { data }: ApolloQueryResult<AppHeatingSystemHeatingSystemGeneralViewQuery>): void {
        if (data.heatingSystems.first.consumptions === null) {
          data.heatingSystems.first.consumptions = [];
        }
      },
    },
  },
  data() {
    return {
      isNonEmptyString,
    };
  },
  components: { RemoveHeatingSystemWizardControl },
})
export default class GeneralView extends Vue {
  @StringProp(true)
  private readonly heatingSystemId!: string;

  @StringProp(true)
  private readonly treeNodeId!: string;

  private readonly heatingSystems?: AppHeatingSystemHeatingSystemGeneralViewQuery['heatingSystems'];

  private get heatingSystem(): HeatingSystem | undefined {
    return this.heatingSystems?.first;
  }

  private get consumptions(): AppHeatingSystemHeatingSystemGeneralViewQuery_heatingSystems_first_consumptions[] {
    return this.heatingSystems?.first?.consumptions ?? [];
  }

  private get energySources(): SelectOption[] {
    return [
      {
        label: 'Gas',
        value: EnergySource.GAS,
      },
      {
        label: 'Öl',
        value: EnergySource.OIL,
      },
      {
        label: 'Nah-/Fernwärme',
        value: EnergySource.HEAT,
      },
      {
        label: 'Strom',
        value: EnergySource.ELECTRICITY,
      },
    ];
  }

  private toNumberOrNull(input: string | null): number | null {
    if (input === '' || input === null) {
      return null;
    }
    return Number(input);
  }

  private isIntegerOrNull(input: number | null): boolean {
    if (input === null) {
      return true;
    }
    return Number.isInteger(input);
  }

  private async editHeatingSystem(input: EditHeatingSystemInput): Promise<void> {
    const { data } = await this.$apollo.mutate<
      AppHeatingSystemHeatingSystemGeneralViewEditHeatingSystemMutation,
      AppHeatingSystemHeatingSystemGeneralViewEditHeatingSystemMutationVariables
    >({
      mutation: editHeatingSystemMutation,
      variables: { input: { ...input, id: this.heatingSystemId } },
    });

    if (!data) {
      throw new Error('Heizungssystem konnte nicht gespeichert werden.');
    }
  }
}
