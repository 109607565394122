




























import { TreeNodePathTreeNode } from '@/features/domain-ui/tree-node-path/model';
import { Order } from '@/types/iot-portal';
import SpotNameCell from '@/features/app-heating-system/views/tree-node/directory-spots/components/TableCells/SpotNameCell.vue';
import { GetTreeNodeSiblings_treeNodeSiblings_items } from '@/features/app-heating-system/views/tree-node/directory-spots/graphqlQueries/__generated__/GetTreeNodeSiblings';
import { HeatingSystemsForCustomer_heatingSystemsList_items } from '@/hsc-api/queries/__generated__/HeatingSystemsForCustomer';
import { HeatingSystemMeasurementGroupsByWhere_heatingSystemMeasurementGroups_items } from '@/hsc-api/queries/__generated__/HeatingSystemMeasurementGroupsByWhere';
import { APOLLO_CLIENT } from '@/features/core/container/model';
import assignComponentMutation from '@/hsc-api/mutations/AssignNewHeatingSystemForMeasurementGroup.gql';
import { mapActions } from 'vuex';
import { SpotTableDataLike, SpotTableSpot } from '@/features/domain-ui/spot-table/model';
import { defineComponent } from '@vue/composition-api';

interface DirectorySpotsTableProps {
  spots: SpotTableDataLike;
  siblingTreeNodes: GetTreeNodeSiblings_treeNodeSiblings_items[];
  heatingSystemList: HeatingSystemsForCustomer_heatingSystemsList_items[];
  heatingSystemMeasurementGroups: HeatingSystemMeasurementGroupsByWhere_heatingSystemMeasurementGroups_items[];
  contextTreeNode: TreeNodePathTreeNode | null;
}

export default defineComponent<DirectorySpotsTableProps, Record<string, unknown>, any, any>({
  components: { SpotNameCell },
  props: {
    spots: [],
    siblingTreeNodes: [],
    heatingSystemList: [],
    heatingSystemMeasurementGroups: [],
    contextTreeNode: null,
    callbackFunction: null,
  },
  data() {
    return {
      columns: [
        { name: 'path', label: 'Standort', width: 150 },
        { name: 'name', sortable: true, width: 200 },
        { name: 'heatingSystem', label: 'Heizungssysteme', width: 200 },
      ],
      sortKeys: [
        {
          key: 'name',
          direction: Order.ASC,
        },
      ],
      loading: false,
      tableHeatingSystemMeasurementGroups: [],
    };
  },
  computed: {
    objectOptions() {
      const filteredObjects = this.siblingTreeNodes.filter((item1: GetTreeNodeSiblings_treeNodeSiblings_items) =>
        this.heatingSystemList.some(
          (item2: HeatingSystemsForCustomer_heatingSystemsList_items) => item1.id === item2.siteId,
        ),
      );
      return filteredObjects.map((treeNode: GetTreeNodeSiblings_treeNodeSiblings_items) => {
        return {
          value: treeNode.id,
          label: treeNode.name,
        };
      });
    },
  },
  created() {
    this.tableHeatingSystemMeasurementGroups = this.heatingSystemMeasurementGroups;
  },
  methods: {
    ...mapActions({
      addToastMessages: 'ADD_TOAST_MESSAGES',
    }),
    getSpotHeatingSystem(spotId: string) {
      if (this.tableHeatingSystemMeasurementGroups) {
        const component = this.tableHeatingSystemMeasurementGroups.find(
          (group: HeatingSystemMeasurementGroupsByWhere_heatingSystemMeasurementGroups_items) =>
            group.spotId === spotId,
        );
        if (component) {
          const heatingSystem = this.heatingSystemList.find(
            (system: HeatingSystemsForCustomer_heatingSystemsList_items) => system.id === component.heatingSystemId,
          );
          const site = this.siblingTreeNodes.find(
            (site: GetTreeNodeSiblings_treeNodeSiblings_items) => site.id === heatingSystem?.siteId,
          );
          return {
            label: site ? site.name : '',
            value: site?.id,
          };
        }
      }
    },
    async updateHeatingSystem(value: string, spotId: string) {
      this.loading = true;
      const { data } = await this.$apollo.mutate({
        client: APOLLO_CLIENT.HEATING_SYSTEM_COLLECTOR_CLIENT,
        mutation: assignComponentMutation,
        variables: { objectId: value, spotId: spotId },
      });

      for (let i = 0; i < this.tableHeatingSystemMeasurementGroups.length; i++) {
        if (this.tableHeatingSystemMeasurementGroups[i].spotId === spotId) {
          // Get the heating system
          const heatingSystem = this.heatingSystemList.find(
            (system: HeatingSystemsForCustomer_heatingSystemsList_items) => {
              return system.siteId === value;
            },
          );
          if (heatingSystem) {
            this.tableHeatingSystemMeasurementGroups[i].heatingSystemId = heatingSystem.id;
          }
        }
      }

      // Call to refresh data when a spot is assigned
      if (this.callbackFunction) {
        await this.callbackFunction();
      }
      await this.addToastMessages({
        messages: [{ text: 'Heating System Changed', class: 'success' }],
      });
      this.loading = false;
    },
  },
});
