var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('ui-overlay',{attrs:{"title":"Messstellen","shown":_vm.selectedIdForOverlay !== undefined},on:{"close":function($event){_vm.selectedIdForOverlay = undefined}}}),_c('ui-panel',{staticClass:"filter-panel"},[_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-flex',{attrs:{"items":"center"}},[_c('ui-cell',{attrs:{"min":150,"max":400}},[_c('div',{staticClass:"label"},[_vm._v(" ")]),_c('ui-input-text',{attrs:{"placeholder":"Standort / Name / Produkt-ID-Nummer / Hersteller-Nummer","dataElementId":"app-heating-system-search"},model:{value:(_vm.nextSearchQuery),callback:function ($$v) {_vm.nextSearchQuery=$$v},expression:"nextSearchQuery"}})],1),_c('ui-cell',{attrs:{"max":280}},[_c('div',{staticClass:"label"},[_vm._v("Filter")]),_c('ui-input-select',{attrs:{"value":_vm.selectedStatus,"options":_vm.statusOptions,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"dataElementId":"app-heating-system-filter-status"},on:{"update":function($event){return _vm.onUpdateStatus($event)}}})],1),(_vm.hasAppRole(_vm.appRoles.APP_HEATING_SYSTEM_METADATA))?_c('ui-cell',{attrs:{"max":280}},[_c('div',{staticClass:"label"},[_vm._v("Stammdaten")]),_c('ui-input-select',{attrs:{"value":_vm.selectedMetadata,"options":_vm.metadataOptions,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"dataElementId":"app-heating-system-filter-metadata"},on:{"update":function($event){return _vm.onUpdateMetadata($event)}}})],1):_vm._e(),(_vm.hasAppRole(_vm.appRoles.APP_HEATING_SYSTEM_METADATA))?_c('ui-cell',[_c('ui-link',{staticClass:"metadata-modal-trigger",on:{"click":_vm.openModal}},[_c('ui-icon',{attrs:{"icon":"folder"}})],1)],1):_vm._e()],1)],1),_c('ui-cell',[_c('ui-table-skeleton',{attrs:{"ready":!_vm.loading,"column-count":_vm.BASE_COLUMNS.length - _vm.hiddenColumns.length,"line-count":1,"header-line-count":1},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('ui-flex',[(_vm.summaries.length === 0 && !_vm.loading)?_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-message',[_vm._v("Keine Heizungssysteme gefunden")])],1):_c('ui-cell',{attrs:{"basis":1}},[_c('domain-ui-heating-system-list-table',{attrs:{"heating-systems":_vm.summaries,"disabled-columns":_vm.hiddenColumns,"is-row-expanded":function (row, index) { return _vm.isRowExpanded(index); }},on:{"heating-system-click":function($event){return _vm.$router.push({ name: 'AppHeatingSystem/HeatingSystem', params: { heatingSystemId: $event.id } })},"toggle-row-expansion":_vm.toggleRowExpansion}})],1),(_vm.totalPages > 1)?_c('ui-cell',{attrs:{"basis":1}},[_c('ui-pagination',{attrs:{"total-pages":_vm.totalPages},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()],1)]},proxy:true}])})],1)],1)],1),_c('heating-systems-metadata-modal',{attrs:{"heating-systems-data":_vm.summaries}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }