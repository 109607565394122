var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-panel',[_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('div',{staticClass:"title"},[_vm._v("Empfänger für Alarme auswählen")])]),_c('ui-cell',{attrs:{"basis":1}},[_c('div',{staticClass:"description"},[_vm._v("Hier können Sie Standardwerte für Alarme nach Komponenten konfigurieren.")])]),_c('ui-cell',{attrs:{"max":250}},[_c('search',{attrs:{"query":_vm.searchQuery,"debounced":true},on:{"update:query":_vm.onSearch}})],1),_c('ui-cell',{attrs:{"basis":1}},[(_vm.heatingSystemCollection)?_c('ui-table-skeleton',{attrs:{"ready":!_vm.loading,"column-count":4,"line-count":3,"header-line-count":3},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('ui-table',{attrs:{"columns":_vm.columns,"rows":_vm.heatingSystemCollection.items.filter(function (item) { return item.heatingSystemMeasurementGroups.length > 0; })},scopedSlots:_vm._u([{key:"cleaning",fn:function(ref){
var value = ref.value;
var row = ref.row;
return [_c('ui-input-select',{staticClass:"settings__inputs",attrs:{"value":_vm.getValue(row.id, 'contactPerson'),"filterable":false,"clearable":false,"placeholder":"bitte auswählen","loading":_vm.loadingServiceProviders,"options":_vm.serviceProvidersOptions,"option-label":function (ref) {
	var firstName = ref.firstName;
	var lastName = ref.lastName;
	var name = ref.name;

	return (firstName + " " + lastName + " (" + name + ")");
}},on:{"input":function (value) { return _vm.onUpdateCleaning(value, row.id); }}})]}},{key:"emergency",fn:function(ref){
var value = ref.value;
var row = ref.row;
return [_c('ui-input-select',{staticClass:"settings__inputs",attrs:{"value":_vm.getValue(row.id, 'emergencyProviderEmail'),"filterable":false,"clearable":false,"placeholder":"bitte auswählen","loading":_vm.loadingServiceProviders,"options":_vm.serviceProvidersOptions,"option-label":function (ref) {
	var firstName = ref.firstName;
	var lastName = ref.lastName;
	var name = ref.name;

	return (firstName + " " + lastName + " (" + name + ")");
}},on:{"input":function (value) { return _vm.onUpdateEmergency(value, row.id); }}})]}},{key:"technicianName",fn:function(ref){
var value = ref.value;
var row = ref.row;
return [_c('ui-flex',{attrs:{"root":"","center":""}},[_c('ui-cell',[_c('ui-input-select',{staticClass:"settings__inputs",attrs:{"value":_vm.getTechnicalContactValue(row.id, 'technicianEmail'),"filterable":false,"clearable":false,"placeholder":_vm.technicalContactPlaceholder,"loading":_vm.loadingTechnicalContacts,"options":_vm.technicalContactsOptions,"option-label":function (ref) {
	var firstName = ref.firstName;
	var lastName = ref.lastName;
	var name = ref.name;

	return (firstName + " " + lastName);
},"disabled":_vm.technicalContactsDisabled},on:{"input":function (value) { return _vm.onUpdateTechnician(value, row.id); }}})],1),_c('ui-cell',[_c('ui-input-checkbox',{attrs:{"value":_vm.getValue(row.id, 'messageShouldBeSent')},on:{"update":function (value) { return _vm.onUpdateShouldMessageBeSend(value, row.id); }},scopedSlots:_vm._u([{key:"optionLabel",fn:function(){return [_c('div',{staticClass:"label"},[_vm._v("Meldungen zur Information senden")])]},proxy:true}],null,true)})],1),_c('ui-cell',[(_vm.serviceProviderId(row.id))?_c('ui-link',{on:{"click":function($event){return _vm.clearProviders(row.id)}}},[_vm._v("zurücksetzen")]):_c('span',{staticClass:"disabled-settings-link"},[_vm._v("zurücksetzen")])],1)],1)]}}],null,false,2144554917)})]},proxy:true}],null,false,1144383913)}):_vm._e()],1),(_vm.totalPages > 1)?_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-pagination',{attrs:{"total-pages":_vm.totalPages},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }