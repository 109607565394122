
















import { defineComponent, ref } from '@vue/composition-api';
import WizardButton from '@/components/clickables/WizardButton.global.vue';
import ConfirmationModal from '@/features/core/components/confirmation-modal/ConfirmationModal.vue';

export default defineComponent({
  name: 'ConfirmationModalButton',
  components: {
    ConfirmationModal,
    WizardButton,
  },
  props: {
    buttonText: {
      type: String,
      required: false,
      default: 'Open Modal',
    },
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    mode: {
      default: 'confirmation',
      type: String,
      required: false,
    },
    cancelButtonText: {
      default: 'Abbrechen',
      type: String,
      required: false,
    },
    confirmButtonText: {
      default: 'Bestätigen Sie',
      type: String,
      required: false,
    },
    understandButtonText: {
      default: 'Ich verstehe',
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    // This is for the modal
    const confirmationModal = ref(null);

    const openModal = (): void => {
      if (!confirmationModal.value) {
        return;
      }
      (confirmationModal.value as any).show();
    };

    // Confirm the modal
    const confirmModal = (): void => {
      emit('confirm');
    };

    const closeModal = (): void => {
      emit('hide');
    };

    return {
      confirmationModal,
      openModal,
      closeModal,
      confirm,
      confirmModal,
    };
  },
});
