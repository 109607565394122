








import { ArrayProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { Measurement } from './Measurement.vue';
import { TEMPERATURE_MEASUREMENT_METRIC_NAMES } from './model';

@Component
export default class HeatingSystemDhwTemperatureMeasurements extends Vue {
  @ArrayProp()
  private readonly measurements!: Measurement[];

  private get heatingTemperatureMain(): Measurement | undefined {
    return this.measurements.find(
      ({ metricName }) => metricName === TEMPERATURE_MEASUREMENT_METRIC_NAMES.DHW_MEASUREMENTS[0],
    );
  }

  private get hotWaterStorageTemperature(): Measurement | undefined {
    return this.measurements.find(
      ({ metricName }) => metricName === TEMPERATURE_MEASUREMENT_METRIC_NAMES.DHW_MEASUREMENTS[1],
    );
  }
}
