






























import { defineComponent, ref } from '@vue/composition-api';
import Table from '@/features/ui/table/Table.global.vue';
import { Order } from '@/types/iot-portal';

export default defineComponent({
  name: 'ConfirmationModal',
  components: { Table },
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    errors: {
      type: Array,
      required: false,
    },
  },
  setup(props, { emit }) {
    const visible = ref(false);
    const modal = ref(null);

    const show = (): void => {
      visible.value = true;
      emit('show');
    };

    const hide = (): void => {
      visible.value = false;
      emit('hide');
    };

    const confirm = (): void => {
      visible.value = false;
      emit('confirm');
    };

    return {
      visible,
      modal,
      show,
      hide,
      confirm,
    };
  },
  data() {
    return {
      columns: [
        { name: 'row', label: 'Row', sortable: true, width: 100 },
        { name: 'error', width: 500 },
      ],
      sortKeys: [
        {
          key: 'row',
          direction: Order.ASC,
        },
      ],
    };
  },
});
