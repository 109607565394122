











import { Component } from 'vue-property-decorator';
import HeatingSystemMeasurement from './Measurement.vue';

@Component
export default class HeatingSystemCommonMeasurement extends HeatingSystemMeasurement {}
