












import Html from '@/features/ui/html/Html.global.vue';
import Switch from '@/features/ui/inputs/InputSwitch.global.vue';
import { isBoolean } from 'lodash';
import { Component as ComponentType } from 'vue';
import { Component } from 'vue-property-decorator';
import HeatingSystemMeasurement, { Measurement } from './Measurement.vue';
import { HtmlAttributes, ScheduleMetricPoint, SETTING_MEASUREMENT_METRIC_NAMES, SwitchAttributes } from './model';

@Component
export default class HeatingSystemSettingMeasurement extends HeatingSystemMeasurement {
  private switchAttributes(value?: boolean | unknown): SwitchAttributes {
    if (value === null || value === undefined) {
      return { error: true, value: false };
    }

    return {
      value: isBoolean(value) ? value : value !== 0,
      rightValue: true,
      leftValue: false,
      leftColor: '#888888',
      rightColor: '#54A318',
      disabled: true,
    };
  }

  private htmlAttributes(value?: ScheduleMetricPoint | unknown): HtmlAttributes {
    if (this.isScheduleMetricPoint(value)) {
      const days = Object.keys(value) as [keyof ScheduleMetricPoint];
      const daySchedule = days.length > 1 ? `Tag: ${days[0]} - ${days[days.length - 1]}` : `Tag: ${days[0]}`;
      const entry = value[days[0]][0];

      const html = `
        <h3>Einstellung</h3>
        <p>${daySchedule}</p>
        <p>Start: ${entry.start}</p>
        <p>End: ${entry.end}</p>
        <p>Mode: ${entry.mode}</p>
        <p>Position: ${entry.position}</p>
      `;
      return { value: html };
    }
    return { value: value as string };
  }

  private component(measurement: Measurement): ComponentType {
    if (this.isSchedule(measurement.metricName)) {
      return Html;
    }

    return Switch;
  }

  private componentAttributes(measurement: Measurement): SwitchAttributes | HtmlAttributes {
    if (this.isSchedule(measurement.metricName)) {
      return this.htmlAttributes(measurement.latestMetricPoint?.value);
    }

    return this.switchAttributes(measurement.latestMetricPoint?.value);
  }

  private isSchedule(name: string): boolean {
    return SETTING_MEASUREMENT_METRIC_NAMES.SCHEDULE.findIndex((value) => value.includes(name)) !== -1;
  }

  private isScheduleMetricPoint(value: unknown): value is ScheduleMetricPoint {
    if (typeof value === 'object') {
      if (
        !!Object.getOwnPropertyDescriptor(value, 'mon') ||
        !!Object.getOwnPropertyDescriptor(value, 'tue') ||
        !!Object.getOwnPropertyDescriptor(value, 'wed') ||
        !!Object.getOwnPropertyDescriptor(value, 'thu') ||
        !!Object.getOwnPropertyDescriptor(value, 'fri') ||
        !!Object.getOwnPropertyDescriptor(value, 'sat') ||
        !!Object.getOwnPropertyDescriptor(value, 'sun')
      ) {
        return true;
      }
    }

    return false;
  }

  private switchText(value: boolean): string {
    if (typeof value === 'boolean' && value) {
      return 'Aktiv';
    }

    return 'Aus';
  }

  private isBoolean = isBoolean;
}
