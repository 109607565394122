


































































import { defineComponent } from '@vue/composition-api';
import { mapActions } from 'vuex';
import DirectorySpotsTable from '@/features/app-heating-system/views/tree-node/directory-spots/components/DirectorySpotsTable.vue';
import { APOLLO_CLIENT } from '@/features/core/container/model';
import siblingTreeNodesQuery from '@/features/app-heating-system/views/tree-node/directory-spots/graphqlQueries/siblingTreeNodes.gql';
import spotsQuery from '@/features/app-heating-system/views/tree-node/directory-spots/graphqlQueries/heatingSystemSpotsQuery.gql';
import treeNodeType from '@/features/app-heating-system/views/tree-node/directory-spots/graphqlQueries/getTreeNodeType.gql';
import heatingSystemsListQuery from '@/hsc-api/queries/HeatingSytems.gql';
import {
  HeatingSystemSpotsQuery_spots_items_Directory_roles_roleAggregations,
  HeatingSystemSpotsQuery_spots_items_Directory_spots_items,
} from '@/features/app-heating-system/views/tree-node/directory-spots/graphqlQueries/__generated__/HeatingSystemSpotsQuery';
import { Option } from '@/features/ui/inputs/model';
import { startCase } from 'lodash';
import DeviceRoleMapMixin from '@/features/core/components/mixins/device-role-map';
import heatingSystemComponentsQuery from '@/hsc-api/queries/HeatingSystemMeasurementGroupsByWhere.gql';
import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { SpotTableSpot } from '@/features/domain-ui/spot-table/model';
import { HeatingSystemMeasurementGroupsByWhere_heatingSystemMeasurementGroups_items } from '@/hsc-api/queries/__generated__/HeatingSystemMeasurementGroupsByWhere';

interface DirectorySpotsPanelProps {
  treeNodeId: string | undefined;
  userId: string | undefined;
}

type RoleSelectOption = {
  label: string;
  value: string;
  count: number;
};

export default defineComponent<DirectorySpotsPanelProps, Record<string, unknown>, any, any>({
  components: { DirectorySpotsTable },
  mixins: [DeviceRoleMapMixin, PaginationQueryStringMixin],
  props: {
    treeNodeId: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      treeNodeSiblings: [],
      spots: [],
      roleAggregations: [],
      heatingSystemsList: [],
      heatingSystemMeasurementGroups: [],
      treeNodeType: null,
      // Filters
      mappedFilterOptions: [
        { label: 'All', value: 'All' },
        { label: 'Mapped', value: 'Mapped' },
        { label: 'Unmapped', value: 'Unmapped' },
      ],
      mappedFilter: { label: 'All', value: 'All' },
      role: undefined,
      // Loading variables
      treeNodesLoading: true,
      spotsLoading: true,
      heatingSystemListLoading: true,
      heatingSystemGroupsLoading: true,
    };
  },
  computed: {
    filteredRoles(): HeatingSystemSpotsQuery_spots_items_Directory_roles_roleAggregations[] {
      return this.roleAggregations.filter(
        (role: HeatingSystemSpotsQuery_spots_items_Directory_roles_roleAggregations) => {
          return role.role === 'GAS_METER' || role.role === 'HEAT_METER_COUNTER';
        },
      );
    },
    roleOptions(): Option[] {
      const aggregations = this.filteredRoles;
      if (
        this.role !== undefined &&
        aggregations.find(
          ({ role }: HeatingSystemSpotsQuery_spots_items_Directory_roles_roleAggregations) => role === this.role,
        ) === undefined
      ) {
        aggregations.push({
          __typename: 'SpotRoleAggregation',
          role: this.role,
          count: 0,
        });
      }

      return [
        ...aggregations
          .map(({ role }: HeatingSystemSpotsQuery_spots_items_Directory_roles_roleAggregations) => {
            const label = this.deviceRoleMap[role]?.label ?? startCase(role);
            // Count the spots without heating systems
            const roleSpots = this.spots.filter((spot: HeatingSystemSpotsQuery_spots_items_Directory_spots_items) => {
              return spot.role === role;
            });
            const count = roleSpots.length;

            return { value: role, label: `${label} (${count})`, count };
          })
          .sort((a: RoleSelectOption, b: RoleSelectOption) => a.label.localeCompare(b.label)),
      ];
    },
    dataLoading(): boolean {
      return (
        this.treeNodesLoading || this.spotsLoading || this.heatingSystemListLoading || this.heatingSystemGroupsLoading
      );
    },
    filteredSpots() {
      let filteredSpots = this.spots;
      if (this.role) {
        filteredSpots = filteredSpots.filter((spot: SpotTableSpot) => {
          return spot.role === this.role;
        });
      }
      if (this.mappedFilter) {
        if (this.mappedFilter === 'Mapped') {
          filteredSpots = filteredSpots.filter((spot: SpotTableSpot) => {
            const component = this.getSpotComponent(spot.id);
            return !!component;
          });
        }
        if (this.mappedFilter === 'Unmapped') {
          filteredSpots = filteredSpots.filter((spot: SpotTableSpot) => {
            const component = this.getSpotComponent(spot.id);
            return !component;
          });
        }
      }
      return filteredSpots;
    },
  },
  async created() {
    await this.loadData();
  },
  methods: {
    ...mapActions({
      addToastMessages: 'ADD_TOAST_MESSAGES',
    }),
    async loadData() {
      await this.loadTreeNodeSiblings();
      await this.loadTreeNodeType();
      await this.loadSpots();
      await this.loadHeatingSystemsList();
      await this.loadHeatingSystemGroups();
    },
    async loadTreeNodeSiblings(): Promise<void> {
      const { data } = await this.$apollo.query({
        query: siblingTreeNodesQuery,
        fetchPolicy: 'network-only',
        variables: { id: this.treeNodeId },
      });
      if (data?.treeNodeSiblings?.items) {
        this.treeNodeSiblings = data.treeNodeSiblings.items;
      }
      this.treeNodesLoading = false;
    },
    async loadSpots(): Promise<void> {
      const { data } = await this.$apollo.query({
        query: spotsQuery,
        fetchPolicy: 'network-only',
        variables: {
          treeNodeId: this.treeNodeId,
          userId: this.userId,
          skip: this.skip,
          take: this.take,
        },
      });
      if (data?.spots?.items[0]?.spots?.items) {
        this.spots = data.spots?.items[0]?.spots?.items;
      }
      if (data?.spots?.items[0]?.roles?.roleAggregations) {
        this.roleAggregations = data.spots?.items[0]?.roles?.roleAggregations;
      }
      this.spotsLoading = false;
    },
    async loadTreeNodeType(): Promise<void> {
      const { data } = await this.$apollo.query({
        query: treeNodeType,
        fetchPolicy: 'network-only',
        variables: {
          id: this.treeNodeId,
        },
      });
      if (data?.treeNodes?.first) {
        this.treeNodeType = data?.treeNodes?.first.__typename;
      }
    },
    async loadHeatingSystemsList(): Promise<void> {
      const { data } = await this.$apollo.query({
        client: APOLLO_CLIENT.HEATING_SYSTEM_COLLECTOR_CLIENT,
        query: heatingSystemsListQuery,
        fetchPolicy: 'network-only',
        variables: {
          customerIdOrSiteId: this.appCustomerLastCustomerId,
          take: 5000,
        },
      });
      if (data?.heatingSystemsList?.items) {
        this.heatingSystemsList = data.heatingSystemsList.items;
      }
      this.heatingSystemListLoading = false;
    },
    async loadHeatingSystemGroups(): Promise<void> {
      const spotIds = this.spots.map((spot: SpotTableSpot) => {
        return spot.id;
      });
      const { data } = await this.$apollo.query({
        client: APOLLO_CLIENT.HEATING_SYSTEM_COLLECTOR_CLIENT,
        query: heatingSystemComponentsQuery,
        variables: { spotIds },
        fetchPolicy: 'network-only',
      });

      if (!data) {
        throw new Error('Die Exportvorlage konnte nicht gespeichert werden.');
      }

      if (data?.heatingSystemMeasurementGroups?.items) {
        this.heatingSystemMeasurementGroups = data.heatingSystemMeasurementGroups.items;
      }
      this.heatingSystemGroupsLoading = false;
    },
    changeDataValue(property: string, value: string | null) {
      this[property] = value === null ? undefined : value;
    },
    getSpotComponent(
      spotId: string,
    ): HeatingSystemMeasurementGroupsByWhere_heatingSystemMeasurementGroups_items | undefined {
      const component = this.heatingSystemMeasurementGroups.find(
        (group: HeatingSystemMeasurementGroupsByWhere_heatingSystemMeasurementGroups_items) => {
          return group.spotId === spotId;
        },
      );
      if (component) {
        return component;
      }
      return undefined;
    },
    // To refresh the data when a spot is assigned to a heating system
    async assignmentCallback() {
      await this.loadData();
    },
  },
});
