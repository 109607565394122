













































































import { defineComponent } from '@vue/composition-api';
import Table from '@/features/ui/table/Table.global.vue';
import ProjectLiegenshaftStatus from '@/features/app-enterprise-project/views/enterprise-project/components/ProjectLiegenshaftStatus.vue';
import ProjectPropertyPopup from '@/features/app-enterprise-project/views/enterprise-project/components/ProjectPropertyPopup.vue';
import { EnterpriseProjectStatus } from '@/types/iot-portal';
import { DatabaseProjectDraft } from '@/features/core/store/page-modules/enterprise-project-create/create-module-types';
import { DEFAULT_PROJECT_SERVICE_PROVIDER } from '@/env';

export default defineComponent<any, Record<string, unknown>, any, any>({
  components: {
    ProjectPropertyPopup,
    ProjectLiegenshaftStatus,
    Table,
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    projectList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      defaultServiceProvider: DEFAULT_PROJECT_SERVICE_PROVIDER,
      columns: [
        { name: 'customerId', label: 'Kundennummer' },
        { name: 'customerName', label: 'Kunde' },
        { name: 'reference', label: 'Projekt ID' },
        { name: 'salesContactUserName', label: 'ASP Vertrieb' },
        { name: 'numberOfLProperties', label: '# Liegenschaften' },
        { name: 'numberOfGProperties', label: '# Gebäude' },
        { name: 'numberOfApartments', label: '# WE' },
        { name: 'projectItems', label: 'Projektstatus' },
        { name: 'ProjectCreationAndCompletionDates', label: 'Projekt erstellt / abgeschlossen' },
        { name: 'lastUpdate', label: 'Letzte Änderung ' },
        { name: 'serviceProviderName', label: 'Dienstleister' },
        { name: 'action', label: 'Aktion' },
      ],
    };
  },
  computed: {},
  methods: {
    getStatusLabel(status: EnterpriseProjectStatus): string {
      if (this.statusMappings.has(status)) {
        return this.statusMappings.get(status)!.label;
      }

      return status;
    },
    transformToDateOnly(date: string): string {
      if (date) {
        return new Date(date).toLocaleDateString('de-DE');
      }

      return '';
    },
    transformToTimeOnly(date: string): string {
      if (date) {
        return new Date(date).toLocaleTimeString('de-DE');
      }

      return '';
    },
    getCustomerName(row: any): string {
      const draft: DatabaseProjectDraft = row.projectDraft?.pageContent;
      return row.customer?.name || draft?.customerInfo?.customerName;
    },
    getCustomerNumber(row: any): string {
      const draft: DatabaseProjectDraft = row.projectDraft?.pageContent;
      return row.customer?.externalId || draft?.customerInfo?.customerNumber;
    },
    getTooltipContent() {
      return `
        <div style="${this.getDivStyle()}">
          <p style="${this.getPStyle(
            'blue',
          )}">• Liegenschaft erstellt, Standortliste finalisiert und Auftrag bestätigt</p>
          <p style="${this.getPStyle('red')}">• fehlende Informationen</p>
          <p style="${this.getPStyle('orange')}">• im Rollout, Rollout erledigt und in QA</p>
          <p style="${this.getPStyle('green')}">• abgeschlossen und storniert</p>
        </div>
      `;
    },
    // Styling functions
    getColor(status: string) {
      // Logic to determine the text color based on the status
      // For example:
      switch (status) {
        case EnterpriseProjectStatus.CONFIRMED:
          return '#006dec';
        case EnterpriseProjectStatus.PLANNED:
          return '#ffce00';
        case EnterpriseProjectStatus.COMPLETE:
          return '#00a40f';
        case EnterpriseProjectStatus.CANCELLED:
          return '#fa0000';
      }
    },
    getStyle(status: string) {
      // Return the style as a string with the !important flag
      const color = this.getColor(status);
      return `color: ${color} !important;`;
    },
    getDivStyle() {
      return `
        display: block !important;
        z-index: 10000;
        background-color: white;
        padding: 10px;
        border: 1px solid black;
        border-radius: 5px;
      `;
    },
    getPStyle(color: string) {
      return `
        margin: 0;
        color: ${color};
      `;
    },
  },
});
