var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-table',{attrs:{"rows":_vm.spots,"columns":_vm.columns,"hidden-columns":[],"sort-keys":_vm.sortKeys,"render-slices":3},on:{"update:sortKeys":function($event){_vm.sortKeys=$event},"update:sort-keys":function($event){_vm.sortKeys=$event}},scopedSlots:_vm._u([{key:"path",fn:function(ref){
var row = ref.row;
return [_c('domain-ui-tree-node-path',{attrs:{"tree-nodes":row.path.items,"context-tree-node":_vm.contextTreeNode},on:{"tree-node-click":function($event){return _vm.$emit('tree-node-click', $event)}}})]}},{key:"name",fn:function(ref){
var row = ref.row;
return [_c('spot-name-cell',{attrs:{"spot":row},on:{"click":function($event){return _vm.$emit('spot-click', row)}}})]}},{key:"heatingSystem",fn:function(ref){
var row = ref.row;
return [_c('ui-input-select',{attrs:{"value":_vm.getSpotHeatingSystem(row.id),"options":_vm.objectOptions,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"clearable":false,"disabled":_vm.loading,"placeholder":"Select a heating system"},on:{"update":function($event){return _vm.updateHeatingSystem($event || undefined, row.id)}}})]}}])},[[_vm._v(" Name "),_c('br'),_vm._v("Gerätenummer ")]],2)}
var staticRenderFns = []

export { render, staticRenderFns }