






























import { AddHeatingSystemConsumerInput, TreeNodeType } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import addHeatingSystemConsumerMutation from './add-heating-system-consumer.gql';
import {
  AppHeatingSystemAddHeatingSystemConsumerMutation,
  AppHeatingSystemAddHeatingSystemConsumerMutationVariables,
} from './__generated__/AppHeatingSystemAddHeatingSystemConsumerMutation';

@Component
export default class AddHeatingSystemConsumerWizardControl extends Vue {
  @StringProp(true)
  private readonly heatingSystemId!: string;

  @StringProp(true)
  private readonly customerId!: string;

  private pluckId<T>(object: { id: T } | null): T | null {
    return object?.id ?? null;
  }

  private get types(): TreeNodeType[] {
    return [
      TreeNodeType.RootDirectory,
      TreeNodeType.Directory,
      TreeNodeType.Property,
      TreeNodeType.PropertyGroup,
      TreeNodeType.PropertySubdivision,
    ];
  }

  private get selectableTypes(): TreeNodeType[] {
    return [
      TreeNodeType.Directory,
      TreeNodeType.Property,
      TreeNodeType.PropertyGroup,
      TreeNodeType.PropertySubdivision,
    ];
  }

  private get rootIds(): string[] {
    return [this.customerId];
  }

  private async addHeatingSystemConsumer(input: Omit<AddHeatingSystemConsumerInput, 'heatingSystemId'>): Promise<void> {
    const { data } = await this.$apollo.mutate<
      AppHeatingSystemAddHeatingSystemConsumerMutation,
      AppHeatingSystemAddHeatingSystemConsumerMutationVariables
    >({
      mutation: addHeatingSystemConsumerMutation,
      variables: { input: { ...input, heatingSystemId: this.heatingSystemId } },
    });

    if (!data) {
      throw new Error('Der Versorgungsbereich konnte nicht hinzugefügt werden!');
    }
  }
}
