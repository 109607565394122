























import { TreeNodePathTreeNode } from '@/features/domain-ui/tree-node-path/model';
import { Column } from '@/features/ui/table/model';
import { RequiredProp } from '@/util/prop-decorators';
import { Vue, Component } from 'vue-property-decorator';
import { AppHeatingSystemHeatingSystemConsumersViewQuery } from './__generated__/AppHeatingSystemHeatingSystemConsumersViewQuery';

type Row = AppHeatingSystemHeatingSystemConsumersViewQuery['heatingSystems']['first']['consumers'][number];

@Component
export default class HeatingSystemConsumersTable extends Vue {
  @RequiredProp()
  private readonly heatingSystem!: AppHeatingSystemHeatingSystemConsumersViewQuery['heatingSystems']['first'];

  private readonly columns: Column[] = [
    {
      name: 'path',
      label: 'Ort',
    },
    {
      name: 'name',
      label: 'Name',
    },
    { name: 'action', label: '', align: 'right' },
  ];

  private onConsumerClick(row: Row): void {
    if (!row.node) {
      return;
    }
    this.$router.push({
      name: 'AppManager/TreeNode',
      params: { treeNodeId: row.node.id },
    });
  }

  private onConsumerPathClick(treeNode: TreeNodePathTreeNode | undefined): void {
    if (!treeNode) {
      return;
    }
    this.$router.push({
      name: 'AppManager/TreeNode',
      params: { treeNodeId: treeNode.id },
    });
  }
}
