var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('ui-overlay',{attrs:{"title":"Messstellen","shown":_vm.selectedIdForOverlay !== undefined},on:{"close":function($event){_vm.selectedIdForOverlay = undefined}}},[_c('div',{staticClass:"divider"}),_c('ui-tab-bar',{attrs:{"value":_vm.measurementTab},on:{"update":_vm.switchMeasurementTab}},[_c('ui-tab-button',{attrs:{"value":"common"}},[_vm._v("Allgemein")]),_c('ui-tab-button',{attrs:{"value":"temperature"}},[_vm._v("Temperaturwerte")]),_c('ui-tab-button',{attrs:{"value":"setting"}},[_vm._v("Modus")])],1),_c('ui-flex',{staticClass:"measurements-wrapper"},[_vm._l((_vm.filteredMeasurements),function(measurement,index){return _c('ui-cell',{key:index,attrs:{"min":230,"max":230}},[(_vm.measurementComponent)?_c(_vm.measurementComponent,{tag:"component",attrs:{"measurement":measurement}}):_vm._e()],1)}),(_vm.temperatureMeasurementTabSelected && _vm.hasDhwMeasurements)?_c('ui-cell',{attrs:{"min":230,"max":230}},[_c('heating-system-dhw-temperature-measurements',{attrs:{"measurements":_vm.dhwMeasurements}})],1):_vm._e()],2)],1),_c('ui-panel',[_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-flex',{attrs:{"items":"center"}},[_c('ui-cell',{attrs:{"min":150,"max":400}},[_c('ui-input-text',{attrs:{"placeholder":"Standort / Name / Produkt-ID-Nummer / Hersteller-Nummer"},model:{value:(_vm.nextSearchQuery),callback:function ($$v) {_vm.nextSearchQuery=$$v},expression:"nextSearchQuery"}})],1),(_vm.canAddHeatingSystem)?_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard',{attrs:{"title":"Heizungssystem hinzufügen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var show = ref.show;
return [_c('ui-wizard-button',{on:{"click":show}},[_vm._v("Heizungssystem hinzufügen")])]}},{key:"content",fn:function(ref){
var hide = ref.hide;
return [_c('ui-form',{attrs:{"action":_vm.addHeatingSystem},on:{"submitted":function($event){return _vm.onSubmitted($event.result, hide)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var dirty = ref.dirty;
var busy = ref.busy;
var submit = ref.submit;
var errorMessages = ref.errorMessages;
return [_c('core-toast',{attrs:{"messages":errorMessages,"error":""}}),_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-text","path":"name","label":"Name"}})],1),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard-button',{attrs:{"disabled":!dirty,"busy":busy},on:{"click":submit}},[_vm._v("Anlegen")])],1)],1)]}}],null,true)})]}}],null,false,1446082026)})],1):_vm._e(),_c('ui-cell',{attrs:{"grow":0,"push-right":!_vm.canAddHeatingSystem}},[_c('ui-table-column-selection',{attrs:{"columns":_vm.BASE_COLUMNS},model:{value:(_vm.hiddenColumns),callback:function ($$v) {_vm.hiddenColumns=$$v},expression:"hiddenColumns"}})],1)],1)],1),_c('ui-cell',[_c('ui-table-skeleton',{attrs:{"ready":!_vm.loading,"column-count":_vm.BASE_COLUMNS.length - _vm.hiddenColumns.length,"line-count":1,"header-line-count":1},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('ui-flex',[(_vm.heatingSystems.length === 0)?_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-message',[_vm._v("Keine Heizungssysteme gefunden")])],1):_c('ui-cell',{attrs:{"basis":1}},[_c('domain-ui-heating-system-table',{attrs:{"heating-systems":_vm.heatingSystems,"disabled-columns":_vm.hiddenColumns,"is-row-expanded":function (row, index) { return _vm.isRowExpanded(index); }},on:{"heating-system-click":function($event){return _vm.$router.push({ name: 'AppHeatingSystem/HeatingSystem', params: { heatingSystemId: $event.id } })},"toggle-row-expansion":_vm.toggleRowExpansion,"show-measurements":function($event){_vm.selectedIdForOverlay = $event.id}}})],1),(_vm.totalPages > 1)?_c('ui-cell',{attrs:{"basis":1}},[_c('ui-pagination',{attrs:{"total-pages":_vm.totalPages},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()],1)]},proxy:true}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }